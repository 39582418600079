import { useMemo, useState } from "react";
import ArazBox from "components/ArazComponents/ArazBox";
import ArazTabPanel, { ArazTabProps } from "components/ArazComponents/ArazTabPanel";
import ArazTabs from "components/ArazComponents/ArazTabs";
import ArazArchiveTab from "components/ArazComponents/ArazArchiveTab";
import ArazActiveTab from "components/ArazComponents/ArazActiveTab";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocalDateFormat } from "i18n/LocalDate";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { EntityTypes, PurchaseOrderStatuses } from "Services/Base/constants";
import { useConfirm } from "material-ui-confirm";
import { Delete } from "Services/Base/ApiBaseService";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import ArazGrid from "components/ArazComponents/ArazGrid";

export default function Purchases() {
  const { t } = useTranslation();
  const tabs = {
    inProgressTab: 0,
    doneTab: 1,
  };
  const confirm = useConfirm();
  const [tabValue, setTabValue] = useState(tabs.inProgressTab);
  const [trigger, setTrigger] = useState(false);
  const navigate = useNavigate();
  const {
    state: { language },
    dispatch,
  } = useValue();

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "code",
        headerName: t("Manufactures.PurchaseOrder.Field.Code"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "vendorName",
        headerName: t("Manufactures.PurchaseOrder.Field.VendorName"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "receiverWarehouseName",
        headerName: t("Manufactures.PurchaseOrder.Field.ReceiverWarehouseName"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "expectedArrive",
        headerName: t("Manufactures.PurchaseOrder.Field.ExpectedArrive"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        valueFormatter: (params) => {
          return `${LocalDateFormat(params.value)}`;
        },
      },
      {
        field: "statusString",
        headerName: t("Manufactures.PurchaseOrder.Field.Status"),
        flex: 1,
        minWidth: 130,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) =>
          params.row.status == PurchaseOrderStatuses.Cancel || params.row.status == PurchaseOrderStatuses.Draft ? (
            <Chip label={params.value} color="error" />
          ) : params.row.status == PurchaseOrderStatuses.PartiallyReceived ? (
            <Chip label={params.value} color="warning" />
          ) : params.row.status == PurchaseOrderStatuses.Confirm || params.row.status == PurchaseOrderStatuses.Received ? (
            <Chip label={params.value} color="success" />
          ) : (
            params.value
          ),
      },

      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        minWidth: 100,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { autoFocus: true, color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(async () => {
                  const result = await Delete({ navigate, dispatch, t }, EntityTypes.PurchaseOrder, params.id);
                  if (result.isSuccess) setTrigger((trigger) => !trigger);
                })
                .catch(() => {});
            }}
          />,
        ],
        align: "center",
        headerAlign: "center",
      },
    ],
    [tabValue, language]
  );

  const filters = useMemo(() => {
    if (tabValue == tabs.inProgressTab) return [["status", "=", PurchaseOrderStatuses.Confirm], "or", ["status", "=", PurchaseOrderStatuses.PartiallyReceived]];
    else return [["status", "=", PurchaseOrderStatuses.Draft], "or", ["status", "=", PurchaseOrderStatuses.Received], "or", ["status", "=", PurchaseOrderStatuses.Cancel]];
  }, [tabValue, language]);

  return (
    <ArazBox>
      <ArazGrid sx={{ float: { md: "right" } }}>
        <ArazGrid xs={12}>
          <ArazAddButton label={t("PurchaseOrders.New")} />
        </ArazGrid>
      </ArazGrid>

      <ArazTabs
        value={tabValue}
        onChange={(event, newValue) => {
          setTabValue(newValue);
        }}
      >
        <ArazActiveTab label={t("Commons.Tabs.InProgress")} {...ArazTabProps(tabs.inProgressTab)} />
        <ArazArchiveTab label={t("Commons.Tabs.Done")} {...ArazTabProps(tabs.doneTab)} style={{ left: "20px" }} />
      </ArazTabs>

      <ArazTabPanel value={tabValue} index={tabValue}>
        <ArazDataGrid columns={columns} entityType={EntityTypes.PurchaseOrder} filters={filters} trigger={trigger} />
      </ArazTabPanel>
    </ArazBox>
  );
}
