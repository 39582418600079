import ArazTab from "./ArazTab";
import CircleIcon from "@mui/icons-material/Circle";

const ArazArchiveTab = ({ children, ...props }) => {
  return (
    <ArazTab {...props} icon={<CircleIcon fontSize="small" color="error" sx={{ mr: 2 }} />} iconPosition="start">
      {children}
    </ArazTab>
  );
};

export default ArazArchiveTab;
