import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { TextField, Typography } from "@mui/material";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import { EntityTypes } from "Services/Base/constants";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { Insert, Update } from "Services/Base/ApiBaseService";

export default function UnitOfMeasureCategory({ open, setOpen, value, onSave }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({
    name: yup.string().required().label(t("Commons.UnitOfMeasureCategory.Field.Name")),
  });

  useEffect(() => {
    formik.resetForm();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: value.id,
      name: value.name,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.setStatus({ error: null });

      const data = {
        id: values.id,
        name: values.name,
      };
      const result =
        values.id == 0 ? await Insert({ navigate, dispatch, t }, EntityTypes.UnitOfMeasureCategory, data) : await Update({ navigate, dispatch, t }, EntityTypes.UnitOfMeasureCategory, data);
      if (result && result.isSuccess) {
        dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
        onSave();
        close();
      } else formik.setStatus({ error: result.error });
    },
  });

  function close() {
    setOpen(false);
  }
  return (
    <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
      <form>
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {value.id == 0 ? t("Commons.New") : t("Commons.Edit")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid xs={12}>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="name"
                  name="name"
                  label={t("Commons.UnitOfMeasureCategory.Field.Name")}
                  fullWidth
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("name", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </DialogContent>
        <DialogActions>
          {formik.status &&
            formik.status.error &&
            formik.status.error.map((e, i) => (
              <Typography component="span" color="error" key={i}>
                {e[Object.keys(formik.status.error[0])[0]]}
              </Typography>
            ))}
          <Button color="error" variant="text" autoFocus onClick={close}>
            {t("Commons.Cancel")}
          </Button>
          <Button autoFocus type="submit" color="success" variant="contained" onClick={formik.handleSubmit}>
            {t("Commons.Save")}
          </Button>
        </DialogActions>
      </form>
    </ArazDialog>
  );
}
