import { Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";

function ArazTabPanel({ children, value, index, sx, ...other }) {
  const theme = useTheme();
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      sx={{
        boxShadow: "0px 10px 96px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
        bgcolor: "common.white",
        width: "100%",
        ...sx,
      }}
      {...other}
    >
      {value === index && <Box sx={{ p: { xs: 1, md: 3 } }}>{children}</Box>}
    </Box>
  );
}

ArazTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function ArazTabProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default ArazTabPanel;
