import { useTranslation } from "react-i18next";
import ArazContainer from "components/ArazComponents/ArazContainer";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import ArazWarehouseAutoComplete from "components/ArazComponents/ArazWarehouseAutoComplete";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useEffect, useMemo, useState } from "react";
import { GetByWarehouseId } from "Services/Inventories/WarehouseService";
import ArazBox from "components/ArazComponents/ArazBox";
import { Search } from "Services/Base/ApiBaseService";
import { EntityTypes } from "Services/Base/constants";
import WarehouseBomLedger from "components/Ledger/WarehouseBomLedger";
import { Link } from "@mui/material";

export default function InStock() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [warehouseId, setWarehouseId] = useState(null);

  const {
    state: {language},
    dispatch,
  } = useValue();

  const [openLedger, setOpenLedger] = useState(false);
  const [ledgerBomId, setLedgerBomId] = useState(0);
  function OpenLedger(bomId) {
    setLedgerBomId(bomId);
    setOpenLedger(true);
  }

  const columns = useMemo(
    () => [
      {
        field: "productName",
        headerName: t("Inventories.WarehouseBom.Field.ProductName"),
        flex: 1,
        minWidth: 200,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <Link
              onClick={(event) => {
                event.stopPropagation();
                OpenLedger(params.row.bomId);
              }}
            >
              {params.value}
            </Link>
          );
        },
      },
      {
        field: "missingExcess",
        headerName: t("Inventories.WarehouseBom.Field.MissingExcess"),
        flex: 1,
        minWidth: 100,
        type: "number",
        align: "center",
        headerAlign: "center",
      },
      {
        field: "inStockCount",
        headerName: t("Inventories.WarehouseBom.Field.InStockCount"),
        flex: 1,
        minWidth: 100,
        type: "number",
        align: "center",
        headerAlign: "center",
      },
      {
        field: "committedCount",
        headerName: t("Inventories.WarehouseBom.Field.CommittedCount"),
        flex: 1,
        minWidth: 100,
        type: "number",
        align: "center",
        headerAlign: "center",
      },
      {
        field: "expectedCount",
        headerName: t("Inventories.WarehouseBom.Field.ExpectedCount"),
        flex: 1,
        minWidth: 120,
        type: "number",
        align: "center",
        headerAlign: "center",
      },
    ],
    [language]
  );

  useEffect(() => {
    (async () => {
      if (!warehouseId) {
        const result = await Search({ navigate, dispatch, t }, EntityTypes.Warehouse, [], 0, 1);
        setWarehouseId({ id: result.data.data[0].id, name: result.data.data[0].name });
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (warehouseId && warehouseId.id > 0) {
        const result = await GetByWarehouseId({ navigate, dispatch, t }, warehouseId.id, {
          pageSize: 100,
          page: 0,
        });
        await setItems(result.data.data);
      } else {
      }
    })();
  }, [warehouseId]);

  return (
    <>
      <ArazContainer>
        <ArazGrid xs={12} md={6}>
          <ArazGridItem>
            <ArazWarehouseAutoComplete id="warehouseId" name="warehouseId" value={warehouseId} defaultValue={warehouseId} setValue={(v) => setWarehouseId(v)} />
          </ArazGridItem>
        </ArazGrid>
      </ArazContainer>
      <ArazContainer>
        <ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazDataGrid
                manual
                getRowId={(row) => row.productName}
                rows={items}
                columns={columns}
                sortingMode="client"
                paginationMode="client"
                rowCount={items.length}
                onRowClick={async (param) => {}}
              />
            </ArazGridItem>
          </ArazGrid>
        </ArazGrid>
      </ArazContainer>
      {openLedger && <WarehouseBomLedger open={openLedger} setOpen={setOpenLedger} warehouseId={warehouseId.id} bomId={ledgerBomId} />}
    </>
  );
}
