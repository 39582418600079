import * as baseApi from "Services/Base/ApiBaseService";
import { EntityTypes } from "Services/Base/constants";

export const DeleteSample = async (hooks) => {
  let response = await baseApi.fetchWithTimeout(hooks, `${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.Company}/DeleteSample`, {
    method: "DELETE",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  return baseApi.ReadResult(hooks, response);
};
