import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";
import ArazBox from "components/ArazComponents/ArazBox";
import ArazContainer from "components/ArazComponents/ArazContainer";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { AssignmentIcon, InvoiceIcon, ManufactureIcon, PeopleIcon, ProductIcon, PurchaseIcon, SellIcon, SettingIcon, WarehouseIcon } from "components/menu/Icons";
import { useTranslation } from "react-i18next";
import { Link as MuiLink } from "@mui/material";
import { Link, Outlet } from "react-router-dom";
import "./video.css";

export default function Index() {
  const { t } = useTranslation();

  return (
    <ArazBox>
      <ArazContainer>
        <ArazGrid>
          <ArazGrid xs={12} md={3}>
            <ArazGridItem>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ backgroundColor: "navbar.main" }}>
                      <SellIcon />
                    </Avatar>
                  }
                  // action={
                  //   <IconButton aria-label="settings">
                  //     <MoreVertIcon />
                  //   </IconButton>
                  // }
                  title={t("SalesRole")}
                  //subheader="September 14, 2016"
                />
                {/* <CardMedia component="img" height="140" image="/assets/knowledge/fa/sales/sales.mp4" alt="green iguana" /> */}
                {/* <video controls className="index">
                  <source src="/assets/knowledge/fa/sales/sales.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}
                <CardContent>
                  {/* <Typography gutterBottom variant="h5" component="div">
                    {t("SalesRole")}
                  </Typography> */}
                  <Typography variant="body2" color="text.secondary">
                    در این بخش می توانید تمامی محصولات فروخته شده را مشاهده کنید و همین طور فروش جدید انجام دهید
                  </Typography>
                </CardContent>
                <CardActions sx={{ float: "right" }}>
                  <MuiLink component={Link} to="Sales" underline="none">
                    {t("LearnMore")}
                  </MuiLink>
                </CardActions>
              </Card>
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12} md={3}>
            <ArazGridItem>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ backgroundColor: "navbar.main" }}>
                      <ManufactureIcon />
                    </Avatar>
                  }
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {t("ManufacturesRole")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica
                  </Typography>
                </CardContent>
                <CardActions sx={{ float: "right" }}>
                  <MuiLink component={Link} to="/" underline="none">
                    {t("LearnMore")}
                  </MuiLink>
                </CardActions>
              </Card>
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12} md={3}>
            <ArazGridItem>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ backgroundColor: "navbar.main" }}>
                      <AssignmentIcon />
                    </Avatar>
                  }
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {t("Tabs.ManufactureOrder.Schedule")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica
                  </Typography>
                </CardContent>
                <CardActions sx={{ float: "right" }}>
                  <MuiLink component={Link} to="/" underline="none">
                    {t("LearnMore")}
                  </MuiLink>
                </CardActions>
              </Card>
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12} md={3}>
            <ArazGridItem>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ backgroundColor: "navbar.main" }}>
                      <PurchaseIcon />
                    </Avatar>
                  }
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {t("PurchasesRole")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica
                  </Typography>
                </CardContent>
                <CardActions sx={{ float: "right" }}>
                  <MuiLink component={Link} to="/" underline="none">
                    {t("LearnMore")}
                  </MuiLink>
                </CardActions>
              </Card>
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12} md={3}>
            <ArazGridItem>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ backgroundColor: "navbar.main" }}>
                      <WarehouseIcon />
                    </Avatar>
                  }
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {t("WarehousesRole")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica
                  </Typography>
                </CardContent>
                <CardActions sx={{ float: "right" }}>
                  <MuiLink component={Link} to="/" underline="none">
                    {t("LearnMore")}
                  </MuiLink>
                </CardActions>
              </Card>
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12} md={3}>
            <ArazGridItem>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ backgroundColor: "navbar.main" }}>
                      <ProductIcon />
                    </Avatar>
                  }
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {t("Product")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica
                  </Typography>
                </CardContent>
                <CardActions sx={{ float: "right" }}>
                  <MuiLink component={Link} to="/" underline="none">
                    {t("LearnMore")}
                  </MuiLink>
                </CardActions>
              </Card>
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12} md={3}>
            <ArazGridItem>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ backgroundColor: "navbar.main" }}>
                      <PeopleIcon />
                    </Avatar>
                  }
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {t("Person")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica
                  </Typography>
                </CardContent>
                <CardActions sx={{ float: "right" }}>
                  <MuiLink component={Link} to="/" underline="none">
                    {t("LearnMore")}
                  </MuiLink>
                </CardActions>
              </Card>
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12} md={3}>
            <ArazGridItem>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ backgroundColor: "navbar.main" }}>
                      <InvoiceIcon />
                    </Avatar>
                  }
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {t("Invoice")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica
                  </Typography>
                </CardContent>
                <CardActions sx={{ float: "right" }}>
                  <MuiLink component={Link} to="/" underline="none">
                    {t("LearnMore")}
                  </MuiLink>
                </CardActions>
              </Card>
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12} md={3}>
            <ArazGridItem>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ backgroundColor: "navbar.main" }}>
                      <SettingIcon />
                    </Avatar>
                  }
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {t("Settings")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica
                  </Typography>
                </CardContent>
                <CardActions sx={{ float: "right" }}>
                  <MuiLink component={Link} to="/" underline="none">
                    {t("LearnMore")}
                  </MuiLink>
                </CardActions>
              </Card>
            </ArazGridItem>
          </ArazGrid>
        </ArazGrid>
      </ArazContainer>
    </ArazBox>
  );
}
