import { useMemo, useState } from "react";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { useTranslation } from "react-i18next";
import { EntityTypes } from "Services/Base/constants";
import ArazTabs from "components/ArazComponents/ArazTabs";
import ArazTabPanel, { ArazTabProps } from "components/ArazComponents/ArazTabPanel";
import ArazActiveTab from "components/ArazComponents/ArazActiveTab";
import ArazArchiveTab from "components/ArazComponents/ArazArchiveTab";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { Delete } from "Services/Base/ApiBaseService";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import ArazButton from "components/ArazComponents/ArazButton";
import ManufacturingWorkOrderHistoryList from "./ManufacturingWorkOrderHistoryList";
import { Completed, InProgress, LoadActiveData, LoadArchiveData, Paused, UpdateManufacturingWorkOrderHistory } from "Services/ManufactureOrders/ManufactureWorkOrderService";
import ManufacturingWorkOrderHistoryItem from "./ManufacturingWorkOrderHistoryItem";
import ArazBox from "components/ArazComponents/ArazBox";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CheckIcon from "@mui/icons-material/Check";
import PauseIcon from "@mui/icons-material/Pause";
import { FormatTimeStamp } from "Helper/DateTimeHelper";
export default function ManufacturingWorkOrderList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {language},
    dispatch,
  } = useValue();

  const tabs = {
    inProgressTab: 0,
    doneTab: 1,
  };

  const confirm = useConfirm();

  const [tabValue, setTabValue] = useState(tabs.inProgressTab);
  const [trigger, setTrigger] = useState(false);

  const [historyItem, setHistoryItem] = useState({});
  const [openPauseHistoryItem, setOpenPauseHistoryItem] = useState(false);
  const [openCompletedHistoryItem, setOpenCompletedHistoryItem] = useState(false);

  async function HandleOnPauseItemSave(value) {
    const duration = FormatTimeStamp(value.realDuration);
    var result = await Paused({ navigate, dispatch, t }, value.manufacturingWorkOrderId, duration);
    if (result && result.isSuccess) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
      setTrigger(!trigger);
    } else dispatch({ type: "UPDATE_ALERT", payload: { message: t("WentWrong.Retry"), severity: "error", open: true } });
  }

  async function HandleOnCompletedItemSave(value) {
    const duration = FormatTimeStamp(value.realDuration);
    var result = await Completed({ navigate, dispatch, t }, value.manufacturingWorkOrderId, duration);
    if (result && result.isSuccess) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
      setTrigger(!trigger);
    } else dispatch({ type: "UPDATE_ALERT", payload: { message: t("WentWrong.Retry"), severity: "error", open: true } });
  }

  const [historyItems, setHistoryItems] = useState([]);
  const [manufacturingWorkOrderId, setManufacturingWorkOrderId] = useState(0);
  const [openManufacturingWorkOrderHistoryList, setOpenManufacturingWorkOrderHistoryList] = useState(false);
  async function HandleOnHistoryItemSave(value) {
    if (!value) {
      setTrigger(!trigger);
      return;
    }
    var result = await UpdateManufacturingWorkOrderHistory(
      { navigate, dispatch, t },
      {
        id: value.id,
        manufacturingWorkOrderId: value.manufacturingWorkOrderId,
        manufacturingWorkOrderHistoryId: value.id,
        realDuration: value.realDuration,
      }
    );
    if (result && result.isSuccess) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
      setTrigger(!trigger);
    } else dispatch({ type: "UPDATE_ALERT", payload: { message: t("WentWrong.Retry"), severity: "error", open: true } });
  }

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "manufacturingOrderCode",
        headerName: t("Manufactures.ManufacturingWorkOrder.Field.ManufacturingOrderCode"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "productName",
        headerName: t("Manufactures.ManufacturingWorkOrder.Field.ProductName"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "processName",
        headerName: t("Manufactures.ManufacturingWorkOrder.Field.ProcessName"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "workCenterName",
        headerName: t("Manufactures.ManufacturingWorkOrder.Field.WorkCenterName"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "expectedDuration",
        headerName: t("Manufactures.ManufacturingWorkOrder.Field.ExpectedDuration"),
        flex: 1,
        minWidth: 150,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "totalRealDuration",
        headerName: t("Manufactures.ManufacturingWorkOrder.Field.TotalRealDuration"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          if (!params.value) return "";
          return (
            <ArazButton
              onClick={(event) => {
                event.stopPropagation();
                setHistoryItems(params.row.manufacturingWorkOrderHistories);
                setManufacturingWorkOrderId(params.id);
                setOpenManufacturingWorkOrderHistoryList(true);
              }}
            >
              {params.value}
            </ArazButton>
          );
        },
      },
      {
        field: "statusString",
        headerName: t("Manufactures.ManufacturingWorkOrder.Field.Status"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },

      {
        field: "actions",
        type: "actions",
        flex: 1,
        minWidth: 100,
        getActions: (params) => {
          const actions = [];
          if (params.row.canInProgress) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title={t("Commons.InProgress")}>
                    <PlayArrowIcon color="success" />
                  </Tooltip>
                }
                label={t("Commons.InProgress")}
                onClick={async () => {
                  const result = await InProgress({ navigate, dispatch, t }, params.id);
                  if (result.isSuccess) setTrigger((trigger) => !trigger);
                }}
              />
            );
          }
          if (params.row.canCompleted) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title={t("Commons.Completed")}>
                    <CheckIcon color="success" />
                  </Tooltip>
                }
                label={t("Commons.Completed")}
                onClick={() => {
                  setHistoryItem({ id: 0, realDuration: "", manufacturingWorkOrderId: params.row.id });
                  setOpenCompletedHistoryItem(true);
                }}
              />
            );
          }
          if (params.row.canPaused) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title={t("Commons.Paused")}>
                    <PauseIcon color="warning" />
                  </Tooltip>
                }
                label={t("Commons.Paused")}
                onClick={() => {
                  setHistoryItem({ id: 0, realDuration: "", manufacturingWorkOrderId: params.row.id });
                  setOpenPauseHistoryItem(true);
                }}
              />
            );
          }

          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              color="error"
              onClick={() => {
                confirm({
                  confirmationButtonProps: { color: "error", variant: "contained" },
                  titleProps: { color: "error" },
                  title: t("Commons.Delete"),
                  description: t("Commons.Delete.confirm"),
                  confirmationText: t("Commons.Delete"),
                  cancellationText: t("Commons.Cancel"),
                })
                  .then(async () => {
                    const result = await Delete({ navigate, dispatch, t }, EntityTypes.ManufacturingWorkOrder, params.id);
                    if (result.isSuccess) setTrigger((trigger) => !trigger);
                  })
                  .catch(() => {});
              }}
            />
          );

          return actions;
        },
        align: "center",
        headerAlign: "center",
      },
    ],
    [language]
  );

  return (
    <>
      <ArazBox>
        <ArazTabs
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
            setTrigger(!trigger);
          }}
        >
          <ArazActiveTab label={t("Commons.Tabs.InProgress")} {...ArazTabProps(tabs.inProgressTab)} />
          <ArazArchiveTab label={t("Commons.Tabs.Done")} {...ArazTabProps(tabs.doneTab)} style={{ left: "20px" }} />
        </ArazTabs>

        <ArazTabPanel value={tabValue} index={tabValue}>
          <ArazDataGrid onRowClick={async (param) => {}} columns={columns} fetchFunction={tabValue == tabs.inProgressTab ? LoadActiveData : LoadArchiveData} trigger={trigger} />
        </ArazTabPanel>
      </ArazBox>

      {openManufacturingWorkOrderHistoryList && (
        <ManufacturingWorkOrderHistoryList
          open={openManufacturingWorkOrderHistoryList}
          setOpen={setOpenManufacturingWorkOrderHistoryList}
          manufacturingWorkOrderId={manufacturingWorkOrderId}
          values={historyItems}
          onSave={HandleOnHistoryItemSave}
        />
      )}
      {openPauseHistoryItem && <ManufacturingWorkOrderHistoryItem open={openPauseHistoryItem} setOpen={setOpenPauseHistoryItem} value={historyItem} onSave={HandleOnPauseItemSave} />}
      {openCompletedHistoryItem && <ManufacturingWorkOrderHistoryItem open={openCompletedHistoryItem} setOpen={setOpenCompletedHistoryItem} value={historyItem} onSave={HandleOnCompletedItemSave} />}
    </>
  );
}
