import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { TextField, Typography } from "@mui/material";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import { useFormik } from "formik";
import * as yup from "yup";
import ArazDatePicker from "components/ArazComponents/ArazDatePicker";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { PartiallyReceived, UpdatePurchaseOrderItemReceive } from "Services/PurchaseOrders/PurchaseOrderService";

export default function PurchaseOrderItemReceivePartially({ open, setOpen, value, onSave }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({
    quantity: yup.number().required().positive().label(t("Manufactures.PurchaseOrderItem.Field.Quantity")),
  });

  const formik = useFormik({
    initialValues: {
      purchaseOrderId: value.purchaseOrderId,
      purchaseOrderItemId: value.purchaseOrderItemId,
      id: value.id,
      receivedDate: value.receivedDate,
      quantity: value.quantity,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.setStatus({ error: null });
      const result =
        value.id == 0
          ? await PartiallyReceived(
              { navigate, dispatch, t },
              {
                id: values.purchaseOrderId,
                code: values.code,
                receivedDate: values.receivedDate,
                purchaseOrderItems: [{ id: values.purchaseOrderItemId, quantity: values.quantity }],
              }
            )
          : await UpdatePurchaseOrderItemReceive(
              { navigate, dispatch, t },
              {
                purchaseOrderId: values.purchaseOrderId,
                purchaseOrderItemId: values.purchaseOrderItemId,
                id: values.id,
                code: values.code,
                receivedDate: values.receivedDate,
                quantity: values.quantity,
              }
            );

      if (result && result.isSuccess) {
        dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
        onSave(result);
        close();
      } else formik.setStatus({ error: result.error });
    },
  });

  function close() {
    setOpen(false);
  }
  return (
    <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
      <form>
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {t("Commons.Receive")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid xs={12}>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazDatePicker
                  id="receivedDate"
                  name="receivedDate"
                  value={formik.values.receivedDate}
                  setValue={(v) => formik.setFieldValue("receivedDate", v)}
                  onBlur={formik.handleBlur}
                  label={t("Manufactures.PurchaseOrderItemReceive.Field.ReceivedDate")}
                  error={formik.touched.receivedDate && Boolean(formik.errors.receivedDate)}
                  helperText={formik.touched.receivedDate && formik.errors.receivedDate}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="quantity"
                  name="quantity"
                  label={t("Manufactures.PurchaseOrderItemReceive.Field.Quantity")}
                  type="number"
                  fullWidth
                  value={formik.values.quantity}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("quantity", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                  helperText={formik.touched.quantity && formik.errors.quantity}
                />
              </ArazGridItem>
            </ArazGrid>

            {formik.status && formik.status.error && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  {formik.status.error.map((e, i) => (
                    <Typography component="span" color="error" key={i}>
                      {e[Object.keys(formik.status.error[0])[0]]}
                    </Typography>
                  ))}
                </ArazGridItem>
              </ArazGrid>
            )}
          </ArazGrid>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="text" autoFocus onClick={close}>
            {t("Commons.Cancel")}
          </Button>
          <Button autoFocus type="submit" color="success" variant="contained" onClick={formik.handleSubmit}>
            {t("Commons.Save")}
          </Button>
        </DialogActions>
      </form>
    </ArazDialog>
  );
}
