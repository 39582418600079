import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector, GridToolbarQuickFilter, GridLinkOperator } from "@mui/x-data-grid";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LoadData } from "Services/Base/ApiBaseService";
import { useValue } from "context/ContextProvider";
import { t } from "i18next";

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 5,
        pb: 2,
        display: "flex",
        justifyContent: "end",
      }}
    >
      <GridToolbarQuickFilter debounceMs={500} placeholder={t("Commons.Search")} />

      {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
    </Box>
  );
}

const ArazDataGrid = ({ entityType, filters, trigger, fetchFunction, manual, ...props }) => {
  const [sortOption, setSortOption] = useState({});
  const [filterOption, setFilterOption] = useState({});
  const {
    state: {},
    dispatch,
  } = useValue();
  const navigate = useNavigate();

  const onFilterChange = useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    setFilterOption(filterModel);
  }, []);

  const handleSortModelChange = useCallback((sortModel) => {
    //setQueryOptions({ sortModel: [...sortModel] });
    setSortOption(sortModel);
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport /> */}
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  const handleOnRowClick = (param) => {
    navigate(`${param.id}`);
  };

  const [items, setItems] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = useState(0);
  const [page, setPage] = useState(0);

  const loadData = async () => {
    let result;
    if (fetchFunction)
      result = await fetchFunction(
        { navigate, dispatch, t },
        {
          pageSize,
          page,
          sortOption,
          filterOption,
          filters,
        }
      );
    else
      result = await LoadData({ navigate, dispatch, t }, entityType, {
        pageSize,
        page,
        sortOption,
        filterOption,
        filters,
      });
    setItems(result.data.data);
    setRowCountState(result.data.totalCount);
  };

  useEffect(() => {
    if (!manual) loadData();
  }, [pageSize, page, sortOption, filterOption, trigger, filters]);

  return (
    <DataGrid
      autoHeight
      rows={items}
      sortingMode="server"
      paginationMode="server"
      rowCount={rowCountState}
      page={page}
      onPageChange={(newPage) => setPage(newPage)}
      onSortModelChange={handleSortModelChange}
      rowsPerPageOptions={[10, 20, 50]}
      pagination={true}
      getRowId={(row) => row.id}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      // getRowSpacing={(params) => ({
      //   top: params.isFirstVisible ? 0 : 5,
      //   bottom: params.isLastVisible ? 0 : 5,
      // })}
      sx={{
        //boxShadow: "0px 10px 96px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
        "& .MuiDataGrid-columnHeader:focus": {
          outline: "unset",
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: "unset",
        },
        "& .MuiDataGrid-cell:focus": {
          outline: "unset",
        },
        "& .MuiDataGrid-cell:focus-within": {
          outline: "unset",
        },
        [`& .${gridClasses.row}`]: {
          bgcolor: (theme) => (theme.palette.mode === "light" ? "common.white" : grey[900]),
          cursor: "pointer",
        },
      }}
      //onCellEditCommit={(params) => setRowId(params.Id)}
      // components={{ Toolbar: CustomToolbar }}
      // componentsProps={{
      //   toolbar: {
      //     showQuickFilter: true,
      //     quickFilterProps: { debounceMs: 500 },
      //   },
      // }}
      onFilterModelChange={onFilterChange}
      //disableColumnFilter
      disableColumnSelector
      disableSelectionOnClick
      //disableDensitySelector
      // components={{ Toolbar: GridToolbar }}
      // componentsProps={{
      //   toolbar: {
      //     showQuickFilter: true,
      //     quickFilterProps: { debounceMs: 500 },
      //   },
      // }}
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterLogicOperator: GridLinkOperator.Or,
          },
        },
      }}
      components={{ Toolbar: QuickSearchToolbar }}
      onRowClick={handleOnRowClick}
      {...props}
    />
  );
};

export default ArazDataGrid;
