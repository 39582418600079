import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { TextField, Typography } from "@mui/material";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import { EntityTypes } from "Services/Base/constants";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { Insert, Update } from "Services/Base/ApiBaseService";
import Roles from "./Roles";
import { useState } from "react";

export default function User({ open, setOpen, value, onSave }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [roles, setRoles] = useState(value.roleIds);

  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({
    username: yup.string().required().label(t("Commons.Authenticate.Field.Username")),
    password: value.id > 0 ? yup.string().label(t("Commons.Authenticate.Field.Password")) : yup.string().required().label(t("Commons.Authenticate.Field.Password")),
    name: yup.string().required().label(t("Commons.Authenticate.Field.Name")),
  });

  useEffect(() => {
    formik.resetForm();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: value.id,
      username: value.username,
      email: value.email,
      name: value.name,
      password: value.password,
      roleIds: value.roleIds,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.setStatus({ error: null });

      const data = {
        id: values.id,
        username: values.username,
        email: values.email,
        name: values.name,
        password: values.password,
        roleIds: roles.map((m) => m.id),
      };

      const result = values.id == 0 ? await Insert({ navigate, dispatch, t }, EntityTypes.User, data) : await Update({ navigate, dispatch, t }, EntityTypes.User, data);
      if (result && result.isSuccess) {
        dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
        onSave();
        close();
      } else formik.setStatus({ error: result.error });
    },
  });

  function close() {
    setOpen(false);
  }
  return (
    <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
      <form>
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {value.id == 0 ? t("Commons.New") : t("Commons.Edit")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid xs={12}>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="name"
                  name="name"
                  label={t("Commons.User.Field.Name")}
                  fullWidth
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("name", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="username"
                  name="username"
                  label={t("Commons.User.Field.Username")}
                  fullWidth
                  value={formik.values.username}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("username", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="password"
                  name="password"
                  label={t("Commons.User.Field.Password")}
                  fullWidth
                  type="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("password", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <Roles value={roles} setValue={setRoles} />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </DialogContent>
        <DialogActions>
          {formik.status &&
            formik.status.error &&
            formik.status.error.map((e, i) => (
              <Typography component="span" color="error" key={i}>
                {e[Object.keys(formik.status.error[0])[0]]}
              </Typography>
            ))}
          <Button color="error" variant="text" autoFocus onClick={close}>
            {t("Commons.Cancel")}
          </Button>
          <Button autoFocus type="submit" color="success" variant="contained" onClick={formik.handleSubmit}>
            {t("Commons.Save")}
          </Button>
        </DialogActions>
      </form>
    </ArazDialog>
  );
}
