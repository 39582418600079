import { useMemo, useState } from "react";
import ArazTabPanel, { ArazTabProps } from "components/ArazComponents/ArazTabPanel";
import ArazTabs from "components/ArazComponents/ArazTabs";
import ArazArchiveTab from "components/ArazComponents/ArazArchiveTab";
import ArazActiveTab from "components/ArazComponents/ArazActiveTab";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { useTranslation } from "react-i18next";
import { AvailabilityStatuses, EntityTypes, ManufacturingOrderStatusTypes } from "Services/Base/constants";
import { Chip, Rating, Typography } from "@mui/material";
import { LocalDateFormat } from "i18n/LocalDate";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { Delete } from "Services/Base/ApiBaseService";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import ArazBox from "components/ArazComponents/ArazBox";
import ArazGrid from "components/ArazComponents/ArazGrid";
import ArazAddButton from "components/ArazComponents/ArazAddButton";

export default function ManufactureOrderList() {
  const tabs = {
    inProgressTab: 0,
    doneTab: 1,
  };

  const { t } = useTranslation();
  const confirm = useConfirm();
  const [tabValue, setTabValue] = useState(tabs.inProgressTab);
  const [trigger, setTrigger] = useState(false);
  const navigate = useNavigate();
  const {
    state: {language},
    dispatch,
  } = useValue();

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "code",
        headerName: t("Manufactures.ManufacturingOrder.Field.Code"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "productName",
        headerName: t("Manufactures.ManufacturingOrder.Field.ProductName"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "productCategoryName",
        headerName: t("Manufactures.ManufacturingOrder.Field.ProductCategoryName"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "quantity",
        headerName: t("Manufactures.ManufacturingOrder.Field.Quantity"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "deadline",
        headerName: t("Manufactures.ManufacturingOrder.Field.Deadline"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        renderCell: (params) =>
          tabValue == tabs.inProgressTab && new Date(params.value).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) ? (
            <Typography variant="div" color="error">{`${LocalDateFormat(params.value)}`}</Typography>
          ) : (
            LocalDateFormat(params.value)
          ),
      },
      {
        field: "priority",
        headerName: t("Manufactures.ManufacturingOrder.Field.Priority"),
        flex: 1,
        minWidth: 130,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => <Rating name="half-rating-read" defaultValue={params.value} precision={0.5} readOnly />,
      },
      {
        field: "statusString",
        headerName: t("Manufactures.ManufacturingOrder.Field.Status"),
        flex: 1,
        minWidth: 150,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) =>
          params.row.status == ManufacturingOrderStatusTypes.Cancelled || params.row.status == ManufacturingOrderStatusTypes.Draft ? (
            <Chip label={params.value} color="error" />
          ) : params.row.status == ManufacturingOrderStatusTypes.Confirmed ||
            params.row.status == ManufacturingOrderStatusTypes.InProgress ||
            params.row.status == ManufacturingOrderStatusTypes.Paused ? (
            <Chip label={params.value} color="warning" />
          ) : params.row.status == ManufacturingOrderStatusTypes.Done ? (
            <Chip label={params.value} color="success" />
          ) : (
            params.value
          ),
      },
      {
        field: "availabilityStatusesString",
        headerName: t("Manufactures.ManufacturingOrder.Field.AvailabilityStatus"),
        flex: 1,
        minWidth: 180,
        align: "center",
        headerAlign: "center",
        sortable: false,
        hide: !(tabValue == tabs.inProgressTab),
        renderCell: (params) => {
          const value = params.row.availabilityStatus == AvailabilityStatuses.Expected ? params.value + " " + LocalDateFormat(params.row.expectedDate) : params.value;
          return params.row.availabilityStatus == AvailabilityStatuses.NotAvailable ? (
            <Chip label={value} color="error" />
          ) : params.row.availabilityStatus == AvailabilityStatuses.InStock ? (
            <Chip label={value} color="success" />
          ) : (
            <Chip label={value} color="warning" />
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        minWidth: 100,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(async () => {
                  const result = await Delete({ navigate, dispatch, t }, EntityTypes.ManufacturingOrder, params.id);
                  if (result.isSuccess) setTrigger((trigger) => !trigger);
                })
                .catch(() => {});
            }}
          />,
        ],
        align: "center",
        headerAlign: "center",
      },
    ],
    [tabValue,language]
  );

  const filters = useMemo(() => {
    if (tabValue == tabs.inProgressTab) return [["status", "=", ManufacturingOrderStatusTypes.Confirmed], "or", ["status", "=", ManufacturingOrderStatusTypes.InProgress]];
    else
      return [
        ["status", "=", ManufacturingOrderStatusTypes.Done],
        "or",
        ["status", "=", ManufacturingOrderStatusTypes.Draft],
        "or",
        ["status", "=", ManufacturingOrderStatusTypes.Paused],
      ];
  }, [tabValue,language]);

  return (
    <ArazBox>
      <ArazGrid sx={{ float: { md: "right" } }}>
        <ArazGrid xs={12}>
          <ArazAddButton label={t("Manufactures.New")} />
        </ArazGrid>
      </ArazGrid>

      <ArazTabs
        value={tabValue}
        onChange={(event, newValue) => {
          setTabValue(newValue);
        }}
      >
        <ArazActiveTab label={t("Commons.Tabs.InProgress")} {...ArazTabProps(tabs.inProgressTab)} />
        <ArazArchiveTab label={t("Commons.Tabs.Done")} {...ArazTabProps(tabs.doneTab)} style={{ left: "20px" }} />
      </ArazTabs>

      <ArazTabPanel value={tabValue} index={tabValue}>
        <ArazDataGrid columns={columns} entityType={EntityTypes.ManufacturingOrder} filters={filters} trigger={trigger} />
      </ArazTabPanel>
    </ArazBox>
  );
}
