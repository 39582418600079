import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { useState } from "react";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { useMemo } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { EntityTypes } from "Services/Base/constants";
import { LocalDateFormat } from "i18n/LocalDate";
import SaleOrderItemPackPartially from "./SaleOrderItemPackPartially";
import { useEffect } from "react";
import { DeleteSaleOrderItemPack } from "Services/SalesOrders/SaleOrderService";
import SendIcon from "@mui/icons-material/Send";
import { Tooltip } from "@mui/material";
import SaleOrderItemPackSendPartially from "./SaleOrderItemPackSendPartially";

export default function SaleOrderItemPackList({ open, setOpen, saleOrderId, saleOrderItemId, remainQuantity, values, onSave }) {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState(false);
  const [openSaleOrderItemPackPartially, setOpenSaleOrderItemPackPartially] = useState(false);
  const [openSaleOrderItemPackSendPartially, setOpenSaleOrderItemPackSendPartially] = useState(false);

  const {
    state: {language},
    dispatch,
  } = useValue();

  useEffect(() => {
    setItems(values);
  }, [values]);

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "code",
        headerName: t("Manufactures.SaleOrder.Field.Code"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "quantity",
        headerName: t("Manufactures.SaleOrderItemPack.Field.Quantity"),
        flex: 1,
        minWidth: 100,
        type: "number",
        align: "center",
        headerAlign: "center",
      },
      {
        field: "packDate",
        headerName: t("Manufactures.SaleOrderItemPack.Field.PackDate"),
        type: "date",
        flex: 1,
        minWidth: 150,
        align: "center",
        headerAlign: "center",
        valueFormatter: (params) => {
          return `${LocalDateFormat(params.value)}`;
        },
      },
      {
        field: "saleOrderItemPackSend.sendDate",
        headerName: t("Manufactures.SaleOrderItemPackSend.Field.SendDate"),
        type: "date",
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          return params.row.saleOrderItemPackSend?.sendDate ? `${LocalDateFormat(params.row.saleOrderItemPackSend.sendDate)}` : "";
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        minWidth: 100,
        getActions: (params) => {
          const actions = [];

          actions.push(
            <GridActionsCellItem
              icon={
                <Tooltip title={!params.row.saleOrderItemPackSend ? t("Commons.Send") : `${t("Commons.Edit")} ${t("Commons.Send")}`}>
                  <SendIcon />
                </Tooltip>
              }
              label={t("Commons.Send")}
              onClick={() => {
                if (!params.row.saleOrderItemPackSend) openItemSend({ id: 0, saleOrderId, saleOrderItemPackId: [params.id], sendDate: new Date() });
                else {
                  openItemSend({ id: params.row.saleOrderItemPackSend.id, saleOrderId, saleOrderItemPackId: params.id, sendDate: params.row.saleOrderItemPackSend.sendDate });
                }
              }}
            />
          );

          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              color="error"
              onClick={() => {
                confirm({
                  confirmationButtonProps: { color: "error", variant: "contained" },
                  titleProps: { color: "error" },
                  title: t("Commons.Delete"),
                  description: t("Commons.Delete.confirm"),
                  confirmationText: t("Commons.Delete"),
                  cancellationText: t("Commons.Cancel"),
                })
                  .then(async () => {
                    const result = await DeleteSaleOrderItemPack({ navigate, dispatch,t }, { saleOrderId, saleOrderItemPackId: params.id });
                    if (result.isSuccess) handleOnSaveItemPackPartially(result);
                  })
                  .catch(() => {});
              }}
            />
          );
          return actions;
        },
        align: "center",
        headerAlign: "center",
      },
    ],
    [language]
  );

  const [item, setItem] = useState({ id: 0, packDate: new Date(), code: "", quantity: 1, saleOrderId, saleOrderItemId });
  async function openItem(v) {
    await setItem(v);
    setOpenSaleOrderItemPackPartially(true);
  }

  const [itemSend, setItemSend] = useState({ id: 0, sendDate: new Date(), saleOrderId, saleOrderItemId });
  async function openItemSend(v) {
    await setItemSend(v);
    setOpenSaleOrderItemPackSendPartially(true);
  }

  function handleOnSaveItemPackPartially(v) {
    onSave(v);
  }

  function close() {
    setOpen(false);
  }
  return (
    <>
      <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="lg">
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {t("Commons.Pack")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazAddButton
                label={t("Commons.Insert")}
                left
                onClick={async () => await openItem({ saleOrderId, saleOrderItemId, id: 0, packDate: new Date(), quantity: remainQuantity })}
                sx={{ mt: 1 }}
              />
            </ArazGridItem>
            <ArazGridItem>
              <ArazDataGrid
                manual
                rows={items}
                columns={columns}
                sortingMode="client"
                paginationMode="client"
                rowCount={items.length}
                onRowClick={async (param) => {
                  await openItem({ saleOrderId, saleOrderItemId, id: param.id, packDate: param.row.packDate, code: param.row.code, quantity: param.row.quantity });
                }}
              />
            </ArazGridItem>
          </ArazGrid>
        </DialogContent>
        <DialogActions></DialogActions>
      </ArazDialog>
      {openSaleOrderItemPackPartially && (
        <SaleOrderItemPackPartially open={openSaleOrderItemPackPartially} setOpen={setOpenSaleOrderItemPackPartially} value={item} onSave={handleOnSaveItemPackPartially} />
      )}
      {openSaleOrderItemPackSendPartially && (
        <SaleOrderItemPackSendPartially open={openSaleOrderItemPackSendPartially} setOpen={setOpenSaleOrderItemPackSendPartially} value={itemSend} onSave={handleOnSaveItemPackPartially} />
      )}
    </>
  );
}
