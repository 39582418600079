import "./login.css";
import bg from "./bg-min.png";
import { useTranslation } from "react-i18next";
import { FormControl, FormHelperText, InputLabel, TextField, Typography } from "@mui/material";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { useNavigate, useParams } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Container } from "@mui/system";
import { GetVerificationCode, VerifyToken, Register as RegisterApi } from "Services/Common/AuthenticateService";
import ArazButton from "components/ArazComponents/ArazButton";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { GetCurrentCustomer } from "Services/Security/UserService";
import { useState } from "react";
import { Button } from "@mui/material";
import OTPInput from "Pages/Commons/OTP/OTPInput";
import { useEffect } from "react";
import menuUnderlineIcon from "./menu-underline-min.png";
import { VerificationTokenTypes } from "Services/Base/constants";
import LanguageSelector from "i18n/LanguageSelector";

export default function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let { username, token } = useParams();
  username = username || "";
  token = token || "";

  const steps = {
    UserNameStep: 0,
    VerificationStep: 1,
    CompleteStep: 2,
  };
  const [step, setStep] = useState(steps.UserNameStep);
  const {
    state: { language },
    dispatch,
  } = useValue();

  useEffect(() => {
    dispatch({ type: "SET_TOKEN", payload: null });
    dispatch({ type: "UPDATE_USER", payload: null });

    if (username && token) {
      formik.setFieldValue("username", username);
      formik.setFieldValue("verificationToken", token);
      verificationFormik.setFieldValue("verificationToken", token);

      setStep(steps.VerificationStep);
      verificationFormik.submitForm();
    }
  }, []);

  ////////// user name
  const userValidationSchema = yup.object().shape({
    username: yup.string().required().label(t("Commons.Authenticate.Field.Username")),
  });
  const userFormik = useFormik({
    initialValues: {
      username: username,
    },
    validationSchema: userValidationSchema,
    onSubmit: async (values) => {
      ClearErrors();
      const result = await GetVerificationCode({ navigate, dispatch, t }, values.username, language, VerificationTokenTypes.Register);

      if (result && result.isSuccess) {
        dispatch({ type: "UPDATE_ALERT", payload: { message: t("Register.SmsSend"), severity: "success", open: true } });
        setStep(steps.VerificationStep);
        formik.setFieldValue("username", values.username);
      } else userFormik.setStatus({ error: result.error });
    },
  });

  ////////// verification
  const verificationValidationSchema = yup.object().shape({
    verificationToken: yup.string().required().label(t("Commons.Authenticate.Field.VerificationToken")),
  });
  const verificationFormik = useFormik({
    initialValues: {
      verificationToken: token,
    },
    validationSchema: verificationValidationSchema,
    onSubmit: async (values) => {
      ClearErrors();

      const result = await VerifyToken({ navigate, dispatch, t }, formik.values.username, values.verificationToken, language);

      if (result && result.isSuccess) {
        setStep(steps.CompleteStep);
        formik.setFieldValue("verificationToken", values.verificationToken);
      } else verificationFormik.setStatus({ error: result.error });
    },
  });

  /////////// register
  const validationSchema = yup.object().shape({
    username: yup.string().required().label(t("Commons.Authenticate.Field.Username")),
    password: yup.string().required().label(t("Commons.Authenticate.Field.Password")),
    name: yup.string().required().label(t("Commons.Authenticate.Field.Name")),
    retypePassword: yup.string().required().label(t("Commons.Authenticate.Field.RetypePassword")),
    verificationToken: yup.string().required().label(t("Commons.Authenticate.Field.VerificationToken")),
    companyName: yup.string().required().label(t("Commons.Authenticate.Field.CompanyName")),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      name: "",
      email: "",
      password: "",
      retypePassword: "",
      verificationToken: "",
      companyName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      ClearErrors();

      const result = await RegisterApi(
        { navigate, dispatch, t },
        {
          username: values.username,
          name: values.name,
          email: values.email,
          password: values.password,
          retypePassword: values.retypePassword,
          verificationToken: values.verificationToken,
          companyName: values.companyName,
          language: localStorage.getItem("i18nextLng"),
        }
      );

      if (result && result.isSuccess) {
        dispatch({ type: "UPDATE_ALERT", payload: { message: t("Register.Success"), severity: "success", open: true } });
        dispatch({ type: "SET_TOKEN", payload: result.data.token });

        const costomer = await GetCurrentCustomer({ navigate, dispatch, t }, result.data.token);
        if (costomer && costomer.isSuccess) {
          dispatch({ type: "UPDATE_USER", payload: costomer.data });
        }
        navigate("/");
      } else formik.setStatus({ error: result.error });
    },
  });

  function ClearErrors() {
    userFormik.setStatus({ error: null });
    verificationFormik.setStatus({ error: null });
    formik.setStatus({ error: null });
  }
  return (
    <form>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          height: "100vh",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          filter: "blur(15.5px)",
          position: "absolute",
          zIndex: "-1",
        }}
      />
      <Container maxWidth="xs">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ height: "100vh" }}>
          <ArazGrid xs={12} justifyContent="center" sx={{ mb: 2 }}>
            <img src="/logo.png" style={{ width: "50%" }} />
            <Typography variant="subtitle1" color="navbar.light" sx={{ mt: 1 }}>
              {t("App.Name")}
            </Typography>
            <LanguageSelector />
          </ArazGrid>
          <ArazGrid xs={12} sx={{ borderRadius: 4, p: 1, pt: 2, backgroundColor: "white" }}>
            <ArazGrid xs={12} sx={{ mb: 5 }}>
              <ArazGridItem>
                <img
                  style={{
                    height: "60px",
                    width: "25px",
                    top: "-12px",
                    position: "relative",
                    left: language == "fa" ? "16px" : "4px",
                    display: "flex",
                    float: language == "fa" ? "right" : "left",
                    marginLeft: "-20px",
                    transform: language == "fa" ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                  src={menuUnderlineIcon}
                />
                <Link component={RouterLink} underline="none" fontSize={25} color="navbar.main" to="/Register" sx={{ mr: 4, cursor: "default" }}>
                  {t("Register")}
                </Link>
                <Link component={RouterLink} underline="none" fontSize={20} color="common.black" to="/Login" sx={{ mr: 4 }}>
                  {t("Login")}
                </Link>
                {step == steps.VerificationStep && (
                  <Button
                    underline="none"
                    fontSize={25}
                    color="warning"
                    sx={{ float: "right" }}
                    onClick={() => {
                      ClearErrors();
                      setStep(steps.UserNameStep);
                    }}
                  >
                    {t("Return")}
                  </Button>
                )}
              </ArazGridItem>
            </ArazGrid>

            {step == steps.UserNameStep && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  <TextField
                    id="username"
                    name="username"
                    label={t("Commons.Authenticate.Field.Username")}
                    type={language == "fa" ? "number" : "text"}
                    fullWidth
                    value={userFormik.values.username}
                    onBlur={userFormik.handleBlur}
                    onChange={async (event) => {
                      await userFormik.setFieldValue("username", event.target.value, true);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={userFormik.touched.username && Boolean(userFormik.errors.username)}
                    helperText={userFormik.touched.username && userFormik.errors.username}
                  />
                </ArazGridItem>
              </ArazGrid>
            )}

            {step == steps.VerificationStep && (
              <ArazGrid xs={12}>
                <ArazGridItem sx={{ display: "flex", justifyContent: "center" }}>
                  <FormControl>
                    <InputLabel sx={{ top: -50 }}>{t("Commons.Authenticate.Field.VerificationToken")}</InputLabel>
                    <OTPInput
                      // placeholder={["*", "*", "*", "*", "*", "*"]}
                      autoFocus
                      values={token}
                      onChange={(v) => verificationFormik.setFieldValue("verificationToken", v)}
                      onComplete={verificationFormik.handleSubmit}
                    />
                    {Boolean(verificationFormik.errors.verificationToken) && <FormHelperText sx={{ color: "error.main" }}> {verificationFormik.errors.verificationToken}</FormHelperText>}
                  </FormControl>
                </ArazGridItem>
              </ArazGrid>
            )}

            {step == steps.CompleteStep && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  <TextField
                    id="name"
                    name="name"
                    label={t("Commons.Authenticate.Field.Name")}
                    fullWidth
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                    onChange={async (event) => {
                      await formik.setFieldValue("name", event.target.value, true);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </ArazGridItem>
              </ArazGrid>
            )}

            {step == steps.CompleteStep && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  <TextField
                    id="companyName"
                    name="companyName"
                    label={t("Commons.Authenticate.Field.CompanyName")}
                    fullWidth
                    value={formik.values.companyName}
                    onBlur={formik.handleBlur}
                    onChange={async (event) => {
                      await formik.setFieldValue("companyName", event.target.value, true);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                    helperText={formik.touched.companyName && formik.errors.companyName}
                  />
                </ArazGridItem>
              </ArazGrid>
            )}

            {step == steps.CompleteStep && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  <TextField
                    id="password"
                    name="password"
                    label={t("Commons.Authenticate.Field.Password")}
                    type="password"
                    fullWidth
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    onChange={async (event) => {
                      await formik.setFieldValue("password", event.target.value, true);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                </ArazGridItem>
              </ArazGrid>
            )}
            {step == steps.CompleteStep && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  <TextField
                    id="retypePassword"
                    name="retypePassword"
                    label={t("Commons.Authenticate.Field.RetypePassword")}
                    type="password"
                    fullWidth
                    value={formik.values.retypePassword}
                    onBlur={formik.handleBlur}
                    onChange={async (event) => {
                      await formik.setFieldValue("retypePassword", event.target.value, true);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={formik.touched.retypePassword && Boolean(formik.errors.retypePassword)}
                    helperText={formik.touched.retypePassword && formik.errors.retypePassword}
                  />
                </ArazGridItem>
              </ArazGrid>
            )}

            {formik.status && formik.status.error && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  {formik.status.error.map((e, i) => (
                    <Typography component="span" color="error" key={i}>
                      {e[Object.keys(formik.status.error[0])[0]]}
                    </Typography>
                  ))}
                </ArazGridItem>
              </ArazGrid>
            )}

            {userFormik.status && userFormik.status.error && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  {userFormik.status.error.map((e, i) => (
                    <Typography component="span" color="error" key={i}>
                      {e[Object.keys(userFormik.status.error[0])[0]]}
                    </Typography>
                  ))}
                </ArazGridItem>
              </ArazGrid>
            )}

            {verificationFormik.status && verificationFormik.status.error && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  {verificationFormik.status.error.map((e, i) => (
                    <Typography component="span" color="error" key={i}>
                      {e[Object.keys(verificationFormik.status.error[0])[0]]}
                    </Typography>
                  ))}
                </ArazGridItem>
              </ArazGrid>
            )}

            {step == steps.UserNameStep && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <ArazButton onClick={userFormik.handleSubmit} color="navbar" type="submit">
                      {t("GetVerificationCode")}
                    </ArazButton>
                  </Box>
                </ArazGridItem>
              </ArazGrid>
            )}
            {step == steps.VerificationStep && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <ArazButton onClick={verificationFormik.handleSubmit} color="navbar" type="submit">
                      {t("Commons.Confirm")}
                    </ArazButton>
                  </Box>
                </ArazGridItem>
              </ArazGrid>
            )}
            {step == steps.CompleteStep && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <ArazButton onClick={formik.handleSubmit} color="navbar" type="submit">
                      {t("Commons.Signup")}
                    </ArazButton>
                  </Box>
                </ArazGridItem>
              </ArazGrid>
            )}
          </ArazGrid>
        </Box>
      </Container>
    </form>
  );
}
