import "./login.css";
import bg from "./bg-min.png";
import { useTranslation } from "react-i18next";
import { TextField, Typography } from "@mui/material";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Container } from "@mui/system";
import { GetToken } from "Services/Common/AuthenticateService";
import ArazButton from "components/ArazComponents/ArazButton";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { GetCurrentCustomer } from "Services/Security/UserService";
import { useEffect } from "react";
import menuUnderlineIcon from "./menu-underline-min.png";
import LanguageSelector from "i18n/LanguageSelector";

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { language },
    dispatch,
  } = useValue();

  useEffect(() => {
    dispatch({ type: "SET_TOKEN", payload: null });
    dispatch({ type: "UPDATE_USER", payload: null });
  }, []);

  const validationSchema = yup.object().shape({
    username: yup.string().required().label(t("Commons.Authenticate.Field.Username")),
    password: yup.string().required().label(t("Commons.Authenticate.Field.Password")),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Save(values);
    },
  });

  async function Save(item) {
    formik.setStatus({ error: null });

    const result = await GetToken(
      { navigate, dispatch, t },
      {
        username: item.username,
        password: item.password,
        language: language,
      }
    );

    if (result && result.isSuccess) {
      dispatch({ type: "SET_TOKEN", payload: result.data.token });
      const costomer = await GetCurrentCustomer({ navigate, dispatch, t }, result.data.token);
      if (costomer && costomer.isSuccess) {
        dispatch({ type: "UPDATE_USER", payload: costomer.data });
        navigate("/");
      } else {
        dispatch({ type: "SET_TOKEN", payload: null });
        formik.setStatus({ error: [{ "": [t("WentWrong.Retry")] }] });
      }
    } else formik.setStatus({ error: result.error });
  }

  return (
    <form>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          height: "100vh",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          filter: "blur(15.5px)",
          position: "absolute",
          zIndex: "-1",
        }}
      />
      <Container maxWidth="xs">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ height: "100vh" }}>
          <ArazGrid xs={12} justifyContent="center" sx={{ mb: 2 }}>
            <img src="/logo.png" style={{ width: "50%" }} />
            <Typography variant="subtitle1" color="navbar.light" sx={{ mt: 1 }}>
              {t("App.Name")}
            </Typography>
            <LanguageSelector />
          </ArazGrid>
          <ArazGrid xs={12} sx={{ borderRadius: 4, p: 1, pt: 2, backgroundColor: "white" }}>
            <ArazGrid xs={12} sx={{ mb: 5 }}>
              <ArazGridItem>
                <img
                  style={{
                    height: "60px",
                    width: "25px",
                    top: "-12px",
                    position: "relative",
                    left: language == "fa" ? "16px" : "4px",
                    display: "flex",
                    float: language == "fa" ? "right" : "left",
                    marginLeft: "-20px",
                    transform: language == "fa" ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                  src={menuUnderlineIcon}
                />

                <Link component={RouterLink} underline="none" fontSize={25} color="navbar.main" to="/Login" sx={{ mr: 4, cursor: "default" }}>
                  {t("Login")}
                </Link>
                <Link component={RouterLink} underline="none" fontSize={20} color="common.black" to="/Register" sx={{ mr: 4 }}>
                  {t("Register")}
                </Link>
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="username"
                  name="username"
                  label={t("Commons.Authenticate.Field.Username")}
                  type={language == "fa" ? "number" : "text"}
                  fullWidth
                  value={formik.values.username}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("username", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="password"
                  name="password"
                  label={t("Commons.Authenticate.Field.Password")}
                  type="password"
                  fullWidth
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("password", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </ArazGridItem>
            </ArazGrid>

            {formik.status && formik.status.error && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  {formik.status.error.map((e, i) => (
                    <Typography component="span" color="error" key={i}>
                      {e[Object.keys(formik.status.error[0])[0]]}
                    </Typography>
                  ))}
                </ArazGridItem>
              </ArazGrid>
            )}

            <ArazGrid xs={12}>
              <ArazGridItem>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <ArazButton onClick={formik.handleSubmit} color="navbar" type="submit">
                    {t("Login")}
                  </ArazButton>
                </Box>
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>

          <ArazGrid xs={12} sx={{ mt: 2, borderRadius: 4, p: 1, backgroundColor: "white" }}>
            <ArazGridItem sx={{ p: 0 }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Link component={RouterLink} underline="none" fontSize={16} color="navbar.main" to="/PasswordRecovery" sx={{ mr: 4 }}>
                  {t("PasswordRecovery.Hint")}
                </Link>
              </Box>
            </ArazGridItem>
          </ArazGrid>
        </Box>
      </Container>
    </form>
  );
}
