import { useState } from "react";
import ArazBox from "components/ArazComponents/ArazBox";
import ArazTabPanel, { ArazTabProps } from "components/ArazComponents/ArazTabPanel";
import ArazTabs from "components/ArazComponents/ArazTabs";
import { useTranslation } from "react-i18next";
import ArazTab from "components/ArazComponents/ArazTab";
import InStock from "./InStock";
import Adjustments from "./Adjustment/Adjustments";
import Transfers from "./Transfer/Transfers";
import { createSearchParams, useSearchParams } from "react-router-dom";

export default function Inventory() {
  const { t } = useTranslation();
  const tabs = {
    inStock: 0,
    stockAdjustment: 1,
    stockTransfer: 2,
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const queryTab = searchParams.get("tab");

  const [tabValue, setTabValue] = useState(queryTab ? tabs[queryTab] : tabs.inStock);

  return (
    <ArazBox>
      <ArazTabs
        variant="scrollable"
        value={tabValue}
        onChange={(event, newValue) => {
          setTabValue(newValue);
          var key = Object.keys(tabs).filter(function (key) {
            return tabs[key] === newValue;
          })[0];
          setSearchParams(createSearchParams({ tab: key }));
        }}
      >
        <ArazTab label={t("InStock")} {...ArazTabProps(tabs.inStock)} />
        <ArazTab label={t("StockAdjustment")} {...ArazTabProps(tabs.stockAdjustment)} style={{ left: "20px" }} />
        <ArazTab label={t("StockTransfer")} {...ArazTabProps(tabs.stockTransfer)} style={{ left: "40px" }} />
      </ArazTabs>

      <ArazTabPanel value={tabValue} index={tabs.inStock}>
        <InStock />
      </ArazTabPanel>
      <ArazTabPanel value={tabValue} index={tabs.stockAdjustment}>
        <Adjustments />
      </ArazTabPanel>
      <ArazTabPanel value={tabValue} index={tabs.stockTransfer}>
        <Transfers />
      </ArazTabPanel>
    </ArazBox>
  );
}
