import ArazBox from "components/ArazComponents/ArazBox";
import { useTranslation } from "react-i18next";
import { Chip, Rating, Step, StepLabel, Stepper, TextField, Tooltip, Typography } from "@mui/material";
import ArazContainer from "components/ArazComponents/ArazContainer";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import ArazSaveButton from "components/ArazComponents/ArazSaveButton";
import ArazDatePicker from "components/ArazComponents/ArazDatePicker";
import { useState } from "react";
import ArazPersonAddressAutoComplete from "components/ArazComponents/ArazPersonAddressAutoComplete";
import ArazWarehouseAutoComplete from "components/ArazComponents/ArazWarehouseAutoComplete";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import PagesIcon from "@mui/icons-material/Pages";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate, useParams } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useConfirm } from "material-ui-confirm";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import SaleOrderItem from "./SaleOrderItem";
import { useFormik } from "formik";
import * as yup from "yup";
import { Confirm, GetNextCode } from "Services/SalesOrders/SaleOrderService";
import { useEffect } from "react";
import { Insert, GetDetail, Update, Delete } from "Services/Base/ApiBaseService";
import { AvailabilityStatuses, EntityTypes, SaleOrderStatuses } from "Services/Base/constants";
import ArazButton from "components/ArazComponents/ArazButton";
import SaleOrderItemPackList from "./SaleOrderItemPackList";
import { LocalDateFormat } from "i18n/LocalDate";
import SaleOrderItemPackPartially from "./SaleOrderItemPackPartially";
import SaleOrderItemPackSendPartially from "./SaleOrderItemPackSendPartially";
import SaleOrderItemPackSend from "./SaleOrderItemPackSend";
import SaleOrderItemPack from "./SaleOrderItemPack";
import WarehouseBomLedger from "components/Ledger/WarehouseBomLedger";
import CircleIcon from "@mui/icons-material/Circle";
import CheckIcon from "@mui/icons-material/Check";
import ArazContainerHeader from "components/ArazComponents/ArazContainerHeader";

export default function SaleOrder() {
  const {
    state: {},
    dispatch,
  } = useValue();
  const { t } = useTranslation();
  let { id } = useParams();

  const [openSaleOrderItem, setOpenSaleOrderItem] = useState(false);
  const [saleOrderItemId, setSaleOrderItemId] = useState(0);
  const [remainQuantity, setRemainQuantity] = useState(0);
  const [openSaleOrderItemPackList, setOpenSaleOrderItemPackList] = useState(false);
  const confirm = useConfirm();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    code: yup.string().required().label(t("Manufactures.SaleOrder.Field.Code")),
    deadline: yup.date().required().label(t("Manufactures.SaleOrder.Field.Deadline")),
    personAddressId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.SaleOrder.Field.PersonAddressId")),
    componentsLocationId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.SaleOrder.Field.ComponentsLocationId")),
    saleOrderItems: yup.array().nullable().min(1).required().label(" "),
    priority: yup.number().required().positive().label(t("Manufactures.SaleOrder.Field.Priority")),
  });

  const formik = useFormik({
    initialValues: {
      id: 0,
      code: "",
      deadline: new Date(),
      personAddressId: null,
      componentsLocationId: null,
      priority: 3,
      canConfirm: false,
      canPack: false,
      canSend: false,
      saleOrderItems: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Save(values);
    },
  });

  const columns = [
    { field: "id", type: "number", headerName: "id", hide: true },
    { field: "bomId", type: "number", headerName: "bomId", hide: true },
    {
      field: "productName",
      headerName: t("Manufactures.SaleOrderItem.Field.ProductName"),
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "quantity",
      headerName: t("Manufactures.SaleOrderItem.Field.Quantity"),
      flex: 1,
      minWidth: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "quantityPacked",
      headerName: t("Manufactures.SaleOrderItem.Field.QuantityPacked"),
      flex: 1,
      minWidth: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      hide: id == "New",
      renderCell: (params) => {
        if (params.row.id < 0) return t("Common.PleaseSaveBefore");
        if ([SaleOrderStatuses.Draft].indexOf(formik.values.status) > -1) return `${params.value}`;
        return (
          <ArazButton
            onClick={(event) => {
              event.stopPropagation();
              setItemPacks(params.row.saleOrderItemPacks);
              setSaleOrderItemId(params.id);
              setRemainQuantity(params.row.quantity - params.row.quantityPacked);
              setOpenSaleOrderItemPackList(true);
            }}
          >
            {params.value}
          </ArazButton>
        );
      },
    },
    {
      field: "quantitySent",
      headerName: t("Manufactures.SaleOrderItem.Field.QuantitySent"),
      flex: 1,
      minWidth: 120,
      type: "number",
      align: "center",
      headerAlign: "center",
      hide: id == "New",
    },
    {
      field: "saleItemAvailabilityStatusString",
      headerName: t("Manufactures.SaleOrderItem.Field.SaleItemAvailabilityStatus"),
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      hide: id == "New",
      renderCell: (params) => {
        if (params.id < 0) return "";
        if ([SaleOrderStatuses.Draft].indexOf(formik.values.status) == -1) {
          const value = params.row.saleItemAvailabilityStatus == AvailabilityStatuses.Expected ? params.value + " " + LocalDateFormat(params.row.expectedDate) : params.value;
          return params.row.saleItemAvailabilityStatus == AvailabilityStatuses.NotAvailable ? (
            <Chip
              label={value}
              color="error"
              onClick={(event) => {
                event.stopPropagation();
                OpenLedger(params.row.bomId);
              }}
            />
          ) : params.row.saleItemAvailabilityStatus == AvailabilityStatuses.InStock ? (
            <Chip
              label={value}
              color="success"
              onClick={(event) => {
                event.stopPropagation();
                OpenLedger(params.row.bomId);
              }}
            />
          ) : (
            <Chip
              label={value}
              color="warning"
              onClick={(event) => {
                event.stopPropagation();
                OpenLedger(params.row.bomId);
              }}
            />
          );
        } else {
          return <Typography variant="caption">{t("DotNotShowAvailabilityStatus", { status: formik.values.statusString })}</Typography>;
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      minWidth: 100,
      getActions: (params) => {
        const actions = [];

        if (id > 0 && params.id > 0 && [SaleOrderStatuses.Draft, SaleOrderStatuses.Sent].indexOf(params.row.status) == -1) {
          if (
            params.row.quantity >
            params.row.saleOrderItemPacks
              ?.map((m) => m.quantity)
              .reduce((accumulator, value) => {
                return accumulator + value;
              }, 0)
          ) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title={t("Commons.Pack")}>
                    <PagesIcon color="warning" />
                  </Tooltip>
                }
                label={t("Commons.Pack")}
                onClick={() => {
                  openItemPackPartially({ saleOrderId: id, saleOrderItemId: params.id, id: 0, quantity: params.row.quantity - params.row.quantityPacked, packDate: new Date() });
                }}
              />
            );
          }
          if (
            [SaleOrderStatuses.Pack, SaleOrderStatuses.PartiallyPack, SaleOrderStatuses.PartiallySent].indexOf(params.row.status) > -1 &&
            params.row.saleOrderItemPacks?.some((m) => !m.saleOrderItemPackSend)
          ) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title={t("Commons.Send")}>
                    <SendIcon color="success" />
                  </Tooltip>
                }
                label={t("Commons.Send")}
                onClick={() => {
                  openItemPackSendPartially({
                    saleOrderId: id,
                    saleOrderItemId: params.id,
                    id: 0,
                    sendDate: new Date(),
                    saleOrderItemPackId: params.row.saleOrderItemPacks.filter((i) => !i.saleOrderItemPackSend).map((i) => i.id),
                  });
                }}
              />
            );
          }
        }
        actions.push(
          <GridActionsCellItem
            icon={
              <Tooltip title={t("Commons.Delete")}>
                <DeleteIcon />
              </Tooltip>
            }
            label={t("Commons.Delete")}
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(() => {
                  const newSate = formik.values.saleOrderItems.filter((v) => v.id != params.id);
                  formik.setFieldValue("saleOrderItems", newSate);
                })
                .catch(() => {});
            }}
          />
        );

        return actions;
      },
      align: "center",
      headerAlign: "center",
    },
  ];

  async function initial(v) {
    if (id === "New" && !v) {
      const nextCode = await GetNextCode({ navigate });
      return {
        id: 0,
        code: nextCode.data,
        deadline: new Date(),
        personAddressId: null,
        componentsLocationId: null,
        priority: 1,
        saleOrderItems: [],
      };
    } else {
      const detail = v ?? (await GetDetail({ navigate, dispatch, t }, EntityTypes.SaleOrder, id));
      return {
        id: id,
        code: detail.data.code,
        deadline: new Date(detail.data.deadline),
        personAddressId: { id: detail.data.personAddress.id, personName: detail.data.personAddress.personName, addressLine: detail.data.personAddress.addressLine },
        componentsLocationId: { id: detail.data.componentsLocation.id, name: detail.data.componentsLocation.name },
        priority: detail.data.priority,
        statusString: detail.data.statusString,
        status: detail.data.status,
        canConfirm: detail.data.canConfirm,
        canPack: detail.data.canPack,
        canSend: detail.data.canSend,
        saleOrderItems: detail.data.saleOrderItems,
      };
    }
  }
  useEffect(() => {
    (async () => {
      const result = await initial();
      formik.setValues(result);
    })();
  }, [id]);

  const [openLedger, setOpenLedger] = useState(false);
  const [ledgerBomId, setLedgerBomId] = useState(0);
  function OpenLedger(bomId) {
    setLedgerBomId(bomId);
    setOpenLedger(true);
  }

  const [itemPacks, setItemPacks] = useState();

  const [item, setItem] = useState({ id: 0, product: null, quantity: 1, bomId: 0 });
  async function openItem(v) {
    await setItem(v);
    setOpenSaleOrderItem(true);
  }

  function handleOnSaveItem(item) {
    const existingProductIdx = formik.values.saleOrderItems.findIndex((v) => v.productId == item.product.id && v.id != item.id);
    if (existingProductIdx !== -1) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Product.Duplicate"), severity: "error", open: true } });
      return;
    }

    const existingId = formik.values.saleOrderItems.findIndex((v) => v.id == item.id);
    if (existingId === -1) {
      // product doesn't exist in the array
      formik.setFieldValue("saleOrderItems", [
        ...formik.values.saleOrderItems,
        { id: item.id, productId: item.product.id, productName: item.product.name, quantity: item.quantity, bomId: item.bomId },
      ]);
    } else {
      // product exists
      const newSate = [...formik.values.saleOrderItems]; // creates a copy of the current array
      newSate[existingId] = { ...newSate[existingId], id: item.id, productId: item.product.id, productName: item.product.name, quantity: item.quantity, bomId: item.bomId }; // set the position of the array to payload (or whatever should be the new value)

      formik.setFieldValue("saleOrderItems", newSate);
    }
  }

  const [openSaleOrderItemPackPartially, setOpenSaleOrderItemPackPartially] = useState(false);
  const [itemPackPartially, setItemPackPartially] = useState(false);
  function openItemPackPartially(v) {
    setItemPackPartially(v);
    setOpenSaleOrderItemPackPartially(true);
  }

  const [openSaleOrderItemPack, setOpenSaleOrderItemPack] = useState(false);
  const [itemPack, setItemPack] = useState(false);
  function openItemPack(v) {
    setItemPack(v);
    setOpenSaleOrderItemPack(true);
  }

  const [openSaleOrderItemPackSendPartially, setOpenSaleOrderItemPackSendPartially] = useState(false);
  const [itemPackSendPartially, setItemPackSendPartially] = useState(false);
  function openItemPackSendPartially(v) {
    setItemPackSendPartially(v);
    setOpenSaleOrderItemPackSendPartially(true);
  }

  const [openSaleOrderItemPackSend, setOpenSaleOrderItemPackSend] = useState(false);
  const [itemPackSend, setItemPackSend] = useState(false);
  function openItemPackSend(v) {
    setItemPackSend(v);
    setOpenSaleOrderItemPackSend(true);
  }

  async function handleOnSaveItemPackPartially(v) {
    debugger;
    const result = await initial(v);
    formik.setValues(result);
    const newItem = v.data.saleOrderItems.filter((x) => x.id == saleOrderItemId);
    if (newItem) setItemPacks(newItem[0].saleOrderItemPacks);
  }

  async function Save(item) {
    formik.setStatus({ error: null });
    const data = {
      id: item.id,
      code: item.code,
      deadline: item.deadline,
      personAddressId: item.personAddressId.id,
      componentsLocationId: item.componentsLocationId.id,
      priority: item.priority,
      saleOrderItems: item.saleOrderItems,
    };
    const result = id == "New" ? await Insert({ navigate, dispatch, t }, EntityTypes.SaleOrder, data) : await Update({ navigate, dispatch, t }, EntityTypes.SaleOrder, data);
    if (result && result.isSuccess) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
      if (id == "New") {
        navigate("/Sales/SaleOrder/" + result.data.id);
        return;
      }

      const data = await initial(result);
      formik.setValues(data);
    } else formik.setStatus({ error: result.error });
  }

  return (
    <form>
      <ArazBox>
        {id > 0 && (
          <ArazContainer sx={{ p: 1 }}>
            <Stepper nonLinear activeStep={-1} sx={{ flexWrap: "wrap" }}>
              {[SaleOrderStatuses.Draft, SaleOrderStatuses.Confirm, SaleOrderStatuses.PartiallyPack, SaleOrderStatuses.Pack, SaleOrderStatuses.PartiallySent, SaleOrderStatuses.Sent].map(
                (label, index) => (
                  <Step key={label} completed={false}>
                    <StepLabel icon={<CircleIcon color={formik.values.status == label ? "navbar" : "deactive"} />}>
                      <Typography variant="subtitle2" color={formik.values.status == label ? "navbar.main" : "deactive.main"}>
                        {t(`SaleOrderStatuses.${Object.keys(SaleOrderStatuses).find((key) => SaleOrderStatuses[key] === label)}`)}
                      </Typography>
                    </StepLabel>
                  </Step>
                )
              )}
            </Stepper>
          </ArazContainer>
        )}

        <ArazContainer>
          <ArazGrid>
            <ArazGrid xs={12} justifyContent="space-between">
              <ArazGrid>
                <ArazGridItem>
                  <ArazSaveButton onClick={formik.handleSubmit} type="submit" />
                  <ArazButton
                    color="error"
                    variant="outlined"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      confirm({
                        confirmationButtonProps: { color: "error", variant: "contained" },
                        titleProps: { color: "error" },
                        title: t("Commons.Delete"),
                        description: t("Commons.Delete.confirm"),
                        confirmationText: t("Commons.Delete"),
                        cancellationText: t("Commons.Cancel"),
                      })
                        .then(async () => {
                          const result = await Delete({ navigate, dispatch, t }, EntityTypes.SaleOrder, id);
                          if (result.isSuccess) navigate("/Sales/SaleOrder");
                          else formik.setStatus({ error: result.error });
                        })
                        .catch(() => {});
                    }}
                  >
                    {t("Commons.Delete")}
                  </ArazButton>
                </ArazGridItem>
              </ArazGrid>

              <ArazGrid>
                <ArazGridItem>
                  {formik.values.canConfirm && (
                    <ArazButton
                      color="warning"
                      sx={{ ml: 1 }}
                      startIcon={<CheckIcon />}
                      onClick={async () => {
                        const result = await Confirm({ navigate, dispatch, t }, id);
                        if (result && result.isSuccess) handleOnSaveItemPackPartially(result);
                        else formik.setStatus({ error: result.error });
                      }}
                    >
                      {t("Commons.Confirm")}
                    </ArazButton>
                  )}
                  {formik.values.canPack && (
                    <ArazButton startIcon={<PagesIcon />} color="warning" sx={{ ml: 1, px: 4 }} onClick={() => openItemPack({ saleOrderId: id, packDate: new Date(), code: "" })}>
                      {t("Commons.Pack")}
                    </ArazButton>
                  )}
                  {formik.values.canSend && (
                    <ArazButton color="warning" startIcon={<SendIcon />} sx={{ ml: 1 }} onClick={() => openItemPackSend({ saleOrderId: id, sendDate: new Date() })}>
                      {t("Commons.Send")}
                    </ArazButton>
                  )}
                </ArazGridItem>
              </ArazGrid>
              {formik.status && formik.status.error && (
                <ArazGrid xs={12}>
                  <ArazGridItem>
                    {formik.status.error.map((e, i) => (
                      <Typography component="span" color="error" key={i}>
                        {e[Object.keys(formik.status.error[0])[0]]}
                      </Typography>
                    ))}
                  </ArazGridItem>
                </ArazGrid>
              )}
            </ArazGrid>
            <ArazGrid xs={12} md={4}>
              <ArazGridItem>
                <TextField
                  id="code"
                  name="code"
                  label={t("Manufactures.SaleOrder.Field.Code")}
                  variant="outlined"
                  fullWidth
                  size="medium"
                  value={formik.values.code}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("code", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={4}>
              <ArazGridItem sx={{ textAlign: "center" }}>
                <ArazDatePicker
                  id="deadline"
                  name="deadline"
                  value={formik.values.deadline}
                  setValue={(v) => formik.setFieldValue("deadline", v)}
                  label={t("Manufactures.SaleOrder.Field.Deadline")}
                  error={formik.touched.deadline && Boolean(formik.errors.deadline)}
                  helperText={formik.touched.deadline && formik.errors.deadline}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={4}>
              <ArazGridItem>
                <Rating
                  name="priority"
                  precision={0.5}
                  defaultValue={formik.values.priority}
                  value={formik.values.priority}
                  onChange={(event) => {
                    formik.setFieldValue("priority", parseFloat(event.target.value), true);
                  }}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <ArazGridItem>
                <ArazPersonAddressAutoComplete
                  id="personAddressId"
                  name="personAddressId"
                  value={formik.values.personAddressId}
                  defaultValue={formik.values.personAddressId}
                  setValue={(v) => formik.setFieldValue("personAddressId", v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.personAddressId && Boolean(formik.errors.personAddressId)}
                  helperText={formik.touched.personAddressId && formik.errors.personAddressId}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <ArazGridItem>
                <ArazWarehouseAutoComplete
                  id="componentsLocationId"
                  name="componentsLocationId"
                  label={t("Manufactures.SaleOrder.Field.ComponentsLocationId")}
                  value={formik.values.componentsLocationId}
                  defaultValue={formik.values.componentsLocationId}
                  setValue={(v) => formik.setFieldValue("componentsLocationId", v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.componentsLocationId && Boolean(formik.errors.componentsLocationId)}
                  helperText={formik.touched.componentsLocationId && formik.errors.componentsLocationId}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </ArazContainer>

        <ArazContainer>
          <ArazContainerHeader>{t("Manufactures.SaleOrder.Field.SaleOrderItems")}</ArazContainerHeader>
          <ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazAddButton
                  label={t("Commons.Insert")}
                  left
                  onClick={async () => await openItem({ id: Math.floor(Math.random() * -100000000 + 1), product: null, quantity: 1, bomId: 0 })}
                  sx={{ mt: 1 }}
                />
              </ArazGridItem>
              {formik.touched.saleOrderItems && Boolean(formik.errors.saleOrderItems) && (
                <ArazGridItem>
                  <Typography component="span" color="error">
                    {formik.touched.saleOrderItems && formik.errors.saleOrderItems}
                  </Typography>
                </ArazGridItem>
              )}
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazDataGrid
                  manual
                  rows={formik.values.saleOrderItems}
                  columns={columns}
                  sortingMode="client"
                  paginationMode="client"
                  rowCount={formik.values.saleOrderItems.length}
                  onRowClick={async (param) => {
                    await openItem({
                      id: param.id,
                      product: { id: param.row.productId, name: param.row.productName, boms: [{ id: param.row.bomId }] },
                      quantity: param.row.quantity,
                      bomId: param.row.bomId,
                    });
                  }}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </ArazContainer>
      </ArazBox>
      {openSaleOrderItem && <SaleOrderItem open={openSaleOrderItem} setOpen={setOpenSaleOrderItem} value={item} onSave={handleOnSaveItem} />}
      {openSaleOrderItemPackPartially && (
        <SaleOrderItemPackPartially open={openSaleOrderItemPackPartially} setOpen={setOpenSaleOrderItemPackPartially} value={itemPackPartially} onSave={handleOnSaveItemPackPartially} />
      )}
      {openSaleOrderItemPack && <SaleOrderItemPack open={openSaleOrderItemPack} setOpen={setOpenSaleOrderItemPack} value={itemPack} onSave={handleOnSaveItemPackPartially} />}
      {openSaleOrderItemPackSendPartially && (
        <SaleOrderItemPackSendPartially
          open={openSaleOrderItemPackSendPartially}
          setOpen={setOpenSaleOrderItemPackSendPartially}
          value={itemPackSendPartially}
          onSave={handleOnSaveItemPackPartially}
        />
      )}
      {openSaleOrderItemPackSend && <SaleOrderItemPackSend open={openSaleOrderItemPackSend} setOpen={setOpenSaleOrderItemPackSend} value={itemPackSend} onSave={handleOnSaveItemPackPartially} />}
      {openSaleOrderItemPackList && (
        <SaleOrderItemPackList
          open={openSaleOrderItemPackList}
          setOpen={setOpenSaleOrderItemPackList}
          saleOrderId={id}
          saleOrderItemId={saleOrderItemId}
          remainQuantity={remainQuantity}
          values={itemPacks}
          onSave={handleOnSaveItemPackPartially}
        />
      )}
      {openLedger && <WarehouseBomLedger open={openLedger} setOpen={setOpenLedger} warehouseId={formik.values.componentsLocationId.id} bomId={ledgerBomId} />}
    </form>
  );
}
