import ArazTab from "./ArazTab";
import CircleIcon from "@mui/icons-material/Circle";

const ArazActiveTab = ({ children, ...props }) => {
  return (
    <ArazTab
      {...props}
      icon={<CircleIcon fontSize="small" color="success" sx={{ mr: 2 }} />}
      iconPosition="start"
    >
      {children}
    </ArazTab>
  );
};

export default ArazActiveTab;
