import { Button, Container } from "@mui/material";
import { useTranslation } from "react-i18next";

const ArazButton = ({ children, sx, ...props }) => {
  return (
    <Button variant="contained" sx={{ px: 6, borderRadius: 2, ...sx }} {...props}>
      {children}
    </Button>
  );
};

export default ArazButton;
