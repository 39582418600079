import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
// import CustomizedTabs from "./components/CustomizedTabs";
import Loading from "./components/Loading";
import NavBar from "./components/menu/NavBar";
import Notification from "./components/Notification";
import Tour from "./Tour/Tour";
import { useEffect, useMemo, useState } from "react";
import { CssBaseline } from "@mui/material";
import { useValue } from "./context/ContextProvider";
import { faIR, enUS } from "@mui/x-data-grid";
import { faIR as coreFaIr, enUS as coreEnUs } from "@mui/material/locale";
import { createTheme, ThemeProvider, useTheme, experimental_sx as sx } from "@mui/material/styles";
import { faIR as pickerFaIr, enUS as pickerEnUs } from "@mui/x-date-pickers";
import { ConfirmProvider } from "material-ui-confirm";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import fa from "yup-locale-fa";
import { setLocale } from "yup";
import SaleOrderList from "Pages/Sales/SaleOrderList";
import SaleOrder from "Pages/Sales/SaleOrder";
import ManufactureOrder from "Pages/Manufactures/ManufactureOrder";
import Purchases from "Pages/Purchase/Purchases";
import PurchaseOrder from "Pages/Purchase/PurchaseOrder";
import Inventory from "Pages/Inventories/Inventory";
import Adjustment from "Pages/Inventories/Adjustment/Adjustment";
import Transfer from "Pages/Inventories/Transfer/Transfer";
import Products from "Pages/Product/Products";
import Product from "Pages/Product/Product";
import Persons from "Pages/Commons/Persons";
import Settings from "Pages/Commons/Settings";
import Invoices from "Pages/Payments/Invoices";
import Verify from "Pages/Payments/Verify";
import Login from "Pages/login/Login";
import Register from "Pages/login/Register";
import PasswordRecovery from "Pages/login/PasswordRecovery";
import Home from "Home";
import { grey } from "@mui/material/colors";
import ManufactureOrderList from "Pages/Manufactures/ManufactureOrderList";
import ManufacturingWorkOrderList from "Pages/Manufactures/ManufacturingWorkOrderList";
import Base from "Knowledge/Base";
import { default as FaIndex } from "Knowledge/Fa/Index";
import { default as FaSales } from "Knowledge/Fa/Sales";

function App() {
  const {
    state: { dark, language },
    dispatch,
  } = useValue();

  const currentLanguage = localStorage.getItem("i18nextLng") || "en";
  if (currentLanguage != language) {
    dispatch({ type: "CHANGE_LANGUAGE", payload: currentLanguage });
  }

  if (currentLanguage == "fa") setLocale(fa);

  useEffect(() => {
    currentLanguage == "fa" ? (document.body.dir = "rtl") : (document.body.dir = "ltr");
  }, [currentLanguage]);

  // Create rtl cache
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  // Create ltr cache
  const cacheLtr = createCache({
    key: "muiltr",
    stylisPlugins: [prefixer],
  });

  const customeTheme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode: dark ? "dark" : "light",
            background: {
              default: !dark ? "#ebebeb" : "#212121",
            },
            navbar: {
              main: !dark ? "#5f27cd" : "#7f4de3", //2  #ff0000
              light: !dark ? "#8F8F8F" : "#8F8F8F", //3
              contrastText: !dark ? "#FFFFFF" : "#8F8F8F",
            },
            common: {
              main: !dark ? "#FFFFFF" : "#000000",
            },
            success: {
              main: !dark ? "#79D701" : "#1ac700", //6
              contrastText: !dark ? "#FFFFFF" : "#000000",
            },
            deactive: {
              main: !dark ? grey[400] : "#8F8F8F",
            },
            tab: {
              main: !dark ? "#fffcfc00" : "#fffcfc00", //9
              bgcolor: !dark ? "#F9F9F9" : "#8F8F8F",
            },
            highlight: {
              main: !dark ? "#8F8F8F" : "#1a1a1a",
            },
            common: {
              main: !dark ? "#FFFFFF" : "#272727",
              white: !dark ? "#FFFFFF" : "#272727",
              black: !dark ? "#000000" : "#1a1a1a",
            },
          },
          typography: {
            fontFamily: "Vazirmatn , Roboto , Helvetica , Arial , sans-serif",
          },
          direction: currentLanguage == "fa" ? "rtl" : "ltr",
          components: {
            MuiDialogContent: {
              styleOverrides: {
                root: {
                  padding: "20px",
                },
              },
            },
            MuiDataGrid: {
              styleOverrides: {
                cellContent: {
                  whiteSpace: "break-spaces",
                },
                cell: {
                  whiteSpace: "break-spaces",
                },
              },
            },
            MuiStepper: {
              styleOverrides: {
                root: {
                  padding: "0px",
                },
              },
            },
          },
          breakpoints: {
            values: {
              xs: 0,
              sm: 600,
              md: 1160,
              lg: 1500,
              xl: 1536,
            },
          },
        },
        currentLanguage == "fa" ? faIR : enUS, // x-data-grid translations
        currentLanguage == "fa" ? coreFaIr : coreEnUs, // core translations
        currentLanguage == "fa" ? pickerFaIr : pickerEnUs // x-date-pickers translations
      ),
    [dark, currentLanguage]
  );

  return (
    <CacheProvider value={currentLanguage == "fa" ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={customeTheme}>
        <ConfirmProvider
          defaultOptions={{
            confirmationButtonProps: { autoFocus: true },
          }}
        >
          <CssBaseline />

          <BrowserRouter>
            <Loading />
            <Notification />
            {/*
            todo help
            <Tour />
             */}
            <NavBar />
            <Routes>
              <Route path="/Manufactures/ManufacturingWorkOrder" element={<ManufacturingWorkOrderList />} />
              <Route path="/Manufactures/ManufacturingOrder" element={<ManufactureOrderList />} />
              <Route path="/Manufactures/ManufacturingOrder/:id" element={<ManufactureOrder />} />

              <Route path="/Sales/SaleOrder" element={<SaleOrderList />} />
              <Route path="/Sales/SaleOrder/:id" element={<SaleOrder />} />

              <Route path="/Purchases/PurchaseOrder" element={<Purchases />} />
              <Route path="/Purchases/PurchaseOrder/:id" element={<PurchaseOrder />} />

              <Route path="/Inventories/Inventory" element={<Inventory />} />

              <Route path="/Inventories/StockAdjustment/:id" element={<Adjustment />} />

              <Route path="/Inventories/StockTransfer/:id" element={<Transfer />} />

              <Route path="/Manufactures/Product" element={<Products />} />
              <Route path="/Manufactures/Product/:id" element={<Product />} />

              <Route path="/Commons/Person" element={<Persons />} />

              <Route path="/Commons/Settings" element={<Settings />} />

              <Route path="/Payments/Invoice" element={<Invoices />} />
              <Route path="/Payments/Verify/:id" element={<Verify />} />

              <Route path="/Login" element={<Login />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/Register/:username/:token" element={<Register />} />
              <Route path="/Register" element={<Register />} />
              <Route path="Fa/Register" element={<Register />} />
              <Route path="En/Register" element={<Register />} />
              <Route path="/PasswordRecovery/:username/:token" element={<PasswordRecovery />} />
              <Route path="/PasswordRecovery" element={<PasswordRecovery />} />

              <Route path="/" element={<Home />} />

              {/* //Knowledge */}

              <Route path="fa/Knowledge" element={<Base />}>
                <Route path="" element={<FaIndex />} />
                <Route path="Sales" element={<FaSales />} />
              </Route>

              {/* cache all */}
              <Route path="*" element={<Login />} />

              {/* <Route path="/tab" element={<CustomizedTabs />} /> */}
            </Routes>
          </BrowserRouter>
        </ConfirmProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
