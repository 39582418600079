import { useTranslation } from "react-i18next";
import { Tooltip, Typography } from "@mui/material";
import ArazContainer from "components/ArazComponents/ArazContainer";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import ArazSaveButton from "components/ArazComponents/ArazSaveButton";
import { useState } from "react";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useConfirm } from "material-ui-confirm";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import { GetDetail, Update } from "Services/Base/ApiBaseService";
import { BomTypes, EntityTypes } from "Services/Base/constants";
import ComponentItem from "./ComponentItem";
import ProcessesItem from "./ProcesesItem";
import ArazContainerHeader from "components/ArazComponents/ArazContainerHeader";

export default function Bom({ productId }) {
  const { t } = useTranslation();

  const confirm = useConfirm();
  const navigate = useNavigate();

  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      id: productId,
      productId: productId,
      BomType: BomTypes.Manufacture,
      components: [],
      bomProcesses: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Save(values);
    },
  });

  const orderComponentsColumns = [
    { field: "id", type: "number", headerName: "id", hide: true },
    { field: "componentId", type: "number", headerName: "componentId", hide: true },
    { field: "productId", type: "number", headerName: "productId", hide: true },
    {
      field: "productName",
      headerName: t("Manufactures.ManufacturingOrderComponent.Field.ProductName"),
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "quantity",
      headerName: t("Manufactures.ManufacturingOrderComponent.Field.Quantity"),
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      getActions: (params) => {
        const actions = [];

        actions.push(
          <GridActionsCellItem
            icon={
              <Tooltip title={t("Commons.Delete")}>
                <DeleteIcon />
              </Tooltip>
            }
            label={t("Commons.Delete")}
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(() => {
                  const newSate = formik.values.components.filter((v) => v.id != params.id);
                  formik.setFieldValue("components", newSate);
                })
                .catch(() => {});
            }}
          />
        );

        return actions;
      },
      align: "center",
      headerAlign: "center",
    },
  ];

  const processColumns = [
    { field: "id", type: "number", headerName: "id", hide: true },
    {
      field: "processName",
      headerName: t("Manufactures.BomProcess.Field.ProcessName"),
      flex: 1,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workCenterName",
      headerName: t("Manufactures.BomProcess.Field.WorkCenterName"),
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "expectedDuration",
      headerName: t("Manufactures.BomProcess.Field.Duration"),
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "sequence",
      headerName: t("Manufactures.BomProcess.Field.Sequence"),
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      getActions: (params) => {
        const actions = [];

        actions.push(
          <GridActionsCellItem
            icon={
              <Tooltip title={t("Commons.Delete")}>
                <DeleteIcon />
              </Tooltip>
            }
            label={t("Commons.Delete")}
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(() => {
                  const newSate = formik.values.bomProcesses.filter((v) => v.id != params.id);
                  formik.setFieldValue("bomProcesses", newSate);
                })
                .catch(() => {});
            }}
          />
        );

        return actions;
      },
      align: "center",
      headerAlign: "center",
    },
  ];

  async function initial(v) {
    const detail = v ?? (await GetDetail({ navigate, dispatch, t }, EntityTypes.BOM, productId));
    return {
      id: productId,
      productId: productId,
      BomType: BomTypes.Manufacture,
      components: detail.data.components,
      bomProcesses: detail.data.bomProcesses,
    };
  }
  useEffect(() => {
    (async () => {
      const result = await initial();
      formik.setValues(result);
    })();
  }, []);

  const [componentsItem, setComponentsItem] = useState({ id: 0, product: null, quantity: 1, bomId: 0 });
  const [openComponentsItem, setOpenComponentsItem] = useState(false);
  async function OpenComponentsItem(v) {
    await setComponentsItem(v);
    setOpenComponentsItem(true);
  }
  function handleOnSaveComponentsItem(item) {
    const existingProductIdx = formik.values.components.findIndex((v) => v.productId == item.product.id && v.id != item.id);
    if (existingProductIdx !== -1) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: "محصول انتخاب شده تکراری است", severity: "error", open: true } });
      return;
    }
    const existingId = formik.values.components.findIndex((v) => v.id == item.id);
    if (existingId === -1) {
      // product doesn't exist in the array
      formik.setFieldValue("components", [
        ...formik.values.components,
        { id: item.id, productId: item.product.id, productName: item.product.name, quantity: item.quantity, componentId: item.componentId },
      ]);
    } else {
      // product exists
      const newSate = [...formik.values.components]; // creates a copy of the current array
      newSate[existingId] = { id: item.id, productId: item.product.id, productName: item.product.name, quantity: item.quantity, componentId: item.componentId }; // set the position of the array to payload (or whatever should be the new value)
      formik.setFieldValue("components", newSate);
    }
  }

  const [processItem, setProcessItem] = useState({ id: 0, process: null, sequence: 1 });
  const [openProcessItem, setOpenProcessItem] = useState(false);
  async function OpenProcessItem(v) {
    await setProcessItem(v);
    setOpenProcessItem(true);
  }
  async function handleOnSaveProcessItem(item) {
    const existingId = formik.values.bomProcesses.findIndex((v) => v.id == item.id);
    if (existingId === -1) {
      // product doesn't exist in the array
      await formik.setFieldValue("bomProcesses", [
        ...formik.values.bomProcesses,
        {
          id: item.id,
          processId: item.process.id,
          processName: item.process.name,
          sequence: item.sequence,
          workCenterId: item.workCenter.id,
          workCenterName: item.workCenter.Name,
          expectedDuration: item.expectedDuration,
        },
      ]);
    } else {
      // product exists
      const newSate = [...formik.values.bomProcesses]; // creates a copy of the current array
      newSate[existingId] = {
        ...newSate[existingId],
        id: item.id,
        processId: item.process.id,
        processName: item.process.name,
        sequence: item.sequence,
        workCenterId: item.workCenter.id,
        workCenterName: item.workCenter.Name,
        expectedDuration: item.expectedDuration,
      }; // set the position of the array to payload (or whatever should be the new value)
      await formik.setFieldValue("bomProcesses", newSate);
    }
  }

  async function Save(item) {
    formik.setStatus({ error: null });

    const process = item.bomProcesses.map((proc) => {
      return {
        ...proc,
        expectedDuration: proc.expectedDuration.length < 6 ? "00:" + proc.expectedDuration : proc.expectedDuration,
      };
    });

    const data = {
      id: productId,
      productId: productId,
      BomType: BomTypes.Manufacture,
      components: item.components,
      bomProcesses: process,
    };
    const result = await Update({ navigate, dispatch, t }, EntityTypes.BOM, data);
    if (result && result.isSuccess) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
      const data = await initial(result);
      formik.setValues(data);
    } else formik.setStatus({ error: result.error });
  }

  return (
    <form>
      <ArazContainer>
        <ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazSaveButton onClick={formik.handleSubmit} type="submit" />
            </ArazGridItem>
            {formik.status && formik.status.error && (
                <ArazGrid xs={12}>
                  <ArazGridItem>
                    {formik.status.error.map((e, i) => (
                      <Typography component="span" color="error" key={i}>
                        {e[Object.keys(formik.status.error[0])[0]]}
                      </Typography>
                    ))}
                  </ArazGridItem>
                </ArazGrid>
              )}
          </ArazGrid>
        </ArazGrid>
      </ArazContainer>
      <ArazContainer>
        <ArazContainerHeader>{t("Manufactures.Bom.Field.Components")}</ArazContainerHeader>
        <ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazAddButton
                label={t("Commons.Insert")}
                left
                onClick={async () => await OpenComponentsItem({ id: Math.floor(Math.random() * -100000000 + 1), product: null, quantity: 1, componentId: 0 })}
                sx={{ mt: 1 }}
              />
            </ArazGridItem>
            {formik.touched.components && Boolean(formik.errors.components) && (
              <ArazGridItem>
                <Typography component="span" color="error">
                  {formik.touched.components && formik.errors.components}
                </Typography>
              </ArazGridItem>
            )}
          </ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazDataGrid
                manual
                rows={formik.values.components}
                columns={orderComponentsColumns}
                sortingMode="client"
                paginationMode="client"
                rowCount={formik.values.components.length}
                onRowClick={async (param) => {
                  await OpenComponentsItem({
                    id: param.id,
                    product: { id: param.row.productId, name: param.row.productName, boms: [{ id: param.row.componentId }] },
                    quantity: param.row.quantity,
                    componentId: param.row.componentId,
                  });
                }}
              />
            </ArazGridItem>
          </ArazGrid>
        </ArazGrid>
      </ArazContainer>
      <ArazContainer>
        <ArazContainerHeader>{t("Manufactures.Bom.Field.BomProcess")}</ArazContainerHeader>
        <ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazAddButton
                label={t("Commons.Insert")}
                left
                onClick={async () => await OpenProcessItem({ id: Math.floor(Math.random() * -100000000 + 1), process: null, workCenter: null, sequence: 1, expectedDuration: "" })}
                sx={{ mt: 1 }}
              />
            </ArazGridItem>
            {formik.touched.bomProcesses && Boolean(formik.errors.bomProcesses) && (
              <ArazGridItem>
                <Typography component="span" color="error">
                  {formik.touched.bomProcesses && formik.errors.bomProcesses}
                </Typography>
              </ArazGridItem>
            )}
          </ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazDataGrid
                manual
                rows={formik.values.bomProcesses}
                columns={processColumns}
                sortingMode="client"
                paginationMode="client"
                rowCount={formik.values.bomProcesses.length}
                onRowClick={async (param) => {
                  await OpenProcessItem({
                    id: param.id,
                    process: { id: param.row.processId, name: param.row.processName },
                    workCenter: { id: param.row.workCenterId, name: param.row.workCenterName },
                    sequence: param.row.sequence,
                    expectedDuration: param.row.expectedDuration,
                  });
                }}
              />
            </ArazGridItem>
          </ArazGrid>
        </ArazGrid>
      </ArazContainer>
      {openComponentsItem && <ComponentItem open={openComponentsItem} setOpen={setOpenComponentsItem} value={componentsItem} onSave={handleOnSaveComponentsItem} />}
      {openProcessItem && <ProcessesItem open={openProcessItem} setOpen={setOpenProcessItem} value={processItem} onSave={handleOnSaveProcessItem} />}
    </form>
  );
}
