import ArazBox from "components/ArazComponents/ArazBox";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Step, StepLabel, Stepper, TextField, Tooltip, Typography } from "@mui/material";
import ArazContainer from "components/ArazComponents/ArazContainer";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import ArazSaveButton from "components/ArazComponents/ArazSaveButton";
import ArazDatePicker from "components/ArazComponents/ArazDatePicker";
import { useMemo, useState } from "react";
import ArazWarehouseAutoComplete from "components/ArazComponents/ArazWarehouseAutoComplete";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useConfirm } from "material-ui-confirm";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import { Insert, GetDetail, Update, Delete } from "Services/Base/ApiBaseService";
import { EntityTypes, StockAdjustmentStatuses } from "Services/Base/constants";
import ArazButton from "components/ArazComponents/ArazButton";
import { Confirm, GetNextCode } from "Services/Inventories/AdjustmentService";
import AdjustmentItem from "./AdjustmentItem";
import CircleIcon from "@mui/icons-material/Circle";
import CheckIcon from "@mui/icons-material/Check";
import ArazContainerHeader from "components/ArazComponents/ArazContainerHeader";

export default function Adjustment() {
  const { t } = useTranslation();
  let { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({
    code: yup.string().required().label(t("Inventories.StockAdjustment.Field.Code")),
    adjustmentDate: yup.date().required().label(t("Inventories.StockAdjustment.Field.AdjustmentDate")),

    warehouseId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Inventories.StockAdjustment.Field.WarehouseId")),

    stockAdjustmentItems: yup.array().nullable().min(1).required().label(" "),
  });

  const formik = useFormik({
    initialValues: {
      id: 0,
      code: "",
      adjustmentDate: new Date(),
      warehouseId: null,
      reason: "",
      stockAdjustmentItems: [],
      canConfirm: false,
      status: "",
      statusString: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Save(values);
    },
  });

  async function SetFormValue(v) {
    const result = await initial(v);
    formik.setValues(result);
  }

  const columns = [
    { field: "id", type: "number", headerName: "id", hide: true },
    { field: "bomId", type: "number", headerName: "bomId", hide: true },
    {
      field: "productName",
      headerName: t("Manufactures.ManufacturingOrderComponent.Field.ProductName"),
      flex: 1,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "quantity",
      headerName: t("Manufactures.ManufacturingOrderComponent.Field.Quantity"),
      flex: 1,
      minWidth: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      minWidth: 100,
      getActions: (params) => {
        const actions = [];

        actions.push(
          <GridActionsCellItem
            icon={
              <Tooltip title={t("Commons.Delete")}>
                <DeleteIcon />
              </Tooltip>
            }
            label={t("Commons.Delete")}
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(() => {
                  const newSate = formik.values.stockAdjustmentItems.filter((v) => v.id != params.id);
                  formik.setFieldValue("stockAdjustmentItems", newSate);
                })
                .catch(() => {});
            }}
          />
        );

        return actions;
      },
      align: "center",
      headerAlign: "center",
    },
  ];

  async function initial(v) {
    if (id === "New" && !v) {
      const nextCode = await GetNextCode({ navigate, dispatch, t });
      return {
        id: 0,
        code: nextCode.data,
        adjustmentDate: new Date(),
        warehouseId: null,
        reason: "",
        stockAdjustmentItems: [],
        canConfirm: false,
      };
    } else {
      const detail = v ?? (await GetDetail({ navigate, dispatch, t }, EntityTypes.StockAdjustment, id));
      return {
        id: id,
        code: detail.data.code,
        adjustmentDate: new Date(detail.data.adjustmentDate),
        warehouseId: { id: detail.data.warehouse.id, name: detail.data.warehouse.name },
        reason: detail.data.reason,
        statusString: detail.data.statusString,
        status: detail.data.status,
        stockAdjustmentItems: detail.data.stockAdjustmentItems,
        canConfirm: detail.data.canConfirm,
      };
    }
  }
  useEffect(() => {
    (async () => {
      const result = await initial();
      formik.setValues(result);
    })();
  }, [id]);

  const [item, setItem] = useState({ id: 0, product: null, quantity: 1, bomId: 0 });
  const [openItem, setOpenItem] = useState(false);
  async function OpenItem(v) {
    await setItem(v);
    setOpenItem(true);
  }
  function handleOnSaveItem(item) {
    const existingProductIdx = formik.values.stockAdjustmentItems.findIndex((v) => v.productId == item.product.id && v.id != item.id);
    if (existingProductIdx !== -1) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: "محصول انتخاب شده تکراری است", severity: "error", open: true } });
      return;
    }
    const existingId = formik.values.stockAdjustmentItems.findIndex((v) => v.id == item.id);
    if (existingId === -1) {
      // product doesn't exist in the array
      formik.setFieldValue("stockAdjustmentItems", [
        ...formik.values.stockAdjustmentItems,
        { id: item.id, productId: item.product.id, productName: item.product.name, quantity: item.quantity, bomId: item.bomId },
      ]);
    } else {
      // product exists
      const newSate = [...formik.values.stockAdjustmentItems]; // creates a copy of the current array
      newSate[existingId] = { id: item.id, productId: item.product.id, productName: item.product.name, quantity: item.quantity, bomId: item.bomId }; // set the position of the array to payload (or whatever should be the new value)
      formik.setFieldValue("stockAdjustmentItems", newSate);
    }
  }

  async function Save(item) {
    formik.setStatus({ error: null });
    debugger;
    const data = {
      id: item.id,
      code: item.code,
      adjustmentDate: item.adjustmentDate,
      warehouseId: item.warehouseId.id,
      reason: item.reason,
      stockAdjustmentItems: item.stockAdjustmentItems,
    };
    const result = id == "New" ? await Insert({ navigate, dispatch, t }, EntityTypes.StockAdjustment, data) : await Update({ navigate, dispatch, t }, EntityTypes.StockAdjustment, data);
    if (result && result.isSuccess) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
      formik.setFieldValue("canConfirm", result.data.canConfirm);

      if (id == "New") navigate("/Inventories/StockAdjustment/" + result.data.id);
    } else formik.setStatus({ error: result.error });
  }

  return (
    <form>
      <ArazBox>
        {id > 0 && (
          <ArazContainer sx={{ p: 1 }}>
            <Stepper nonLinear activeStep={-1} sx={{ flexWrap: "wrap" }}>
              {[StockAdjustmentStatuses.Draft, StockAdjustmentStatuses.Confirm].map((label, index) => (
                <Step key={label} completed={false}>
                  <StepLabel icon={<CircleIcon color={formik.values.status == label ? "navbar" : "deactive"} />}>
                    <Typography variant="subtitle2" color={formik.values.status == label ? "navbar.main" : "deactive.main"}>
                      {t(`StockAdjustmentStatuses.${Object.keys(StockAdjustmentStatuses).find((key) => StockAdjustmentStatuses[key] === label)}`)}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </ArazContainer>
        )}

        <Breadcrumbs aria-label="breadcrumb" sx={{ m: 2 }}>
          <Link underline="hover" color="inherit" to="/Inventories/Inventory">
            {t("WarehousesRole")}
          </Link>
          <Link underline="hover" color="inherit" to="/Inventories/Inventory?tab=stockAdjustment">
            {t("StockAdjustment")}
          </Link>
        </Breadcrumbs>

        <ArazContainer>
          <ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazSaveButton onClick={formik.handleSubmit} type="submit" />
                <ArazButton
                  color="error"
                  variant="outlined"
                  sx={{ ml: 1 }}
                  onClick={() => {
                    confirm({
                      confirmationButtonProps: { color: "error", variant: "contained" },
                      titleProps: { color: "error" },
                      title: t("Commons.Delete"),
                      description: t("Commons.Delete.confirm"),
                      confirmationText: t("Commons.Delete"),
                      cancellationText: t("Commons.Cancel"),
                    })
                      .then(async () => {
                        const result = await Delete({ navigate, dispatch, t }, EntityTypes.StockAdjustment, id);
                        if (result.isSuccess) navigate("/Inventories/Inventory");
                        else formik.setStatus({ error: result.error });
                      })
                      .catch(() => {});
                  }}
                >
                  {t("Commons.Delete")}
                </ArazButton>
                {formik.values.canConfirm && (
                  <ArazButton
                    color="warning"
                    startIcon={<CheckIcon />}
                    sx={{ float: "right", ml: 1 }}
                    onClick={async () => {
                      const result = await Confirm({ navigate, dispatch, t }, id);
                      if (result && result.isSuccess) SetFormValue(result);
                      else formik.setStatus({ error: result.error });
                    }}
                  >
                    {t("Commons.Confirm")}
                  </ArazButton>
                )}
              </ArazGridItem>
              {formik.status && formik.status.error && (
                <ArazGrid xs={12}>
                  <ArazGridItem>
                    {formik.status.error.map((e, i) => (
                      <Typography component="span" color="error" key={i}>
                        {e[Object.keys(formik.status.error[0])[0]]}
                      </Typography>
                    ))}
                  </ArazGridItem>
                </ArazGrid>
              )}
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <ArazGridItem>
                <TextField
                  id="code"
                  name="code"
                  label={t("Inventories.StockAdjustment.Field.Code")}
                  variant="outlined"
                  fullWidth
                  size="medium"
                  value={formik.values.code}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("code", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <ArazGridItem sx={{ textAlign: "center" }}>
                <ArazDatePicker
                  id="adjustmentDate"
                  name="adjustmentDate"
                  value={formik.values.adjustmentDate}
                  setValue={(v) => formik.setFieldValue("adjustmentDate", v)}
                  label={t("Inventories.StockAdjustment.Field.AdjustmentDate")}
                  error={formik.touched.adjustmentDate && Boolean(formik.errors.adjustmentDate)}
                  helperText={formik.touched.adjustmentDate && formik.errors.adjustmentDate}
                />
              </ArazGridItem>
            </ArazGrid>

            <ArazGrid xs={12} md={6}>
              <ArazGridItem>
                <ArazWarehouseAutoComplete
                  id="warehouseId"
                  name="warehouseId"
                  value={formik.values.warehouseId}
                  defaultValue={formik.values.warehouseId}
                  setValue={(v) => formik.setFieldValue("warehouseId", v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.warehouseId && Boolean(formik.errors.warehouseId)}
                  helperText={formik.touched.warehouseId && formik.errors.warehouseId}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <ArazGridItem>
                <TextField
                  id="reason"
                  name="reason"
                  label={t("Inventories.StockAdjustment.Field.Reason")}
                  variant="outlined"
                  fullWidth
                  size="medium"
                  value={formik.values.reason}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("reason", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.reason && Boolean(formik.errors.reason)}
                  helperText={formik.touched.reason && formik.errors.reason}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </ArazContainer>
        <ArazContainer>
          <ArazContainerHeader>{t("Inventories.StockAdjustment.Field.StockAdjustmentItems")}</ArazContainerHeader>
          <ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazAddButton
                  label={t("Commons.Insert")}
                  left
                  onClick={async () => await OpenItem({ id: Math.floor(Math.random() * -100000000 + 1), product: null, quantity: 1, bomId: 0 })}
                  sx={{ mt: 1 }}
                />
              </ArazGridItem>
              {formik.touched.stockAdjustmentItems && Boolean(formik.errors.stockAdjustmentItems) && (
                <ArazGridItem>
                  <Typography component="span" color="error">
                    {formik.touched.stockAdjustmentItems && formik.errors.stockAdjustmentItems}
                  </Typography>
                </ArazGridItem>
              )}
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazDataGrid
                  manual
                  rows={formik.values.stockAdjustmentItems}
                  columns={columns}
                  sortingMode="client"
                  paginationMode="client"
                  rowCount={formik.values.stockAdjustmentItems.length}
                  onRowClick={async (param) => {
                    await OpenItem({
                      id: param.id,
                      product: { id: param.row.productId, name: param.row.productName, boms: [{ id: param.row.bomId }] },
                      quantity: param.row.quantity,
                      bomId: param.row.bomId,
                    });
                  }}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </ArazContainer>
      </ArazBox>
      {openItem && <AdjustmentItem open={openItem} setOpen={setOpenItem} value={item} onSave={handleOnSaveItem} />}
    </form>
  );
}
