import * as baseApi from "Services/Base/ApiBaseService";
import { EntityTypes } from "Services/Base/constants";

export const GetNextCode = async (hooks) => {
  let response = await baseApi.fetchWithTimeout(hooks, `${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.StockAdjustment}/GetNextCode`, {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  return baseApi.ReadResult(hooks, response);
};

export const Confirm = async (hooks, stockAdjustmentId) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks, `${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.StockAdjustment}/Confirm/${stockAdjustmentId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};
