import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Avatar, IconButton, Menu } from "@mui/material";
import { useState } from "react";
import Link from "@mui/material/Link";
import i18next from "i18next";
import { useValue } from "context/ContextProvider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SetWorkingLanguage } from "Services/Security/UserService";

export default function LanguageSelector() {
  const {
    state: { language },
    dispatch,
  } = useValue();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const menuHandleClose = () => {
    setAnchorEl(null);
  };
  const menuHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Link href="#" underline="none" onClick={menuHandleClick} sx={{ mx: 1, height: 32 }}>
        {language == "fa" ? <img src="/assets/locales/fa/flag.png" /> : <img src="/assets/locales/en/flag.png" />}
      </Link>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={menuHandleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {language == "en" && (
          <MenuItem
            value="fa"
            onClick={async () => {
              dispatch({ type: "CHANGE_LANGUAGE", payload: "fa" });
              await SetWorkingLanguage({ navigate, dispatch, t }, "fa");
              menuHandleClose();
            }}
          >
            <IconButton>
              <img src="/assets/locales/fa/flag.png" />
            </IconButton>
            فارسی
          </MenuItem>
        )}
        {language == "fa" && (
          <MenuItem
            value="en"
            onClick={async () => {
              dispatch({ type: "CHANGE_LANGUAGE", payload: "en" });
              await SetWorkingLanguage({ navigate, dispatch, t }, "en");
              menuHandleClose();
            }}
          >
            <IconButton>
              <img src="/assets/locales/en/flag.png" />
            </IconButton>
            English
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
