import { Avatar, Box, Button, Chip, Divider, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useValue } from "context/ContextProvider";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Profile from "./Profile";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { DeleteSample } from "Services/Company/CompanyService";
import { IsAdmin } from "Services/Security/UserService";

export default function UserMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { currentUser },
    dispatch,
  } = useValue();
  const confirm = useConfirm();

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const menuHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuHandleClose = () => {
    setAnchorEl(null);
  };

  const [openProfile, setOpenProfile] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <Avatar
          sx={{
            borderColor: "navbar.main",
            height: 50,
            width: 50,
            cursor: "pointer",
            borderStyle: "solid",
            color: "navbar.main",
          }}
          onClick={menuHandleClick}
        >
          <img src={`${process.env.REACT_APP_BaseWWWAddress}${currentUser?.pictureUrl}`} />
        </Avatar>

        <Button id="basic-button" aria-controls={menuOpen ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen ? "true" : undefined} onClick={menuHandleClick}>
          <Stack>
            <Typography variant="h6" color="navbar.main">
              {currentUser?.name}
            </Typography>
            {currentUser?.trialRemainDay > 0 && <Chip label={`${currentUser?.trialRemainDay} ${t("TrialRemainDay")}`} color="error" size="small" />}
            {currentUser?.hasInvoice && (
              <Chip
                label={`${t("HasInvoice")}`}
                color="error"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  navigate("/Payments/Invoice");
                }}
              />
            )}
          </Stack>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={menuHandleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setOpenProfile(true);
            }}
          >
            {t("Profile")}
          </MenuItem>

          {IsAdmin() && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigate("/Payments/Invoice");
              }}
            >
              {t("Invoice")}
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/Login");
            }}
          >
            {t("Logout")}
          </MenuItem>

          {currentUser?.showDeleteSampleData && <Divider />}
          {currentUser?.showDeleteSampleData && (
            <MenuItem
              onClick={async () => {
                setAnchorEl(null);

                confirm({
                  confirmationButtonProps: { color: "error", variant: "contained" },
                  titleProps: { color: "error" },
                  title: t("Commons.Delete"),
                  description: t("Commons.Delete.confirm"),
                  confirmationText: t("Commons.Delete"),
                  cancellationText: t("Commons.Cancel"),
                })
                  .then(async () => {
                    const result = await DeleteSample({ navigate, dispatch, t });
                    if (result.isSuccess) {
                      currentUser.showDeleteSampleData = false;
                      dispatch({ type: "UPDATE_USER", payload: currentUser });
                      navigate("/");
                    }
                  })
                  .catch(() => {});
              }}
            >
              <DeleteIcon color="error" />
              <Typography variant="span" color="error.main">
                {t("DeleteSampleData")}
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </Box>
      {openProfile && <Profile open={openProfile} setOpen={setOpenProfile} />}
    </>
  );
}
