import { Typography } from "@mui/material";
import ArazGrid from "./ArazGrid";

const ArazContainerHeader = ({ children, sx, ...props }) => {
  return (
    <ArazGrid xs={12}>
      <Typography variant="subtitle1" sx={{ borderBottom: 1, borderColor: "background.default", mb: 1, pb: 1, pr: 12, ...sx }} {...props}>
        {children}
      </Typography>
    </ArazGrid>
  );
};

export default ArazContainerHeader;
