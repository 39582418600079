import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { useState } from "react";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { useMemo } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { LocalDateFormat } from "i18n/LocalDate";
import { useEffect } from "react";
import { DeletePurchaseOrderItemReceive } from "Services/PurchaseOrders/PurchaseOrderService";
import PurchaseOrderItemReceivePartially from "./PurchaseOrderItemReceivePartially";

export default function PurchaseOrderItemReceiveList({ open, setOpen, purchaseOrderId, purchaseOrderItemId, remainQuantity, values, onSave }) {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState(false);
  const [openPurchaseOrderItemReceivePartially, setOpenPurchaseOrderItemReceivePartially] = useState(false);
  const {
    state: {language},
    dispatch,
  } = useValue();

  useEffect(() => {
    setItems(values);
  }, [values]);

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "quantity",
        headerName: t("Manufactures.PurchaseOrderItemReceive.Field.Quantity"),
        flex: 1,
        minWidth: 100,
        type: "number",
        align: "center",
        headerAlign: "center",
      },
      {
        field: "receivedDate",
        headerName: t("Manufactures.PurchaseOrderItemReceive.Field.ReceivedDate"),
        flex: 1,
        minWidth: 100,
        type: "date",
        align: "center",
        headerAlign: "center",
        valueFormatter: (params) => {
          return `${LocalDateFormat(params.value)}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        minWidth: 100,
        getActions: (params) => {
          const actions = [];
          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              color="error"
              onClick={() => {
                confirm({
                  confirmationButtonProps: { color: "error", variant: "contained" },
                  titleProps: { color: "error" },
                  title: t("Commons.Delete"),
                  description: t("Commons.Delete.confirm"),
                  confirmationText: t("Commons.Delete"),
                  cancellationText: t("Commons.Cancel"),
                })
                  .then(async () => {
                    const result = await DeletePurchaseOrderItemReceive({ navigate, dispatch, t }, { purchaseOrderId, purchaseOrderItemReceiveId: params.id });
                    if (result.isSuccess) handleOnSaveItemReceivePartially(result);
                  })
                  .catch(() => {});
              }}
            />
          );
          return actions;
        },
        align: "center",
        headerAlign: "center",
      },
    ],
    [language]
  );

  const [item, setItem] = useState({ id: 0, receivedDate: new Date(), code: "", quantity: 1, purchaseOrderId, purchaseOrderItemId });
  async function openItem(v) {
    await setItem(v);
    setOpenPurchaseOrderItemReceivePartially(true);
  }

  const [itemSend, setItemSend] = useState({ id: 0, receivedDate: new Date(), purchaseOrderId, purchaseOrderItemId });
  async function openItemSend(v) {
    await setItemSend(v);
  }

  function handleOnSaveItemReceivePartially(v) {
    onSave(v);
  }

  function close() {
    setOpen(false);
  }
  return (
    <>
      <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="lg">
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {t("Commons.Pack")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazAddButton
                label={t("Commons.Insert")}
                left
                onClick={async () => await openItem({ purchaseOrderId, purchaseOrderItemId, id: 0, receivedDate: new Date(), quantity: remainQuantity })}
                sx={{ mt: 1 }}
              />
            </ArazGridItem>
            <ArazGridItem>
              <ArazDataGrid
                manual
                rows={items}
                columns={columns}
                sortingMode="client"
                paginationMode="client"
                rowCount={items.length}
                onRowClick={async (param) => {
                  await openItem({ purchaseOrderId, purchaseOrderItemId, id: param.id, receivedDate: param.row.receivedDate, code: param.row.code, quantity: param.row.quantity });
                }}
              />
            </ArazGridItem>
          </ArazGrid>
        </DialogContent>
        <DialogActions></DialogActions>
      </ArazDialog>
      {openPurchaseOrderItemReceivePartially && (
        <PurchaseOrderItemReceivePartially open={openPurchaseOrderItemReceivePartially} setOpen={setOpenPurchaseOrderItemReceivePartially} value={item} onSave={handleOnSaveItemReceivePartially} />
      )}
    </>
  );
}
