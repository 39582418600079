import { useState } from "react";
import ArazBox from "components/ArazComponents/ArazBox";
import ArazOuterTabs from "components/ArazComponents/ArazOuterTabs";
import ArazTab from "components/ArazComponents/ArazTab";
import ArazTabPanel, { ArazTabProps } from "components/ArazComponents/ArazTabPanel";
import { useTranslation } from "react-i18next";
import ProductInfo from "./ProductInfo";
import Bom from "./Bom";
import { useParams } from "react-router-dom";
import { IsAdmin, IsManufactures } from "Services/Security/UserService";

export default function Product() {
  const { t } = useTranslation();
  let { id } = useParams();
  const tabs = {
    infoTab: 0,
    bomTab: 1,
  };
  const [outerTabValue, setOuterTabValue] = useState(tabs.infoTab);

  return (
    <ArazBox sx={{ px: { sm: 0, md: 2 } }}>
      <ArazOuterTabs
        value={outerTabValue}
        onChange={(event, newValue) => {
          setOuterTabValue(newValue);
        }}
      >
        <ArazTab label={t("Commons.GeneralInfo")} {...ArazTabProps(tabs.infoTab)} />
        {id != "New" && (IsAdmin() || IsManufactures()) && <ArazTab label={t("Bom")} {...ArazTabProps(tabs.bomTab)} style={{ left: "20px" }} />}
      </ArazOuterTabs>

      <ArazTabPanel value={outerTabValue} index={tabs.infoTab}>
        <ProductInfo id={id} />
      </ArazTabPanel>
      {id != "New" && (IsAdmin() || IsManufactures()) && (
        <ArazTabPanel value={outerTabValue} index={tabs.bomTab}>
          <Bom productId={id} />
        </ArazTabPanel>
      )}
    </ArazBox>
  );
}
