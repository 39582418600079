import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { useValue } from "context/ContextProvider";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
export default function WarehouseBomLedgerInStock({ items }) {
  const { t } = useTranslation();
  const {
    state: { language },
    dispatch,
  } = useValue();

  const columns = useMemo(
    () => [
      {
        field: "causedTypeString",
        headerName: t("Inventories.WarehouseBom.Field.InStockDto.CausedTypeString"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "causedBy",
        headerName: t("Inventories.WarehouseBom.Field.InStockDto.CausedBy"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "quantity",
        headerName: t("Inventories.WarehouseBom.Field.InStockDto.Quantity"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
    ],
    [language]
  );

  return (
    <ArazGrid xs={12}>
      <ArazGridItem>
        <ArazDataGrid
          manual
          rows={items}
          columns={columns}
          sortingMode="client"
          paginationMode="client"
          rowCount={items.length}
          onRowClick={async (param) => {}}
          getRowId={(row) => `${row.causedTypeString}${row.causedBy}${row.quantity}`}
        />
      </ArazGridItem>
    </ArazGrid>
  );
}
