import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { Typography } from "@mui/material";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import { useFormik } from "formik";
import * as yup from "yup";
import ArazDatePicker from "components/ArazComponents/ArazDatePicker";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { EntityTypes } from "Services/Base/constants";
import { Send } from "Services/SalesOrders/SaleOrderService";

export default function SaleOrderItemPackSend({ open, setOpen, value, onSave }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      saleOrderId: value.saleOrderId,
      sendDate: value.sendDate,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.setStatus({ error: null });
      const result = await Send(
        { navigate, dispatch, t },
        {
          saleOrderId: values.saleOrderId,
          sendDate: values.sendDate,
        }
      );

      if (result && result.isSuccess) {
        dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
        onSave(result);
        close();
      } else formik.setStatus({ error: result.error });
    },
  });

  function close() {
    setOpen(false);
  }
  return (
    <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
      <form>
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {t("Commons.Send")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid xs={12}>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazDatePicker
                  id="sendDate"
                  name="sendDate"
                  value={formik.values.sendDate}
                  setValue={(v) => formik.setFieldValue("sendDate", v)}
                  onBlur={formik.handleBlur}
                  label={t("Manufactures.SaleOrderItemPackSend.Field.SendDate")}
                  error={formik.touched.sendDate && Boolean(formik.errors.sendDate)}
                  helperText={formik.touched.sendDate && formik.errors.sendDate}
                />
              </ArazGridItem>
            </ArazGrid>
            {formik.status && formik.status.error && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  {formik.status.error.map((e, i) => (
                    <Typography component="span" color="error" key={i}>
                      {e[Object.keys(formik.status.error[0])[0]]}
                    </Typography>
                  ))}
                </ArazGridItem>
              </ArazGrid>
            )}
          </ArazGrid>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="text" autoFocus onClick={close}>
            {t("Commons.Cancel")}
          </Button>
          <Button autoFocus type="submit" color="success" variant="contained" onClick={formik.handleSubmit}>
            {t("Commons.Save")}
          </Button>
        </DialogActions>
      </form>
    </ArazDialog>
  );
}
