import { useEffect, useState } from "react";
import ArazBox from "components/ArazComponents/ArazBox";
import { Typography } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { ZarinPalVerifyPayment } from "Services/Payments/PaymentService";
import { useTranslation } from "react-i18next";

export default function Verify() {
  const { t } = useTranslation();

  let { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams.get("Status");
  const authority = searchParams.get("Authority");

  const navigate = useNavigate();
  const {
    state: {},
    dispatch,
  } = useValue();

  const [success, setSuccess] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    (async () => {
      if (status == "OK") {
        const result = await ZarinPalVerifyPayment({ navigate, dispatch, t }, { id: id, authority: authority });
        if (result.isSuccess) setSuccess(true);
      }
      setChecked(true);
    })();
  }, []);

  return (
    <ArazBox>
      {checked ? (
        success ? (
          <Typography color="success.main" variant="h3">
            پرداخت با موفقیت انجام شد
          </Typography>
        ) : (
          <Typography color="error" variant="h3">
            خطا در پرداخت ، در صورتی که مبلغ از حساب شما کسر شده باشد بعد از 48 ساعت به حساب شما باز خواهد گشت
          </Typography>
        )
      ) : (
        <></>
      )}
    </ArazBox>
  );
}
