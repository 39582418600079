import * as baseApi from "Services/Base/ApiBaseService";
import { EntityTypes } from "Services/Base/constants";
export const GetNextCode = async (hooks) => {
  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.SaleOrder}/GetNextCode`, {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  return baseApi.ReadResult(hooks, response);
};
export const GetNextPackCode = async (hooks) => {
  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.SaleOrder}/GetNextPackCode`, {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  return baseApi.ReadResult(hooks, response);
};

export const Confirm = async (hooks, saleOrderId) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.SaleOrder}/Confirm/${saleOrderId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};

export const Pack = async (hooks, data) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.SaleOrder}/Pack/${data.saleOrderId}/${data.code}/${data.packDate.toISOString().split("T")[0]}`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};

export const PartiallyPack = async (hooks, data) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.SaleOrder}/PartiallyPack/${data.saleOrderId}`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
    body: JSON.stringify(data),
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};

export const UpdateSaleOrderItemPack = async (hooks, data) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.SaleOrder}/UpdateSaleOrderItemPack/${data.saleOrderId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
    body: JSON.stringify(data),
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};

export const DeleteSaleOrderItemPack = async (hooks, data) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.SaleOrder}/DeleteSaleOrderItemPack/${data.saleOrderId}/${data.saleOrderItemPackId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};

/////////// send ////////////////////
export const Send = async (hooks, data) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.SaleOrder}/Send/${data.saleOrderId}/${data.sendDate.toISOString().split("T")[0]}`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};

export const PartiallySend = async (hooks, data) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.SaleOrder}/PartiallySend/${data.saleOrderId}`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
    body: JSON.stringify(data),
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};

export const UpdateSaleOrderItemSend = async (hooks, data) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.SaleOrder}/UpdateSaleOrderItemSend/${data.saleOrderId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
    body: JSON.stringify(data),
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};

export const DeleteSaleOrderItemSend = async (hooks, data) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.SaleOrder}/DeleteSaleOrderItemSend/${data.saleOrderId}/${data.saleOrderItemPackSendId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};
