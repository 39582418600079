import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useEffect } from "react";
import { useState } from "react";
import WarehouseBomLedgerInStock from "./WarehouseBomLedgerInStock";
import WarehouseBomLedgerCommitted from "./WarehouseBomLedgerCommitted";
import WarehouseBomLedgerExpected from "./WarehouseBomLedgerExpected";
import { GetByWarehouseIdAndBomId } from "Services/Inventories/WarehouseService";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import { Button, DialogActions, DialogContent, Stack, useTheme } from "@mui/material";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import ArazOuterTabs from "components/ArazComponents/ArazOuterTabs";
import ArazTab from "components/ArazComponents/ArazTab";
import ArazTabPanel, { ArazTabProps } from "components/ArazComponents/ArazTabPanel";

export default function WarehouseBomLedger({ open, setOpen, warehouseId, bomId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {},
    dispatch,
  } = useValue();
  const theme = useTheme();
  const [ledgerDto, setLedgerDto] = useState({ productName: "", warehouseName: "", inStock: [], committed: [], expected: [] });

  useEffect(() => {
    (async () => {
      const result = await GetByWarehouseIdAndBomId({ navigate, dispatch, t }, warehouseId, bomId);
      setLedgerDto(result.data);
    })();
  }, [warehouseId, bomId]);

  const tabs = {
    inStockTab: 0,
    committedTab: 1,
    expectedTab: 2,
  };

  const [outerTabValue, setOuterTabValue] = useState(tabs.inStockTab);

  function close() {
    setOpen(false);
  }

  return (
    <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="xl">
      <ArazDialogTitle id="customized-dialog-title" onClose={close}>
        <Stack direction="row" spacing={3}>
          <span> {`${t("Inventories.Warehouse.Field.Name")} : ${ledgerDto.warehouseName}`}</span>
          <span> {`${t("Manufactures.Product.Field.Name")} : ${ledgerDto.productName}`}</span>
        </Stack>
      </ArazDialogTitle>
      <DialogContent dividers>
        <ArazGrid xs={12}>
          <ArazGrid xs={6} md={3}>
            <ArazGridItem>
              <Stack>
                <span style={{ textAlign: "center" }}> {t("Inventories.WarehouseBom.Field.MissingExcess")}</span>
                <span style={{ textAlign: "center" }}> {ledgerDto.missingExcess}</span>
              </Stack>
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={6} md={3}>
            <ArazGridItem>
              <Stack>
                <span style={{ textAlign: "center" }}>{t("Inventories.WarehouseBom.Field.InStockCount")}</span>
                <span style={{ textAlign: "center" }}> {ledgerDto.inStockCount}</span>
              </Stack>
            </ArazGridItem>
          </ArazGrid>

          <ArazGrid xs={6} md={3}>
            <ArazGridItem>
              <Stack>
                <span style={{ textAlign: "center" }}> {t("Inventories.WarehouseBom.Field.CommittedCount")}</span>
                <span style={{ textAlign: "center" }}> {ledgerDto.committedCount}</span>
              </Stack>
            </ArazGridItem>
          </ArazGrid>

          <ArazGrid xs={6} md={3}>
            <ArazGridItem>
              <Stack>
                <span style={{ textAlign: "center" }}> {t("Inventories.WarehouseBom.Field.ExpectedCount")}</span>
                <span style={{ textAlign: "center" }}> {ledgerDto.expectedCount}</span>
              </Stack>
            </ArazGridItem>
          </ArazGrid>

          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazOuterTabs
                variant={theme.breakpoints.down("md") ? "scrollable" : ""}
                value={outerTabValue}
                onChange={(event, newValue) => {
                  setOuterTabValue(newValue);
                }}
              >
                <ArazTab label={t("InStock")} {...ArazTabProps(tabs.inStockTab)} />
                <ArazTab label={t("Committed")} {...ArazTabProps(tabs.committedTab)} />
                <ArazTab label={t("Expected")} {...ArazTabProps(tabs.expectedTab)} />
              </ArazOuterTabs>

              <ArazTabPanel value={outerTabValue} index={tabs.inStockTab}>
                <WarehouseBomLedgerInStock items={ledgerDto.inStock} />
              </ArazTabPanel>
              <ArazTabPanel value={outerTabValue} index={tabs.committedTab}>
                <WarehouseBomLedgerCommitted items={ledgerDto.committed} />
              </ArazTabPanel>
              <ArazTabPanel value={outerTabValue} index={tabs.expectedTab}>
                <WarehouseBomLedgerExpected items={ledgerDto.expected} />
              </ArazTabPanel>
            </ArazGridItem>
          </ArazGrid>
        </ArazGrid>
      </DialogContent>
    </ArazDialog>
  );
}
