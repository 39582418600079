import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { TextField, Typography } from "@mui/material";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import { EntityTypes } from "Services/Base/constants";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { Insert, Update } from "Services/Base/ApiBaseService";
import ArazSpecificationAutoComplete from "components/ArazComponents/ArazSpecificationAutoComplete";

export default function SpecificationValue({ open, setOpen, value, onSave }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({
    value: yup.string().required().label(t("Commons.SpecificationValue.Field.Value")),

    specification: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Commons.SpecificationValue.Field.SpecificationId")),
  });

  useEffect(() => {
    formik.resetForm();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: value.id,
      value: value.value,
      specification: value.specification ?? null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.setStatus({ error: null });
      const data = {
        id: values.id,
        value: values.value,
        specificationId: values.specification.id,
      };
      const result = values.id == 0 ? await Insert({ navigate, dispatch, t }, EntityTypes.SpecificationValue, data) : await Update({ navigate, dispatch, t }, EntityTypes.SpecificationValue, data);
      if (result && result.isSuccess) {
        dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
        onSave();
        close();
      } else formik.setStatus({ error: result.error });
    },
  });

  function close() {
    setOpen(false);
  }
  return (
    <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
      <form>
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {value.id == 0 ? t("Commons.New") : t("Commons.Edit")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid xs={12}>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazSpecificationAutoComplete
                  id="specification"
                  name="specification"
                  value={formik.values.specification}
                  defaultValue={formik.values.specification}
                  label={t("Commons.SpecificationValue.Field.SpecificationId")}
                  setValue={(v) => formik.setFieldValue("specification", v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.specification && Boolean(formik.errors.specification)}
                  helperText={formik.touched.specification && formik.errors.specification}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="value"
                  name="value"
                  label={t("Commons.SpecificationValue.Field.Value")}
                  fullWidth
                  value={formik.values.value}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("value", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.value && Boolean(formik.errors.value)}
                  helperText={formik.touched.value && formik.errors.value}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </DialogContent>
        <DialogActions>
          {formik.status &&
            formik.status.error &&
            formik.status.error.map((e, i) => (
              <Typography component="span" color="error" key={i}>
                {e[Object.keys(formik.status.error[0])[0]]}
              </Typography>
            ))}
          <Button color="error" variant="text" autoFocus onClick={close}>
            {t("Commons.Cancel")}
          </Button>
          <Button autoFocus type="submit" color="success" variant="contained" onClick={formik.handleSubmit}>
            {t("Commons.Save")}
          </Button>
        </DialogActions>
      </form>
    </ArazDialog>
  );
}
