import { useMemo, useState } from "react";
import ArazBox from "components/ArazComponents/ArazBox";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { useTranslation } from "react-i18next";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { EntityTypes } from "Services/Base/constants";
import { useConfirm } from "material-ui-confirm";
import { Delete } from "Services/Base/ApiBaseService";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import ArazContainer from "components/ArazComponents/ArazContainer";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import Person from "./Person";

export default function Persons() {
  const { t } = useTranslation();

  const confirm = useConfirm();
  const navigate = useNavigate();
  const {
    state: {language},
    dispatch,
  } = useValue();

  const [trigger, setTrigger] = useState(false);
  const [item, setItem] = useState({ id: 0, name: "", addressLine: "", phone: "" });
  const [openItem, setOpenItem] = useState(false);
  async function OpenItem(v) {
    await setItem(v);
    setOpenItem(true);
  }

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "name",
        headerName: t("Commons.Person.Field.Name"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "addressLine",
        headerName: t("Commons.PersonAddress.Field.AddressLine"),
        flex: 1,
        minWidth: 200,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          return `${params.value}`; // ${params.row.phone}
        },
      },
      {
        field: "phone",
        headerName: t("Commons.PersonAddress.Field.Phone"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => {
          return `${params.value}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        minWidth: 100,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(async () => {
                  const result = await Delete({ navigate, dispatch,t }, EntityTypes.Person, params.id);
                  if (result.isSuccess) setTrigger((trigger) => !trigger);
                })
                .catch(() => {});
            }}
          />,
        ],
        align: "center",
        headerAlign: "center",
      },
    ],
    [language]
  );

  function handleOnSaveItem(item) {
    setTrigger((trigger) => !trigger);
  }

  return (
    <>
      <ArazBox>
        <ArazContainer>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazAddButton
                label={t("Person.New")}
                onClick={() => {
                  OpenItem({ id: 0, name: "", addressLine: "", phone: "" });
                }}
              />
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazDataGrid
                columns={columns}
                entityType={EntityTypes.Person}
                trigger={trigger}
                onRowClick={async (param) => {
                  await OpenItem({
                    id: param.id,
                    name: param.row.name,
                    addressLine: param.row.addressLine,
                    phone: param.row.phone,
                  });
                }}
              />
            </ArazGridItem>
          </ArazGrid>
        </ArazContainer>
      </ArazBox>
      {openItem && <Person open={openItem} setOpen={setOpenItem} value={item} onSave={handleOnSaveItem} />}
    </>
  );
}
