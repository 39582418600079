import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { TextField } from "@mui/material";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import ArazProcessAutoComplete from "components/ArazComponents/ArazProcessAutoComplete";
import ArazWorkCenterAutoComplete from "components/ArazComponents/ArazWorkCenterAutoComplete";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="mm:hm:hm:hm"
      definitions={{
        m: /[0-9]/,
        h: /[0-5]/,
      }}
      inputRef={ref}
      onAccept={(value) => {
        onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function ProcesesItem({ open, setOpen, value, onSave }) {
  const { t } = useTranslation();
  const validationSchema = yup.object().shape({
    process: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.ManufacturingWorkOrder.Field.ProcessName")),

    sequence: yup.string().required().label(t("Manufactures.BomProcess.Field.Sequence")),
    workCenter: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.ManufacturingWorkOrder.Field.WorkCenterName")),
    expectedDuration: yup.string().required().label(t("Manufactures.BomProcess.Field.Duration")),
  });

  useEffect(() => {
    formik.resetForm();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: value.id,
      process: value.process,
      sequence: value.sequence,
      workCenter: value.workCenter,
      expectedDuration: value.expectedDuration,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSave(values);
      close();
    },
  });

  function setProcess(p) {
    formik.setFieldValue("process", p);
  }

  function close() {
    setOpen(false);
  }

  function ConvertTimeStampToReadable(input) {
    const data = input?.split(":");

    let format = [];
    if (data[0]) format.push(data[data.length - 1] + ` ${t("Second")} `);
    if (data[1]) format.push(data[data.length - 2] + ` ${t("Minute")} `);
    if (data[2]) format.push(data[data.length - 3] + ` ${t("Hour")} `);
    if (data[3]) format.push(data[data.length - 4] + ` ${t("Day")} `);

    return format.reverse().toString();
  }

  return (
    <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
      <form>
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {t("Commons.New")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid xs={12}>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazProcessAutoComplete
                  id="process"
                  name="process"
                  value={formik.values.process}
                  defaultValue={formik.values.process}
                  setValue={(p) => setProcess(p)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.process && Boolean(formik.errors.process)}
                  helperText={formik.touched.process && formik.errors.process}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazWorkCenterAutoComplete
                  id="workCenter"
                  name="workCenter"
                  value={formik.values.workCenter}
                  defaultValue={formik.values.workCenter}
                  setValue={(p) => formik.setFieldValue("workCenter", p)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.workCenter && Boolean(formik.errors.workCenter)}
                  helperText={formik.touched.workCenter && formik.errors.workCenter}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="sequence"
                  name="sequence"
                  label={t("Manufactures.BomProcess.Field.Sequence")}
                  type="number"
                  fullWidth
                  value={formik.values.sequence}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("sequence", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.sequence && Boolean(formik.errors.sequence)}
                  helperText={formik.touched.sequence && formik.errors.sequence}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="expectedDuration"
                  name="expectedDuration"
                  label={t("Manufactures.BomProcess.Field.Duration")}
                  fullWidth
                  value={formik.values.expectedDuration}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("expectedDuration", event.target.value, true);
                  }}
                  error={formik.touched.expectedDuration && Boolean(formik.errors.expectedDuration)}
                  helperText={formik.touched.expectedDuration && formik.errors.expectedDuration}
                  placeholder="dd:hh:mm:ss"
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />
                {!formik.errors.expectedDuration && <Typography variant="caption">{ConvertTimeStampToReadable(formik.values.expectedDuration)}</Typography>}
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="text" autoFocus onClick={close}>
            {t("Commons.Cancel")}
          </Button>
          <Button autoFocus type="submit" color="success" variant="contained" onClick={formik.handleSubmit}>
            {t("Commons.Save")}
          </Button>
        </DialogActions>
      </form>
    </ArazDialog>
  );
}
