import { useMemo, useState } from "react";
import ArazTabPanel, { ArazTabProps } from "components/ArazComponents/ArazTabPanel";
import ArazTabs from "components/ArazComponents/ArazTabs";
import ArazArchiveTab from "components/ArazComponents/ArazArchiveTab";
import ArazActiveTab from "components/ArazComponents/ArazActiveTab";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocalDateFormat } from "i18n/LocalDate";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { EntityTypes, StockTransferStatuses } from "Services/Base/constants";
import { useConfirm } from "material-ui-confirm";
import { Delete } from "Services/Base/ApiBaseService";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import ArazGrid from "components/ArazComponents/ArazGrid";

export default function Transfers() {
  const { t } = useTranslation();
  const tabs = {
    inProgressTab: 0,
    doneTab: 1,
  };
  const confirm = useConfirm();
  const [tabValue, setTabValue] = useState(tabs.inProgressTab);
  const [trigger, setTrigger] = useState(false);
  const navigate = useNavigate();
  const {
    state: {language},
    dispatch,
  } = useValue();

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "code",
        headerName: t("Inventories.StockTransfer.Field.Code"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "originWarehouseName",
        headerName: t("Inventories.StockTransfer.Field.OriginWarehouseId"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "destinationWarehouseName",
        headerName: t("Inventories.StockTransfer.Field.DestinationWarehouseId"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "transferDate",
        headerName: t("Inventories.StockTransfer.Field.TransferDate"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        valueFormatter: (params) => {
          return `${LocalDateFormat(params.value)}`;
        },
      },
      {
        field: "statusString",
        headerName: t("Inventories.StockTransfer.Field.Status"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) =>
          params.row.status == StockTransferStatuses.Cancel ? (
            <Chip label={params.value} color="error" />
          ) : params.row.status == StockTransferStatuses.Draft ? (
            <Chip label={params.value} color="warning" />
          ) : params.row.status == StockTransferStatuses.Confirm ? (
            <Chip label={params.value} color="success" />
          ) : (
            params.value
          ),
      },

      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        minWidth: 100,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(async () => {
                  const result = await Delete({ navigate, dispatch, t }, EntityTypes.StockTransfer, params.id);
                  if (result.isSuccess) setTrigger((trigger) => !trigger);
                })
                .catch(() => {});
            }}
          />,
        ],
        align: "center",
        headerAlign: "center",
      },
    ],
    [tabValue,language]
  );

  const filters = useMemo(() => {
    if (tabValue == tabs.inProgressTab) return [["status", "=", StockTransferStatuses.Confirm]];
    else return [["status", "=", StockTransferStatuses.Draft], "or", ["status", "=", StockTransferStatuses.Cancel]];
  }, [tabValue,language]);

  return (
    <>
      <ArazGrid sx={{ float: { md: "right" }, mt: { xs: 2, md: 0 } }}>
        <ArazGrid xs={12}>
          <ArazAddButton
            label={t("StockTransfer.New")}
            onClick={() => {
              navigate("/Inventories/StockTransfer/New");
            }}
          />
        </ArazGrid>
      </ArazGrid>

      <ArazTabs
        value={tabValue}
        onChange={(event, newValue) => {
          setTabValue(newValue);
        }}
      >
        <ArazActiveTab label={t("Commons.Tabs.InProgress")} {...ArazTabProps(tabs.inProgressTab)} />
        <ArazArchiveTab label={t("Commons.Tabs.Done")} {...ArazTabProps(tabs.doneTab)} style={{ left: "20px" }} />
      </ArazTabs>

      <ArazTabPanel value={tabValue} index={tabValue}>
        <ArazDataGrid
          columns={columns}
          entityType={EntityTypes.StockTransfer}
          filters={filters}
          trigger={trigger}
          onRowClick={(param) => {
            navigate(`/Inventories/StockTransfer/${param.id}`);
          }}
        />
      </ArazTabPanel>
    </>
  );
}
