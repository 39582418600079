export function FormatTimeStamp(input) {
  const duration =
    input.length == 1
      ? "00:00:0" + input
      : input.length == 2
      ? "00:00:" + input
      : input.length == 3
      ? "00:00" + input
      : input.length == 4
      ? "00:0" + input
      : input.length == 5
      ? "00:" + input
      : input.length == 6
      ? "00" + input
      : input.length == 7
      ? "0" + input
      : input;

  return duration;
}
