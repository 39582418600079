import * as baseApi from "Services/Base/ApiBaseService";
import { EntityTypes } from "Services/Base/constants";

export const ZarinPalVerifyPayment = async (hooks, data) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks,`${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.Payment}/ZarinPalVerifyPayment/${data.id}`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
    body: JSON.stringify(data),
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};
