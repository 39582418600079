import * as React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ArazTabPanel, { ArazTabProps } from "components/ArazComponents/ArazTabPanel";
import CompanyCalendars from "./CompanyCalendars";
import Processes from "./Processes";
import WorkCenters from "./WorkCenters";
import Specifications from "./Specifications";
import SpecificationValues from "./SpecificationValues";
import UnitOfMeasureCategories from "./UnitOfMeasureCategories";
import UnitOfMeasures from "./UnitOfMeasures";
import Warehouses from "./Warehouses";
import Users from "./Users";
import { createSearchParams, useSearchParams } from "react-router-dom";
import ArazTab from "components/ArazComponents/ArazTab";
import ArazTabs from "components/ArazComponents/ArazTabs";
import ArazBox from "components/ArazComponents/ArazBox";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Settings() {
  const { t } = useTranslation();
  const tabs = {
    //TODO V3
    // companyCalendarTab: 0,
    processTab: 0,
    workCenterTab: 1,
    specificationTab: 2,
    specificationValueTab: 3,
    unitOfMeasureCategoryTab: 4,
    unitOfMeasureTab: 5,
    warehouseTab: 6,
    userTab: 7,
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const queryTab = searchParams.get("tab");

  const [tabValue, setTabValue] = React.useState(queryTab ? tabs[queryTab] : tabs.processTab);

  return (
    <ArazBox>
      <ArazTabs
        variant="scrollable"
        scrollButtons
        value={tabValue}
        onChange={(event, newValue) => {
          setTabValue(newValue);
          var key = Object.keys(tabs).filter(function (key) {
            return tabs[key] === newValue;
          })[0];
          setSearchParams(createSearchParams({ tab: key }));
        }}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {/* <ArazTab label={t("CompanyCalendar")} {...ArazTabProps(tabs.CompanyCalendarTab)} /> */}
        <ArazTab label={t("Process")} {...ArazTabProps(tabs.processTab)} />
        <ArazTab label={t("WorkCenter")} {...ArazTabProps(tabs.workCenterTab)} />
        <ArazTab label={t("Specification")} {...ArazTabProps(tabs.specificationTab)} />
        <ArazTab label={t("SpecificationValue")} {...ArazTabProps(tabs.specificationValueTab)} />
        <ArazTab label={t("UnitOfMeasureCategory")} {...ArazTabProps(tabs.unitOfMeasureCategoryTab)} />
        <ArazTab label={t("UnitOfMeasure")} {...ArazTabProps(tabs.unitOfMeasureTab)} />
        <ArazTab label={t("Warehouse")} {...ArazTabProps(tabs.warehouseTab)} />
        <ArazTab label={t("User")} {...ArazTabProps(tabs.userTab)} />
      </ArazTabs>

      {/* 
      TODO V3
      <ArazTabPanel value={tabValue} index={tabs.companyCalendarTab}>
        <CompanyCalendars />
      </ArazTabPanel> */}
      <ArazTabPanel value={tabValue} index={tabs.processTab}>
        <Processes />
      </ArazTabPanel>
      <ArazTabPanel value={tabValue} index={tabs.workCenterTab}>
        <WorkCenters />
      </ArazTabPanel>
      <ArazTabPanel value={tabValue} index={tabs.specificationTab}>
        <Specifications />
      </ArazTabPanel>
      <ArazTabPanel value={tabValue} index={tabs.specificationValueTab}>
        <SpecificationValues />
      </ArazTabPanel>
      <ArazTabPanel value={tabValue} index={tabs.unitOfMeasureCategoryTab}>
        <UnitOfMeasureCategories />
      </ArazTabPanel>
      <ArazTabPanel value={tabValue} index={tabs.unitOfMeasureTab}>
        <UnitOfMeasures />
      </ArazTabPanel>
      <ArazTabPanel value={tabValue} index={tabs.warehouseTab}>
        <Warehouses />
      </ArazTabPanel>
      <ArazTabPanel value={tabValue} index={tabs.userTab}>
        <Users />
      </ArazTabPanel>
    </ArazBox>
  );
}
