import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import ArazBox from "./ArazBox";
import { useNavigate } from "react-router-dom";
import { Search } from "Services/Base/ApiBaseService";
import { EntityTypes } from "Services/Base/constants";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { useValue } from "context/ContextProvider";
import Person from "Pages/Commons/Person";

const filter = createFilterOptions({
  matchFrom: "contains",
  stringify: (option) => option.personName,
});

export default function ArazPersonAddressAutoComplete({ value, setValue, defaultValue, label, error, helperText, ...props }) {
  const { t } = useTranslation();
  const {
    state: {},
    dispatch,
  } = useValue();
  const navigate = useNavigate();

  defaultValue = defaultValue ?? null;
  const [openDialog, setOpenDialog] = useState(false);

  const [data, setData] = useState([]);

  const [loading, SetLoading] = useState(false);

  const GetData = async (keyword) => {
    SetLoading(true);
    const result = await Search({ navigate, dispatch, t }, EntityTypes.PersonAddress, keyword ? [["personName", "contains", keyword], "or", ["addressLine", "contains", keyword]] : []);
    setData(result.data.data);
    SetLoading(false);
  };

  // Axios Shit goes here
  useEffect(() => {
    setValue(defaultValue);
    (async () => {
      await GetData();
    })();
  }, []);

  const [dialogValue, setDialogValue] = useState({ id: 0, name: "", addressLine: "" });

  const handleSubmit = (v) => {
    debugger;
    setValue({ ...v, personName: v.name, id: v.personAddresses[0].id });
  };

  return (
    <>
      <Autocomplete
        loading={loading}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue) => {
          if (!newValue) {
            setValue(null);
          } else if (newValue.inputValue) {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setOpenDialog(true);
              setDialogValue({
                name: newValue.inputValue,
              });
            });
          } else {
            setValue(newValue);
          }
        }}
        options={data}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (option.inputValue) {
            return option.personName;
          }
          return `${option.personName} , ${option.addressLine}`;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const isExisting = options.some((option) => params.inputValue === `${option.personName}`);
          if (params.inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue: params.inputValue,
              personName: `${t("Commons.Add")} ${params.inputValue}`,
            });
          }
          return filtered;
        }}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(`${option.personName} , ${option.addressLine}`, inputValue);
          const parts = option.id ? parse(`${option.personName} , ${option.addressLine}`, matches) : parse(`${option.personName}`, matches);

          return (
            <li {...props} key={option.id}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? "highlight.main" : "inherit",
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            onChange={async (e) => await GetData(e.target.value)}
            label={label ?? t("Manufactures.SaleOrder.Field.PersonAddressId")}
            error={error}
            helperText={helperText}
          />
        )}
        {...props}
      />
      {openDialog && <Person open={openDialog} setOpen={setOpenDialog} value={{ id: 0, name: dialogValue.name }} onSave={handleSubmit} />}
    </>
  );
}
