import { Breadcrumbs, Typography } from "@mui/material";
import ArazBox from "components/ArazComponents/ArazBox";
import ArazContainer from "components/ArazComponents/ArazContainer";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./video.css";

export default function Sales() {
  const { t } = useTranslation();

  return (
    <ArazBox>
      <Breadcrumbs aria-label="breadcrumb" sx={{ m: 2 }}>
        <Link key="1" underline="hover" color="inherit" to="..">
          {t("LearnMore")}
        </Link>
        <Typography key="2" color="text.primary">
          {t("SalesRole")}
        </Typography>
      </Breadcrumbs>

      <ArazContainer>
        <ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              {/* <div className="h_iframe-aparat_embed_frame">
                <span style={{ display: "block;padding-top: 57%" }}></span>
                <iframe
                  src="https://www.aparat.com/video/video/embed/videohash/Jm39c/vt/frame?titleShow=true&autoplay=true"
                  allow="autoplay"
                  allowFullScreen
                  webkitallowfullscreen
                  mozallowfullscreen
                ></iframe>
              </div> */}
              {/* <video src="/assets/knowledge/fa/sales/sales.mp4" /> */}
              {/* <video controls className="detail">
                <source src="/assets/knowledge/fa/sales/sales.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
            </ArazGridItem>
            <ArazGridItem>در این بخش می توانید تمامی محصولات فروخته شده را مشاهده کنید و همین طور فروش جدید انجام دهید</ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/1.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem>این صفحه شامل دو بخش اصلی است</ArazGridItem>
            <ArazGridItem> 1- پیش نویس</ArazGridItem>
            <ArazGridItem> 2- سفارشات</ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/2.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem> در قسمت پیش نویس سفارشاتی که هنوز تایید نشده اند نمایش داده می شود</ArazGridItem>
            <ArazGridItem> در قسمت سفارشات ، سفارشاتی که تایید شده و از مرحله پیش نویس عبور کرده اند نمایش داده می شود</ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/3.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem>سفارشات به دو دسته کلی فعال و بایگانی تقسیم می شوند ، سفارشات فعال به سفارشاتی گفته می شود که هنوز در جریان هستند و به طور کامل به مشتری تحویل داده نشده اند</ArazGridItem>
            <ArazGridItem>در جدول بخشی از اطلاعات مهم هر سفارش نمایش داده می شود</ArazGridItem>
            <ArazGridItem>با کلیک بر روی هر سطر می توانید جزئیات کامل هر سفارش را مشاهده کنید </ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/4.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem> با کلیک بر روی "افزودن فروش جدید" می توانید سفارش جدید ثبت کنید</ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/5.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem> صفحه جزئیات محصول به 3 بخش اصلی تقسیم می شود</ArazGridItem>
            <ArazGridItem> 1- وضعیت سفارش</ArazGridItem>
            <ArazGridItem> 2- اطلاعات کلی سفارش</ArazGridItem>
            <ArazGridItem> 3- اقلام سفارش</ArazGridItem>

            <ArazGridItem>1- در صفحه جزئیات محصول وضعیت فعلی سفارش مشخص شده است</ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/6.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem> در بخش اطلاعات کلی موارد زیر را مشخص می کنیم</ArazGridItem>
            <ArazGridItem> کد سفارش ، یک کد یکتا بوده که باعث شناسایی هر سفارش می شود</ArazGridItem>
            <ArazGridItem> تاریخ سررسید مشخص می کند که سفارش در چه تاریخی باید به خریدار تحویل شود</ArazGridItem>
            <ArazGridItem> میزان اهمیت هر سفارش نیز قابل انتخاب است</ArazGridItem>
            <ArazGridItem>
              آدرس خریدار مشخص کننده محل ارسال برای هر سفارش فروش است ، آدرس خریدار ها را در بخش اشخاص می توانید ویرایش و یا اضافه نمایید همچنین در صورتی که خریدار جدید باشد در صورت وارد نمودن نام
              جدید امکان افزودن آدرس از همین صفحه نیز وجود دارد
            </ArazGridItem>
            <ArazGridItem> استفاده از انبار مشخص می کند که اقلام این سفارش از کدام انبار باید ارسال گردد</ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/7.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem> بخش اقلام نشان دهنده کالاهایی است در این سفارش باید ارسال گردد</ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/8.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem> با کلیک بر روی هر سطر می توانید تعداد کالا را ویرایش نمایید همچنین با کلیک بر روی "افزودن" امکان افزودن کالای جدید به سفارش وجود دارد</ArazGridItem>
            <ArazGridItem> کالاهای هر سفارش ابتدا بسته بندی شده و سپس ارسال می گردند</ArazGridItem>
            <ArazGridItem> در ستون وضعیت موجودی مقدار موجودی کالا نشان داده می شود همچنین با کلیک بر روی این گزینه می توانید وضعیت موجودی آن کالا را به صورت کلی مشاهده نمایید</ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/9.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem>با کلیک بر روی بسته بندی می توانید تعداد بسته بندی شده و ارسال را مشاهده و ویرایش نمایید </ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/10.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/11.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem>در صورتی که کالا موجود باشد می توانید بر روی دکمه بسته بندی کلیک نمایید </ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/12.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
            <ArazGridItem>
              می توانید تمامی اقلام سفارش را به صورت یکجا بسته بندی و یا ارسال نمایید جهت این کار از بالای صفحه گزینه بسته بندی و یا ارسال را انتخاب نمایید در اینصورت اقلام سفارش به میزان حداکثر تعداد
              موجود بسته بندی و یا ارسال می گردند
            </ArazGridItem>
            <ArazGridItem>
              <img src="/assets/knowledge/fa/sales/13.png" style={{ maxWidth: "100%" }} />
            </ArazGridItem>
          </ArazGrid>
        </ArazGrid>
      </ArazContainer>
    </ArazBox>
  );
}
