import ArazBox from "components/ArazComponents/ArazBox";
import { useTranslation } from "react-i18next";
import { Step, StepLabel, Stepper, TextField, Tooltip, Typography } from "@mui/material";
import ArazContainer from "components/ArazComponents/ArazContainer";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import ArazSaveButton from "components/ArazComponents/ArazSaveButton";
import ArazDatePicker from "components/ArazComponents/ArazDatePicker";
import { useMemo, useState } from "react";
import ArazWarehouseAutoComplete from "components/ArazComponents/ArazWarehouseAutoComplete";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useConfirm } from "material-ui-confirm";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { Confirm, Received, GetNextCode } from "Services/PurchaseOrders/PurchaseOrderService";
import { useEffect } from "react";
import { Insert, GetDetail, Update, Delete } from "Services/Base/ApiBaseService";
import { EntityTypes, PurchaseOrderStatuses } from "Services/Base/constants";
import ArazButton from "components/ArazComponents/ArazButton";
import PagesIcon from "@mui/icons-material/Pages";
import PurchaseOrderItem from "./PurchaseOrderItem";
import PurchaseOrderItemReceivePartially from "./PurchaseOrderItemReceivePartially";
import PurchaseOrderItemReceive from "./PurchaseOrderItemReceive";
import PurchaseOrderItemReceiveList from "./PurchaseOrderItemReceiveList";
import ArazPersonAutoComplete from "components/ArazComponents/ArazPersonAutoComplete";
import CircleIcon from "@mui/icons-material/Circle";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import CheckIcon from "@mui/icons-material/Check";
import ArazContainerHeader from "components/ArazComponents/ArazContainerHeader";

export default function PurchaseOrder() {
  const { t } = useTranslation();
  let { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [itemReceives, setItemReceives] = useState();
  const [purchaseOrderItemId, setPurchaseOrderItemId] = useState(0);
  const [remainQuantity, setRemainQuantity] = useState(0);
  const [openPurchaseOrderItemReceiveList, setOpenPurchaseOrderItemReceiveList] = useState(false);

  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({
    code: yup.string().required().label(t("Manufactures.PurchaseOrder.Field.Code")),
    expectedArrive: yup.date().required().label(t("Manufactures.PurchaseOrder.Field.ExpectedArrive")),

    vendorId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.PurchaseOrder.Field.VendorAddress")),
    receiverWarehouseId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.PurchaseOrder.Field.ReceiverWarehouse")),
    purchaseOrderItems: yup.array().nullable().min(1).required().label(" "),
  });

  const formik = useFormik({
    initialValues: {
      id: 0,
      code: "",
      expectedArrive: new Date(),
      receiverWarehouseId: null,
      vendorId: null,
      purchaseOrderItems: [],
      canConfirm: false,
      canReceive: false,
      statusString: "",
      status: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Save(values);
    },
  });

  async function SetFormValue(v) {
    const result = await initial(v);
    formik.setValues(result);
  }

  const Columns = [
    { field: "id", type: "number", headerName: "id", hide: true },
    { field: "bomId", type: "number", headerName: "bomId", hide: true },
    {
      field: "productName",
      headerName: t("Manufactures.PurchaseOrderItem.Field.ProductName"),
      flex: 1,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "quantity",
      headerName: t("Manufactures.PurchaseOrderItem.Field.Quantity"),
      flex: 1,
      minWidth: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "quantityReceived",
      headerName: t("Manufactures.PurchaseOrderItem.Field.PurchaseOrderItemReceives"),
      flex: 1,
      minWidth: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
      hide: id == "New",
      renderCell: (params) => {
        if (params.row.id < 0) return t("Common.PleaseSaveBefore");
        if ([PurchaseOrderStatuses.Draft].indexOf(formik.values.status) > -1) return params.value;
        return (
          <ArazButton
            onClick={(event) => {
              event.stopPropagation();
              setItemReceives(params.row.purchaseOrderItemReceives);
              setPurchaseOrderItemId(params.id);
              setRemainQuantity(params.row.quantity - params.row.quantityReceived);
              setOpenPurchaseOrderItemReceiveList(true);
            }}
          >
            {params.value}
          </ArazButton>
        );
      },
    },

    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      minWidth: 100,
      getActions: (params) => {
        const actions = [];

        if (params.row.id > 0 && [PurchaseOrderStatuses.Draft, PurchaseOrderStatuses.Received].indexOf(params.row.status) == -1) {
          actions.push(
            <GridActionsCellItem
              icon={
                <Tooltip title={t("Commons.PartiallyReceive")}>
                  <RunningWithErrorsIcon color="warning" />
                </Tooltip>
              }
              label={t("Commons.PartiallyReceive")}
              onClick={() => {
                openItemReceivePartially({ purchaseOrderId: id, purchaseOrderItemId: params.id, id: 0, quantity: params.row.quantity - params.row.quantityReceived, receivedDate: new Date() });
              }}
            />
          );
        }

        actions.push(
          <GridActionsCellItem
            icon={
              <Tooltip title={t("Commons.Delete")}>
                <DeleteIcon />
              </Tooltip>
            }
            label={t("Commons.Delete")}
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(() => {
                  const newSate = formik.values.purchaseOrderItems.filter((v) => v.id != params.id);
                  formik.setFieldValue("purchaseOrderItems", newSate);
                })
                .catch(() => {});
            }}
          />
        );

        return actions;
      },
      align: "center",
      headerAlign: "center",
    },
  ];

  async function initial(v) {
    if (id === "New" && !v) {
      const nextCode = await GetNextCode({ navigate, dispatch, t });
      return {
        id: 0,
        code: nextCode.data,
        expectedArrive: new Date(),
        receiverWarehouseId: null,
        vendorId: null,
        purchaseOrderItems: [],
        canConfirm: false,
        canReceive: false,
      };
    } else {
      const detail = v ?? (await GetDetail({ navigate, dispatch, t }, EntityTypes.PurchaseOrder, id));
      return {
        id: id,
        code: detail.data.code,
        vendorId: { id: detail.data.vendor.id, name: detail.data.vendor.name },
        receiverWarehouseId: { id: detail.data.receiverWarehouse.id, name: detail.data.receiverWarehouse.name },
        expectedArrive: detail.data.expectedArrive,
        statusString: detail.data.statusString,
        status: detail.data.status,
        canConfirm: detail.data.canConfirm,
        canReceive: detail.data.canReceive,
        purchaseOrderItems: detail.data.purchaseOrderItems,
      };
    }
  }

  useEffect(() => {
    (async () => {
      const result = await initial();
      formik.setValues(result);
    })();
  }, [id]);

  const [item, setItem] = useState({ id: 0, product: null, quantity: 1, bomId: 0 });
  const [openItem, setOpenItem] = useState(false);
  async function OpenItem(v) {
    await setItem(v);
    setOpenItem(true);
  }

  const [openPurchaseOrderItemReceivePartially, setOpenPurchaseOrderItemReceivePartially] = useState(false);
  const [itemReceivePartially, setItemReceivePartially] = useState(false);
  function openItemReceivePartially(v) {
    setItemReceivePartially(v);
    setOpenPurchaseOrderItemReceivePartially(true);
  }

  const [openPurchaseOrderItemReceive, setOpenPurchaseOrderItemReceive] = useState(false);
  const [itemReceive, setItemReceive] = useState(false);
  function openItemReceive(v) {
    setItemReceive(v);
    setOpenPurchaseOrderItemReceive(true);
  }

  async function handleOnSaveItemReceivePartially(v) {
    const result = await initial(v);
    formik.setValues(result);
    const newItem = v.data.purchaseOrderItems.filter((x) => x.id == purchaseOrderItemId);
    if (newItem) setItemReceives(newItem[0].purchaseOrderItemReceives);
  }

  function handleOnSaveItem(item) {
    const existingProductIdx = formik.values.purchaseOrderItems.findIndex((v) => v.productId == item.product.id && v.id != item.id);
    if (existingProductIdx !== -1) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Product.Duplicate"), severity: "error", open: true } });
      return;
    }
    const existingId = formik.values.purchaseOrderItems.findIndex((v) => v.id == item.id);
    if (existingId === -1) {
      // product doesn't exist in the array
      formik.setFieldValue("purchaseOrderItems", [
        ...formik.values.purchaseOrderItems,
        { id: item.id, productId: item.product.id, productName: item.product.name, quantity: item.quantity, bomId: item.bomId },
      ]);
    } else {
      // product exists
      const newSate = [...formik.values.purchaseOrderItems]; // creates a copy of the current array
      newSate[existingId] = { ...newSate[existingId], id: item.id, productId: item.product.id, productName: item.product.name, quantity: item.quantity, bomId: item.bomId }; // set the position of the array to payload (or whatever should be the new value)
      formik.setFieldValue("purchaseOrderItems", newSate);
    }
  }

  async function Save(item) {
    formik.setStatus({ error: null });
    const data = {
      id: item.id,
      code: item.code,
      vendorId: item.vendorId.id,
      receiverWarehouseId: item.receiverWarehouseId.id,
      expectedArrive: item.expectedArrive,
      purchaseOrderItems: item.purchaseOrderItems,
    };
    const result = id == "New" ? await Insert({ navigate, dispatch, t }, EntityTypes.PurchaseOrder, data) : await Update({ navigate, dispatch, t }, EntityTypes.PurchaseOrder, data);
    if (result && result.isSuccess) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });

      if (id == "New") {
        navigate("/Purchases/PurchaseOrder/" + result.data.id);
        return;
      }
      const data = await initial(result);
      formik.setValues(data);
    } else formik.setStatus({ error: result.error });
  }

  return (
    <form>
      <ArazBox>
        {id > 0 && (
          <ArazContainer sx={{ p: 1 }}>
            <Stepper nonLinear activeStep={-1} sx={{ flexWrap: "wrap" }}>
              {[PurchaseOrderStatuses.Draft, PurchaseOrderStatuses.Confirm, PurchaseOrderStatuses.PartiallyReceived, PurchaseOrderStatuses.Received].map((label, index) => (
                <Step key={label} completed={false}>
                  <StepLabel icon={<CircleIcon color={formik.values.status == label ? "navbar" : "deactive"} />}>
                    <Typography variant="subtitle2" color={formik.values.status == label ? "navbar.main" : "deactive.main"}>
                      {t(`PurchaseOrderStatuses.${Object.keys(PurchaseOrderStatuses).find((key) => PurchaseOrderStatuses[key] === label)}`)}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </ArazContainer>
        )}

        <ArazContainer>
          <ArazGrid>
            <ArazGrid xs={12} justifyContent="space-between">
              <ArazGrid>
                <ArazGridItem>
                  <ArazSaveButton onClick={formik.handleSubmit} type="submit" />
                  <ArazButton
                    color="error"
                    variant="outlined"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      confirm({
                        confirmationButtonProps: { color: "error", variant: "contained" },
                        titleProps: { color: "error" },
                        title: t("Commons.Delete"),
                        description: t("Commons.Delete.confirm"),
                        confirmationText: t("Commons.Delete"),
                        cancellationText: t("Commons.Cancel"),
                      })
                        .then(async () => {
                          const result = await Delete({ navigate, dispatch, t }, EntityTypes.PurchaseOrder, id);
                          if (result.isSuccess) navigate("/Purchases/PurchaseOrder");
                          else formik.setStatus({ error: result.error });
                        })
                        .catch(() => {});
                    }}
                  >
                    {t("Commons.Delete")}
                  </ArazButton>
                </ArazGridItem>
                {formik.status && formik.status.error && (
                  <ArazGrid xs={12}>
                    <ArazGridItem>
                      {formik.status.error.map((e, i) => (
                        <Typography component="span" color="error" key={i}>
                          {e[Object.keys(formik.status.error[0])[0]]}
                        </Typography>
                      ))}
                    </ArazGridItem>
                  </ArazGrid>
                )}
              </ArazGrid>

              <ArazGrid>
                <ArazGridItem>
                  {formik.values.canConfirm && (
                    <ArazButton
                      color="warning"
                      startIcon={<CheckIcon />}
                      onClick={async () => {
                        const result = await Confirm({ navigate, dispatch, t }, id);
                        if (result && result.isSuccess) SetFormValue(result);
                        else formik.setStatus({ error: result.error });
                      }}
                    >
                      {t("Commons.Confirm")}
                    </ArazButton>
                  )}

                  {formik.values.canReceive && (
                    <ArazButton
                      color="warning"
                      onClick={async () => {
                        const result = await Received({ navigate, dispatch, t }, id);
                        if (result && result.isSuccess) SetFormValue(result);
                        else formik.setStatus({ error: result.error });
                      }}
                    >
                      {t("Commons.Receive")}
                    </ArazButton>
                  )}
                </ArazGridItem>
              </ArazGrid>
            </ArazGrid>

            <ArazGrid xs={12} md={6}>
              <ArazGridItem>
                <TextField
                  id="code"
                  name="code"
                  label={t("Manufactures.PurchaseOrder.Field.Code")}
                  variant="outlined"
                  fullWidth
                  size="medium"
                  value={formik.values.code}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("code", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <ArazGridItem sx={{ textAlign: "center" }}>
                <ArazDatePicker
                  id="expectedArrive"
                  name="expectedArrive"
                  value={formik.values.expectedArrive}
                  setValue={(v) => formik.setFieldValue("expectedArrive", v)}
                  label={t("Manufactures.PurchaseOrder.Field.ExpectedArrive")}
                  error={formik.touched.expectedArrive && Boolean(formik.errors.expectedArrive)}
                  helperText={formik.touched.expectedArrive && formik.errors.expectedArrive}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <ArazGridItem>
                <ArazPersonAutoComplete
                  id="vendorId"
                  name="vendorId"
                  value={formik.values.vendorId}
                  defaultValue={formik.values.vendorId}
                  label={t("Manufactures.PurchaseOrder.Field.VendorName")}
                  setValue={(v) => formik.setFieldValue("vendorId", v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.vendorId && Boolean(formik.errors.vendorId)}
                  helperText={formik.touched.vendorId && formik.errors.vendorId}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <ArazGridItem>
                <ArazWarehouseAutoComplete
                  id="receiverWarehouseId"
                  name="receiverWarehouseId"
                  value={formik.values.receiverWarehouseId}
                  defaultValue={formik.values.receiverWarehouseId}
                  label={t("Manufactures.PurchaseOrder.Field.ReceiverWarehouseName")}
                  setValue={(v) => formik.setFieldValue("receiverWarehouseId", v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.receiverWarehouseId && Boolean(formik.errors.receiverWarehouseId)}
                  helperText={formik.touched.receiverWarehouseId && formik.errors.receiverWarehouseId}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </ArazContainer>
        <ArazContainer>
          <ArazContainerHeader>{t("Manufactures.PurchaseOrder.Field.PurchaseOrderItems")}</ArazContainerHeader>
          <ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazAddButton
                  label={t("Commons.Insert")}
                  left
                  onClick={async () => await OpenItem({ id: Math.floor(Math.random() * -100000000 + 1), product: null, quantity: 1, bomId: 0 })}
                  sx={{ mt: 1 }}
                />
              </ArazGridItem>
              {formik.touched.purchaseOrderItems && Boolean(formik.errors.purchaseOrderItems) && (
                <ArazGridItem>
                  <Typography component="span" color="error">
                    {formik.touched.purchaseOrderItems && formik.errors.purchaseOrderItems}
                  </Typography>
                </ArazGridItem>
              )}
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazDataGrid
                  manual
                  rows={formik.values.purchaseOrderItems}
                  columns={Columns}
                  sortingMode="client"
                  paginationMode="client"
                  rowCount={formik.values.purchaseOrderItems.length}
                  onRowClick={async (param) => {
                    await OpenItem({
                      id: param.id,
                      product: { id: param.row.productId, name: param.row.productName, boms: [{ id: param.row.bomId }] },
                      quantity: param.row.quantity,
                      bomId: param.row.bomId,
                    });
                  }}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </ArazContainer>
      </ArazBox>
      {openItem && <PurchaseOrderItem open={openItem} setOpen={setOpenItem} value={item} onSave={handleOnSaveItem} />}
      {openPurchaseOrderItemReceivePartially && (
        <PurchaseOrderItemReceivePartially
          open={openPurchaseOrderItemReceivePartially}
          setOpen={setOpenPurchaseOrderItemReceivePartially}
          value={itemReceivePartially}
          onSave={handleOnSaveItemReceivePartially}
        />
      )}
      {openPurchaseOrderItemReceive && (
        <PurchaseOrderItemReceive open={openPurchaseOrderItemReceive} setOpen={setOpenPurchaseOrderItemReceive} value={itemReceive} onSave={handleOnSaveItemReceivePartially} />
      )}
      {openPurchaseOrderItemReceiveList && (
        <PurchaseOrderItemReceiveList
          open={openPurchaseOrderItemReceiveList}
          setOpen={setOpenPurchaseOrderItemReceiveList}
          purchaseOrderId={id}
          purchaseOrderItemId={purchaseOrderItemId}
          remainQuantity={remainQuantity}
          values={itemReceives}
          onSave={handleOnSaveItemReceivePartially}
        />
      )}
    </form>
  );
}
