import { Box } from "@mui/material";

const ArazBox = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        minHeight: 400,
        width: "100%",
        mt: {
          xs: 11,
          md: 13,
        },
        px: {
          xs: 0,
          md: 2,
        },
        borderBottom: "0px",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default ArazBox;
