import { Avatar, Button, DialogActions, DialogContent, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { useValue } from "context/ContextProvider";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { DropzoneArea } from "react-mui-dropzone";
import { useNavigate } from "react-router-dom";
import { UpdateProfile } from "Services/Security/UserService";
import * as yup from "yup";
import styles from "./Profile.module.css";
import { Insert as InsertPicture } from "Services/Common/PictureService";

export default function Profile({ open, setOpen }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { currentUser },
    dispatch,
  } = useValue();

  function close() {
    setOpen(false);
  }

  async function changePicture(file) {
    if (file.length == 0) return;
    const picResult = await InsertPicture({ navigate, dispatch, t }, file[0]);
    if (picResult.isSuccess) {
      formik.setFieldValue("pictureId", picResult.data.id);
      formik.setFieldValue("pictureUrl120", picResult.data.pictureThumbnailUrl);
    }
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required().label(t("Commons.User.Field.Name")),
  });

  const formik = useFormik({
    initialValues: {
      name: currentUser.name,
      password: currentUser.password,
      retypePassword: currentUser.retypePassword,
      pictureId: currentUser.pictureId,
      pictureUrl: currentUser.pictureUrl,
      pictureUrl120: currentUser.pictureUrl120,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.setStatus({ error: null });

      const costomer = await UpdateProfile(
        { navigate, dispatch, t },
        {
          name: values.name,
          password: values.password,
          retypePassword: values.retypePassword,
          pictureId: values.pictureId,
        }
      );

      if (costomer && costomer.isSuccess) {
        dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
        dispatch({ type: "UPDATE_USER", payload: costomer.data });

        close();
      } else formik.setStatus({ error: costomer.error });
    },
  });

  return (
    <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
      <ArazDialogTitle id="customized-dialog-title" onClose={close}>
        {t("Commons.Edit")}
        {t(" ")}
        {t("Profile")}
      </ArazDialogTitle>
      <DialogContent dividers>
        <ArazGrid xs={12}>
          <ArazGrid xs={12}>
            <ArazGridItem sx={{ display: "flex", justifyContent: "center" }}>
              <Avatar
                sx={{
                  borderColor: "navbar.main",
                  height: 120,
                  width: 120,
                  //cursor: "pointer",
                  borderStyle: "solid",
                  color: "navbar.main",
                }}
                // onClick={menuHandleClick}
              >
                {formik.values.pictureUrl120 && <img src={`${process.env.REACT_APP_BaseWWWAddress}${formik.values.pictureUrl120}`} />}
              </Avatar>
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem sx={{ display: "flex", justifyContent: "center" }}>
              <DropzoneArea
                filesLimit={1}
                dropzoneText={t("Commons.AddPicture")}
                showAlerts={false}
                acceptedFiles={["image/*"]}
                showPreviewsInDropzone={false}
                dropzoneClass={styles["drop-min-height"]}
                onChange={(file) => changePicture(file)}
              />
              {/* onChange={(file) => setFiles(file)} */}
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <TextField
                id="name"
                name="name"
                label={t("Commons.User.Field.Name")}
                fullWidth
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={async (event) => {
                  await formik.setFieldValue("name", event.target.value, true);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <TextField
                id="password"
                name="password"
                label={t("Commons.User.Field.Password")}
                fullWidth
                type="password"
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={async (event) => {
                  await formik.setFieldValue("password", event.target.value, true);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <TextField
                id="retypePassword"
                name="retypePassword"
                label={t("Commons.User.Field.RetypePassword")}
                fullWidth
                type="password"
                value={formik.values.retypePassword}
                onBlur={formik.handleBlur}
                onChange={async (event) => {
                  await formik.setFieldValue("retypePassword", event.target.value, true);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={formik.touched.retypePassword && Boolean(formik.errors.retypePassword)}
                helperText={formik.touched.retypePassword && formik.errors.retypePassword}
              />
            </ArazGridItem>
          </ArazGrid>

          {formik.status && formik.status.error && (
            <ArazGrid xs={12}>
              <ArazGridItem>
                {formik.status.error.map((e, i) => (
                  <Typography component="span" color="error" key={i}>
                    {e[Object.keys(formik.status.error[0])[0]]}
                  </Typography>
                ))}
              </ArazGridItem>
            </ArazGrid>
          )}
        </ArazGrid>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="text" autoFocus onClick={close}>
          {t("Commons.Cancel")}
        </Button>
        <Button autoFocus type="submit" color="success" variant="contained" onClick={formik.handleSubmit}>
          {t("Commons.Save")}
        </Button>
      </DialogActions>
    </ArazDialog>
  );
}
