import { useMemo, useState } from "react";
import ArazBox from "components/ArazComponents/ArazBox";
import ArazTabPanel, { ArazTabProps } from "components/ArazComponents/ArazTabPanel";
import ArazTabs from "components/ArazComponents/ArazTabs";
import ArazArchiveTab from "components/ArazComponents/ArazArchiveTab";
import ArazActiveTab from "components/ArazComponents/ArazActiveTab";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { Chip, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocalDateFormat } from "i18n/LocalDate";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { EntityTypes, PurchaseOrderStatuses } from "Services/Base/constants";
import { useConfirm } from "material-ui-confirm";
import { Delete, Insert } from "Services/Base/ApiBaseService";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import { boolean } from "yup/lib/locale";
import PaymentIcon from "@mui/icons-material/Payment";

export default function Invoices() {
  const { t } = useTranslation();
  const tabs = {
    inProgressTab: 0,
    doneTab: 1,
  };
  const confirm = useConfirm();
  const [tabValue, setTabValue] = useState(tabs.inProgressTab);
  const [trigger, setTrigger] = useState(false);
  const navigate = useNavigate();
  const {
    state: {language},
    dispatch,
  } = useValue();

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "totalPrice",
        headerName: t("Manufactures.CompanyInvoice.Field.TotalPrice"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        valueFormatter: (params) => {
          return `${params.value.toLocaleString()} ریال`;
        },
      },
      {
        field: "deadline",
        headerName: t("Manufactures.CompanyInvoice.Field.Deadline"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        valueFormatter: (params) => {
          return `${LocalDateFormat(params.value)}`;
        },
      },
      {
        field: "isPaid",
        headerName: t("Manufactures.CompanyInvoice.Field.IsPaid"),
        flex: 1,
        minWidth: 130,
        align: "center",
        headerAlign: "center",
        valueFormatter: (params) => {
          return params.value == true ? "پرداخت شده" : "پرداخت نشده";
        },
      },

      {
        field: "actions",
        type: "actions",
        flex: 1,
        minWidth: 100,
        hide: tabValue == tabs.doneTab,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip title={t("Commons.Pay")}>
                <PaymentIcon />
              </Tooltip>
            }
            label="Delete"
            color="success"
            onClick={() => {
              GoToBank(params.id);
            }}
          />,
        ],
        align: "center",
        headerAlign: "center",
      },
    ],
    [tabValue,language]
  );

  const filters = useMemo(() => {
    if (tabValue == tabs.inProgressTab) return [["isPaid", "=", false]];
    return [["isPaid", "=", true]];
  }, [tabValue,language]);

  function GoToBank(id) {
    confirm({
      confirmationButtonProps: { color: "success", variant: "contained" },
      titleProps: { color: "success" },
      title: t("Commons.Confirm"),
      description: t("Invoice.GoToBank"),
      confirmationText: t("Commons.Confirm"),
      cancellationText: t("Commons.Cancel"),
    })
      .then(async () => {
        const result = await Insert({ navigate, dispatch,t }, EntityTypes.Payment, { companyInvoiceId: id });
        if (result.isSuccess) window.location.href = result.data.paymentLink;
      })
      .catch(() => {});
  }
  return (
    <ArazBox>
      <ArazTabs
        value={tabValue}
        onChange={(event, newValue) => {
          setTabValue(newValue);
        }}
      >
        <ArazActiveTab label={t("Commons.Tabs.InProgress")} {...ArazTabProps(tabs.inProgressTab)} />
        <ArazArchiveTab label={t("Commons.Tabs.Done")} {...ArazTabProps(tabs.doneTab)} style={{ left: "20px" }} />
      </ArazTabs>

      <ArazTabPanel index={tabValue} value={tabValue}>
        <ArazDataGrid
          columns={columns}
          entityType={EntityTypes.Invoice}
          filters={filters}
          trigger={trigger}
          onRowClick={async (params) => {
            if (params.row.isPaid == true) return;
            GoToBank(params.id);
          }}
        />
      </ArazTabPanel>
    </ArazBox>
  );
}
