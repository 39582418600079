import { useMemo, useState } from "react";
import ArazBox from "components/ArazComponents/ArazBox";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { useTranslation } from "react-i18next";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { EntityTypes } from "Services/Base/constants";
import { useConfirm } from "material-ui-confirm";
import { Delete } from "Services/Base/ApiBaseService";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import ArazContainer from "components/ArazComponents/ArazContainer";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";

export default function Products() {
  const { t } = useTranslation();

  const confirm = useConfirm();
  const navigate = useNavigate();
  const {
    state: {language},
    dispatch,
  } = useValue();

  const [trigger, setTrigger] = useState(false);

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "name",
        headerName: t("Manufactures.Product.Field.Name"),
        flex: 1,
        minWidth: 200,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "productCategoryName",
        headerName: t("Manufactures.Product.Field.ProductCategoryName"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "isPurchasable",
        headerName: t("Manufactures.Product.Field.IsPurchasable"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        type: "boolean",
      },
      {
        field: "isProducible",
        headerName: t("Manufactures.Product.Field.IsProducible"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        type: "boolean",
      },
      {
        field: "isSaleable",
        headerName: t("Manufactures.Product.Field.IsSaleable"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        type: "boolean",
      },

      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        minWidth: 100,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(async () => {
                  const result = await Delete({ navigate, dispatch, t }, EntityTypes.Product, params.id);
                  if (result.isSuccess) setTrigger((trigger) => !trigger);
                })
                .catch(() => {});
            }}
          />,
        ],
        align: "center",
        headerAlign: "center",
      },
    ],
    [language]
  );

  return (
    <ArazBox>
      <ArazGrid sx={{ float: { md: "right" } }}>
        <ArazGrid xs={12}>
          <ArazAddButton label={t("Product.New")} />
        </ArazGrid>
      </ArazGrid>

      <ArazGrid xs={12}>
        <ArazGridItem>
          <ArazDataGrid columns={columns} entityType={EntityTypes.Product} trigger={trigger} />
        </ArazGridItem>
      </ArazGrid>
    </ArazBox>
  );
}
