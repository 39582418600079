import { useEffect, useMemo, useState } from "react";
import ArazBox from "components/ArazComponents/ArazBox";
import ArazOuterTabs from "components/ArazComponents/ArazOuterTabs";
import ArazTab from "components/ArazComponents/ArazTab";
import ArazTabPanel, { ArazTabProps } from "components/ArazComponents/ArazTabPanel";
import ArazTabs from "components/ArazComponents/ArazTabs";
import ArazArchiveTab from "components/ArazComponents/ArazArchiveTab";
import ArazActiveTab from "components/ArazComponents/ArazActiveTab";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { Badge, Button, Chip, Rating, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocalDateFormat } from "i18n/LocalDate";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { EntityTypes, SaleOrderStatuses, AvailabilityStatuses } from "Services/Base/constants";
import { Stack } from "@mui/system";
import { useConfirm } from "material-ui-confirm";
import { Delete } from "Services/Base/ApiBaseService";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";

export default function SaleOrderList() {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const {
    state: { language },
    dispatch,
  } = useValue();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryTab = searchParams.get("tab");

  const tabs = {
    active: 0,
    draft: 1,
    inProgress: 0,
    done: 1,
  };

  const [outerTabValue, setOuterTabValue] = useState(queryTab ? tabs[queryTab] : tabs.active);
  const [tabValue, setTabValue] = useState(tabs.inProgress);
  const [trigger, setTrigger] = useState(false);

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "code",
        headerName: t("Manufactures.SaleOrder.Field.Code"),
        flex: 1,
        minWidth: 100,
        description: "The identification used by the person with access to the online service.",
        align: "center",
        headerAlign: "center",
      },
      {
        field: "personName",
        headerName: t("Manufactures.SaleOrder.Field.PersonName"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "deadline",
        headerName: t("Manufactures.SaleOrder.Field.Deadline"),
        flex: 1,
        minWidth: 100,
        type: "date",
        // valueFormatter: (params) => {
        //   return `${LocalDateFormat(params.value)}`;
        // },
        align: "center",
        headerAlign: "center",
        renderCell: (params) =>
          outerTabValue == tabs.active && tabValue == tabs.inProgress && new Date(params.value).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) ? (
            <Typography variant="div" color="error">{`${LocalDateFormat(params.value)}`}</Typography>
          ) : (
            LocalDateFormat(params.value)
          ),
      },
      {
        field: "componentsLocationName",
        headerName: t("Manufactures.SaleOrder.Field.ComponentsLocationId"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "priority",
        headerName: t("Manufactures.SaleOrder.Field.Priority"),
        type: "number",
        flex: 1,
        minWidth: 130,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => <Rating name="half-rating-read" defaultValue={params.value} precision={0.5} readOnly />,
      },
      {
        field: "statusString",
        headerName: t("Manufactures.SaleOrder.Field.Status"),
        flex: 1,
        minWidth: 120,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (params) =>
          params.row.status == SaleOrderStatuses.Block || params.row.status == SaleOrderStatuses.Cancel ? (
            <Chip label={params.value} color="error" />
          ) : params.row.status == SaleOrderStatuses.Confirm ||
            params.row.status == SaleOrderStatuses.Pack ||
            params.row.status == SaleOrderStatuses.PartiallyPack ||
            params.row.status == SaleOrderStatuses.PartiallySent ? (
            <Chip label={params.value} color="warning" />
          ) : params.row.status == SaleOrderStatuses.Sent ? (
            <Chip label={params.value} color="success" />
          ) : (
            params.value
          ),
      },
      {
        field: "saleItemAvailabilityStatusString",
        headerName: t("Manufactures.SaleOrder.Field.SaleItemAvailabilityStatus"),
        flex: 1,
        minWidth: 180,
        sortable: false,
        align: "center",
        headerAlign: "center",
        hide: !(outerTabValue == tabs.active && tabValue == tabs.inProgress),
        renderCell: (params) => {
          const value = params.row.saleItemAvailabilityStatus == AvailabilityStatuses.Expected ? params.value + " " + LocalDateFormat(params.row.expectedDate) : params.value;
          return params.row.saleItemAvailabilityStatus == AvailabilityStatuses.NotAvailable ? (
            <Chip label={value} color="error" />
          ) : params.row.saleItemAvailabilityStatus == AvailabilityStatuses.InStock ? (
            <Chip label={value} color="success" />
          ) : (
            <Chip label={value} color="warning" />
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        minWidth: 100,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(async () => {
                  const result = await Delete({ navigate, dispatch, t }, EntityTypes.SaleOrder, params.id);
                  if (result.isSuccess) setTrigger((trigger) => !trigger);
                })
                .catch(() => {});
            }}
          />,
        ],
        align: "center",
        headerAlign: "center",
      },
    ],
    [outerTabValue, tabValue, language]
  );

  const filters = useMemo(() => {
    if (outerTabValue == tabs.active) {
      if (tabValue == tabs.inProgress)
        return [
          ["status", "=", SaleOrderStatuses.Confirm],
          "or",
          ["status", "=", SaleOrderStatuses.PartiallyPack],
          "or",
          ["status", "=", SaleOrderStatuses.Pack],
          "or",
          ["status", "=", SaleOrderStatuses.PartiallySent],
        ];
      else return [["status", "=", SaleOrderStatuses.Sent], "or", ["status", "=", SaleOrderStatuses.Block], "or", ["status", "=", SaleOrderStatuses.Cancel]];
    } else {
      return [["status", "=", SaleOrderStatuses.Draft]];
    }
  }, [outerTabValue, tabValue, language]);

  return (
    <ArazBox>
      <ArazGrid sx={{ float: { md: "right" } }}>
        <ArazGrid xs={12}>
          <ArazAddButton label={t("SaleOrders.New")} />
        </ArazGrid>
      </ArazGrid>

      <ArazOuterTabs
        value={outerTabValue}
        onChange={(event, newValue) => {
          setOuterTabValue(newValue);
          var key = Object.keys(tabs).filter(function (key) {
            return tabs[key] === newValue;
          })[0];
          setSearchParams(createSearchParams({ tab: key }));
        }}
      >
        <ArazTab label={t("Tabs.SalesOrder.Active")} {...ArazTabProps(tabs.active)} />
        <ArazTab label={t("Tabs.SalesOrder.Draft")} {...ArazTabProps(tabs.draft)} style={{ left: "20px" }} />
      </ArazOuterTabs>

      <ArazTabPanel value={outerTabValue} index={tabs.active}>
        <ArazTabs
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
        >
          <ArazActiveTab label={t("Commons.Tabs.InProgress")} {...ArazTabProps(tabs.inProgress)} />
          <ArazArchiveTab label={t("Commons.Tabs.Done")} {...ArazTabProps(tabs.done)} style={{ left: "20px" }} />
        </ArazTabs>

        <ArazDataGrid columns={columns} entityType={EntityTypes.SaleOrder} filters={filters} trigger={trigger} />
      </ArazTabPanel>
      <ArazTabPanel value={outerTabValue} index={tabs.draft}>
        <ArazDataGrid columns={columns} entityType={EntityTypes.SaleOrder} filters={filters} trigger={trigger} />
      </ArazTabPanel>
    </ArazBox>
  );
}
