import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import ArazContainer from "components/ArazComponents/ArazContainer";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import ArazSaveButton from "components/ArazComponents/ArazSaveButton";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useConfirm } from "material-ui-confirm";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import { Insert, GetDetail, Update, Delete } from "Services/Base/ApiBaseService";
import { EntityTypes, ProductTypes } from "Services/Base/constants";
import ArazButton from "components/ArazComponents/ArazButton";
import ArazImageList from "components/ArazComponents/ArazImageList";
import ArazUnitOfMeasureAutoComplete from "components/ArazComponents/ArazUnitOfMeasureAutoComplete";
import { DropzoneArea } from "react-mui-dropzone";
import { useState } from "react";
import { Insert as InsertPicture } from "Services/Common/PictureService";
import ArazProductCategoryAutoComplete from "components/ArazComponents/ArazProductCategoryAutoComplete";
export default function ProductInfo({ id }) {
  const { t } = useTranslation();

  const confirm = useConfirm();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({
    name: yup.string().required().label(t("Manufactures.Product.Field.Name")),
    width: yup.number().required().min(0).label(t("Manufactures.Product.Field.Width")),
    length: yup.number().required().min(0).label(t("Manufactures.Product.Field.Length")),
    height: yup.number().required().min(0).label(t("Manufactures.Product.Field.Height")),
    weight: yup.number().required().min(0).label(t("Manufactures.Product.Field.Weight")),
    unitOfMeasureId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.Bom.Field.UnitOfMeasureId")),

    purchaseUnitOfMeasureId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.Bom.Field.PurchaseUnitOfMeasureId")),

    productCategoryId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.Product.Field.ProductCategoryName")),
  });

  const formik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      isPurchasable: false,
      isProducible: true,
      isSaleable: true,
      //isRawMaterial,
      width: 1,
      length: 1,
      height: 1,
      weight: 1,
      productType: ProductTypes.StorableProduct,
      productCategoryId: null,
      description: "",
      productPictures: [],
      productSpecificationValues: [],
      unitOfMeasureId: null,
      purchaseUnitOfMeasureId: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Save(values);
    },
  });

  async function SetFormValue(v) {
    const result = await initial(v);
    formik.setValues(result);
  }

  async function initial(v) {
    if (id === "New" && !v) {
      return {
        id: 0,
        name: "",
        isPurchasable: false,
        isProducible: true,
        isSaleable: true,
        width: 1,
        length: 1,
        height: 1,
        weight: 1,
        productType: ProductTypes.StorableProduct,
        productCategoryId: null,
        description: "",
        productPictures: [],
        productSpecificationValues: [],
        unitOfMeasureId: null,
        purchaseUnitOfMeasureId: null,
      };
    } else {
      const detail = v ?? (await GetDetail({ navigate, dispatch, t }, EntityTypes.Product, id));
      return {
        id: id,
        name: detail.data.name,
        isPurchasable: detail.data.isPurchasable,
        isProducible: detail.data.isProducible,
        isSaleable: detail.data.isSaleable,
        width: detail.data.width,
        length: detail.data.length,
        height: detail.data.height,
        weight: detail.data.weight,
        productType: detail.data.productType,
        productCategoryId: { id: detail.data.productCategory.id, name: detail.data.productCategory.name },
        description: detail.data.description,
        productPictures: detail.data.productPictures,
        productSpecificationValues: detail.data.productSpecificationValues,
        unitOfMeasureId: { id: detail.data.unitOfMeasure.id, name: detail.data.unitOfMeasure.name },
        purchaseUnitOfMeasureId: { id: detail.data.purchaseUnitOfMeasure.id, name: detail.data.purchaseUnitOfMeasure.name },
      };
    }
  }
  useEffect(() => {
    (async () => {
      const result = await initial();
      formik.setValues(result);
    })();
  }, [id]);

  function onDeletePicture(id) {
    const pics = formik.values.productPictures.filter((p) => p.id != id);
    formik.setFieldValue("productPictures", pics, true);
  }

  async function Save(item) {
    formik.setStatus({ error: null });
    const newPictures = [];
    for (const file of files) {
      const picResult = await InsertPicture({ navigate, dispatch, t }, file);
      if (picResult.isSuccess) {
        newPictures.push({ pictureId: picResult.data.id });
      }
    }
    const pictures = [...item.productPictures, ...newPictures];
    const data = {
      id: item.id,
      name: item.name,
      isPurchasable: item.isPurchasable,
      isProducible: item.isProducible,
      isSaleable: item.isSaleable,
      width: item.width,
      length: item.length,
      height: item.height,
      weight: item.weight,
      productType: item.productType,
      productCategoryId: item.productCategoryId.id,
      description: item.description,
      productPictures: pictures,
      productSpecificationValues: item.productSpecificationValues,
      unitOfMeasureId: item.unitOfMeasureId.id,
      purchaseUnitOfMeasureId: item.purchaseUnitOfMeasureId.id,
    };

    const result = id == "New" ? await Insert({ navigate, dispatch, t }, EntityTypes.Product, data) : await Update({ navigate, dispatch, t }, EntityTypes.Product, data);
    if (result && result.isSuccess) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
      if (id == "New") navigate("/Manufactures/Product/" + result.data.id);
    } else formik.setStatus({ error: result.error });
  }

  return (
    <form>
      <ArazContainer sx={{ px: 0 }}>
        <ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazSaveButton onClick={formik.handleSubmit} />
              <ArazButton
                color="error"
                variant="outlined"
                sx={{ ml: 1 }}
                onClick={() => {
                  confirm({
                    confirmationButtonProps: { color: "error", variant: "contained" },
                    titleProps: { color: "error" },
                    title: t("Commons.Delete"),
                    description: t("Commons.Delete.confirm"),
                    confirmationText: t("Commons.Delete"),
                    cancellationText: t("Commons.Cancel"),
                  })
                    .then(async () => {
                      const result = await Delete({ navigate, dispatch, t }, EntityTypes.Product, id);
                      if (result.isSuccess) navigate("/Manufactures/Product");
                      else formik.setStatus({ error: result.error });
                    })
                    .catch(() => {});
                }}
              >
                {t("Commons.Delete")}
              </ArazButton>
            </ArazGridItem>
            {formik.status && formik.status.error && (
                <ArazGrid xs={12}>
                  <ArazGridItem>
                    {formik.status.error.map((e, i) => (
                      <Typography component="span" color="error" key={i}>
                        {e[Object.keys(formik.status.error[0])[0]]}
                      </Typography>
                    ))}
                  </ArazGridItem>
                </ArazGrid>
              )}
          </ArazGrid>
          <ArazGrid xs={12} md={6}>
            <ArazGridItem>
              <TextField
                id="name"
                name="name"
                label={t("Manufactures.Product.Field.Name")}
                variant="outlined"
                fullWidth
                size="medium"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={async (event) => {
                  await formik.setFieldValue("name", event.target.value, true);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12} md={6}>
            <ArazGridItem>
              <ArazGrid xs={12} md={12}>
                <ArazGrid xs={4} md={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="isPurchasable"
                        name="isPurchasable"
                        checked={formik.values.isPurchasable}
                        onChange={async (event) => {
                          await formik.setFieldValue("isPurchasable", event.target.checked, true);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={t("Manufactures.Product.Field.IsPurchasable")}
                  />
                </ArazGrid>
                <ArazGrid xs={4} md={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="isProducible"
                        name="isProducible"
                        checked={formik.values.isProducible}
                        onChange={async (event) => {
                          await formik.setFieldValue("isProducible", event.target.checked, true);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={t("Manufactures.Product.Field.IsProducible")}
                  />
                </ArazGrid>
                <ArazGrid xs={4} md={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="isSaleable"
                        name="isSaleable"
                        checked={formik.values.isSaleable}
                        onChange={async (event) => {
                          await formik.setFieldValue("isSaleable", event.target.checked, true);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={t("Manufactures.Product.Field.IsSaleable")}
                  />
                </ArazGrid>
              </ArazGrid>
            </ArazGridItem>
          </ArazGrid>

          <ArazGrid xs={12} md={4}>
            <ArazGridItem>
              <ArazProductCategoryAutoComplete
                id="productCategoryId"
                name="productCategoryId"
                value={formik.values.productCategoryId}
                defaultValue={formik.values.productCategoryId}
                label={t("Manufactures.Product.Field.ProductCategoryName")}
                setValue={(v) => formik.setFieldValue("productCategoryId", v)}
                onBlur={formik.handleBlur}
                error={formik.touched.productCategoryId && Boolean(formik.errors.productCategoryId)}
                helperText={formik.touched.productCategoryId && formik.errors.productCategoryId}
              />
            </ArazGridItem>
          </ArazGrid>

          <ArazGrid xs={12} md={4}>
            <ArazGridItem>
              <ArazUnitOfMeasureAutoComplete
                id="unitOfMeasureId"
                name="unitOfMeasureId"
                value={formik.values.unitOfMeasureId}
                defaultValue={formik.values.unitOfMeasureId}
                label={t("Manufactures.Bom.Field.UnitOfMeasureId")}
                setValue={(v) => formik.setFieldValue("unitOfMeasureId", v)}
                onBlur={formik.handleBlur}
                error={formik.touched.unitOfMeasureId && Boolean(formik.errors.unitOfMeasureId)}
                helperText={formik.touched.unitOfMeasureId && formik.errors.unitOfMeasureId}
              />
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12} md={4}>
            <ArazGridItem>
              <ArazUnitOfMeasureAutoComplete
                id="purchaseUnitOfMeasureId"
                name="purchaseUnitOfMeasureId"
                value={formik.values.purchaseUnitOfMeasureId}
                defaultValue={formik.values.purchaseUnitOfMeasureId}
                label={t("Manufactures.Bom.Field.PurchaseUnitOfMeasureId")}
                setValue={(v) => formik.setFieldValue("purchaseUnitOfMeasureId", v)}
                onBlur={formik.handleBlur}
                error={formik.touched.purchaseUnitOfMeasureId && Boolean(formik.errors.purchaseUnitOfMeasureId)}
                helperText={formik.touched.purchaseUnitOfMeasureId && formik.errors.purchaseUnitOfMeasureId}
              />
            </ArazGridItem>
          </ArazGrid>

          <ArazGrid xs={6} md={3}>
            <ArazGridItem>
              <TextField
                id="width"
                name="width"
                label={t("Manufactures.Product.Field.Width")}
                type="number"
                variant="outlined"
                fullWidth
                size="medium"
                value={formik.values.width}
                onBlur={formik.handleBlur}
                onChange={async (event) => {
                  await formik.setFieldValue("width", event.target.value, true);
                }}
                error={formik.touched.width && Boolean(formik.errors.width)}
                helperText={formik.touched.width && formik.errors.width}
                sx={{
                  margin: "auto",
                  px: 2,
                  "& .MuiInputLabel-root": {
                    left: 16,
                  },
                }}
              />
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={6} md={3}>
            <ArazGridItem>
              <TextField
                id="length"
                name="length"
                label={t("Manufactures.Product.Field.Length")}
                type="number"
                variant="outlined"
                fullWidth
                size="medium"
                value={formik.values.length}
                onBlur={formik.handleBlur}
                onChange={async (event) => {
                  await formik.setFieldValue("length", event.target.value, true);
                }}
                error={formik.touched.length && Boolean(formik.errors.length)}
                helperText={formik.touched.length && formik.errors.length}
                sx={{
                  margin: "auto",
                  px: 2,
                  "& .MuiInputLabel-root": {
                    left: 16,
                  },
                }}
              />
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={6} md={3}>
            <ArazGridItem>
              <TextField
                id="height"
                name="height"
                label={t("Manufactures.Product.Field.Height")}
                type="number"
                variant="outlined"
                fullWidth
                size="medium"
                value={formik.values.height}
                onBlur={formik.handleBlur}
                onChange={async (event) => {
                  await formik.setFieldValue("height", event.target.value, true);
                }}
                error={formik.touched.height && Boolean(formik.errors.height)}
                helperText={formik.touched.height && formik.errors.height}
                sx={{
                  margin: "auto",
                  px: 2,
                  "& .MuiInputLabel-root": {
                    left: 16,
                  },
                }}
              />
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={6} md={3}>
            <ArazGridItem>
              <TextField
                id="weight"
                name="weight"
                label={t("Manufactures.Product.Field.Weight")}
                type="number"
                variant="outlined"
                fullWidth
                size="medium"
                value={formik.values.weight}
                onBlur={formik.handleBlur}
                onChange={async (event) => {
                  await formik.setFieldValue("weight", event.target.value, true);
                }}
                error={formik.touched.weight && Boolean(formik.errors.weight)}
                helperText={formik.touched.weight && formik.errors.weight}
                sx={{
                  margin: "auto",
                  px: 2,
                  "& .MuiInputLabel-root": {
                    left: 16,
                  },
                }}
              />
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12} md={12}>
            <ArazGridItem>
              <TextField
                id="description"
                name="description"
                label={t("Manufactures.Product.Field.Description")}
                type="number"
                variant="outlined"
                fullWidth
                size="medium"
                value={formik.values.description}
                onBlur={formik.handleBlur}
                onChange={async (event) => {
                  await formik.setFieldValue("description", event.target.value, true);
                }}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                sx={{
                  margin: "auto",
                  px: 2,
                  "& .MuiInputLabel-root": {
                    left: 16,
                  },
                }}
                multiline
                rows={4}
              />
            </ArazGridItem>
          </ArazGrid>
        </ArazGrid>
      </ArazContainer>
      <ArazContainer>
        <ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazImageList items={formik.values.productPictures} onDelete={onDeletePicture} />
            </ArazGridItem>
          </ArazGrid>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <DropzoneArea dropzoneText={t("Commons.AddPicture")} showAlerts={false} acceptedFiles={["image/*"]} onChange={(file) => setFiles(file)} />
            </ArazGridItem>
          </ArazGrid>
        </ArazGrid>
      </ArazContainer>
    </form>
  );
}
