import { Tabs } from "@mui/material";

const ArazOuterTabs = ({ children, sx, ...props }) => {
  return (
    <Tabs
      {...props}
      TabIndicatorProps={{
        sx: {
          backgroundColor: "tab.main",
        },
      }}
      sx={{
        ml: 3,
        "& .MuiButtonBase-root": {
          borderRadius: "17px 17px 0px 0px",
          bgcolor: "common.white",
          color: "navbar.main",
          minWidth: "125px",
          zIndex: 0,
          minWidth: "160px",
          boxShadow: "0px 10px 96px rgba(0, 0, 0, 0.08)",
        },
        "& .MuiButtonBase-root.Mui-selected": {
          bgcolor: "navbar.main",
          color: "common.white",
          borderRadius: "17px 17px 0px 0px",
          zIndex: 1,
        },
        ...sx,
      }}
    >
      {children}
    </Tabs>
  );
};

export default ArazOuterTabs;
