import { useValue } from "context/ContextProvider";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GetMenuItems } from "Services/Security/UserService";

export default function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { currentUser },
    dispatch,
  } = useValue();

  useEffect(() => {
    if (currentUser) {
      const menuList = GetMenuItems({ navigate, t });
      navigate(menuList[0].link);
    } else {
      navigate("/Login");
    }
  }, []);

  return <></>;
}
