import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { TextField, Typography } from "@mui/material";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import { useFormik } from "formik";
import * as yup from "yup";
import { EntityTypes, ProductTypes } from "Services/Base/constants";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { Insert, Update } from "Services/Base/ApiBaseService";
import ArazProductCategoryAutoComplete from "./ArazProductCategoryAutoComplete";
import ArazUnitOfMeasureAutoComplete from "./ArazUnitOfMeasureAutoComplete";

export default function ArazQuickProductInsert({ open, setOpen, value, onSave }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({
    name: yup.string().required().label(t("Manufactures.Product.Field.Name")),

    unitOfMeasureId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.Bom.Field.UnitOfMeasureId")),

    purchaseUnitOfMeasureId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.Bom.Field.PurchaseUnitOfMeasureId")),

    productCategoryId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.Product.Field.ProductCategoryName")),
  });

  const formik = useFormik({
    initialValues: {
      id: 0,
      name: value.name,
      isPurchasable: true,
      isProducible: true,
      isSaleable: true,
      width: 1,
      length: 1,
      height: 1,
      weight: 1,
      productType: ProductTypes.StorableProduct,
      productCategoryId: null,
      description: "",
      productPictures: [],
      productSpecificationValues: [],
      unitOfMeasureId: null,
      purchaseUnitOfMeasureId: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Save(values);
    },
  });

  async function Save(item) {
    formik.setStatus({ error: null });

    const data = {
      id: item.id,
      name: item.name,
      isPurchasable: item.isPurchasable,
      isProducible: item.isProducible,
      isSaleable: item.isSaleable,
      width: item.width,
      length: item.length,
      height: item.height,
      weight: item.weight,
      productType: item.productType,
      productCategoryId: item.productCategoryId.id,
      description: item.description,
      productSpecificationValues: item.productSpecificationValues,
      unitOfMeasureId: item.unitOfMeasureId.id,
      purchaseUnitOfMeasureId: item.purchaseUnitOfMeasureId.id,
    };

    const result = await Insert({ navigate, dispatch, t }, EntityTypes.Product, data);
    if (result && result.isSuccess) {
      onSave(result.data);
      close();
    } else formik.setStatus({ error: result.error });
  }

  function close() {
    setOpen(false);
  }
  return (
    <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
      <form>
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {value.id == 0 ? t("Commons.New") : t("Commons.Edit")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="name"
                  name="name"
                  label={t("Manufactures.Product.Field.Name")}
                  variant="outlined"
                  fullWidth
                  size="medium"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("name", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </ArazGridItem>
            </ArazGrid>

            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazProductCategoryAutoComplete
                  id="productCategoryId"
                  name="productCategoryId"
                  value={formik.values.productCategoryId}
                  defaultValue={formik.values.productCategoryId}
                  label={t("Manufactures.Product.Field.ProductCategoryName")}
                  setValue={(v) => formik.setFieldValue("productCategoryId", v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.productCategoryId && Boolean(formik.errors.productCategoryId)}
                  helperText={formik.touched.productCategoryId && formik.errors.productCategoryId}
                />
              </ArazGridItem>
            </ArazGrid>

            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazUnitOfMeasureAutoComplete
                  id="unitOfMeasureId"
                  name="unitOfMeasureId"
                  value={formik.values.unitOfMeasureId}
                  defaultValue={formik.values.unitOfMeasureId}
                  label={t("Manufactures.Bom.Field.UnitOfMeasureId")}
                  setValue={(v) => formik.setFieldValue("unitOfMeasureId", v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.unitOfMeasureId && Boolean(formik.errors.unitOfMeasureId)}
                  helperText={formik.touched.unitOfMeasureId && formik.errors.unitOfMeasureId}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazUnitOfMeasureAutoComplete
                  id="purchaseUnitOfMeasureId"
                  name="purchaseUnitOfMeasureId"
                  value={formik.values.purchaseUnitOfMeasureId}
                  defaultValue={formik.values.purchaseUnitOfMeasureId}
                  label={t("Manufactures.Bom.Field.PurchaseUnitOfMeasureId")}
                  setValue={(v) => formik.setFieldValue("purchaseUnitOfMeasureId", v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.purchaseUnitOfMeasureId && Boolean(formik.errors.purchaseUnitOfMeasureId)}
                  helperText={formik.touched.purchaseUnitOfMeasureId && formik.errors.purchaseUnitOfMeasureId}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </DialogContent>
        <DialogActions>
          {formik.status &&
            formik.status.error &&
            formik.status.error.map((e, i) => (
              <Typography component="span" color="error" key={i}>
                {e[Object.keys(formik.status.error[0])[0]]}
              </Typography>
            ))}
          <Button color="error" variant="text" autoFocus onClick={close}>
            {t("Commons.Cancel")}
          </Button>
          <Button autoFocus type="submit" color="success" variant="contained" onClick={formik.handleSubmit}>
            {t("Commons.Save")}
          </Button>
        </DialogActions>
      </form>
    </ArazDialog>
  );
}
