import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import ArazProductAutoComplete from "components/ArazComponents/ArazProductAutoComplete";
import { TextField } from "@mui/material";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";

export default function ComponentItem({ open, setOpen, value, onSave }) {
  const { t } = useTranslation();
  const validationSchema = yup.object().shape({
    quantity: yup.number().required().positive().label(t("Manufactures.SaleOrderItem.Field.Quantity")),
    product: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.SaleOrderItem.Field.ProductName")),
  });

  useEffect(() => {
    formik.resetForm();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: value.id,
      product: value.product,
      quantity: value.quantity,
      componentId: value.componentId,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSave(values);
      close();
    },
  });

  function setProduct(p) {
    formik.setFieldValue("product", p);
    if (p?.boms) formik.setFieldValue("componentId", p.boms[0]?.id);
    else formik.setFieldValue("componentId", 0);
  }
  function close() {
    setOpen(false);
  }
  return (
    <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
      <form>
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {t("Commons.New")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid xs={12}>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazProductAutoComplete
                  id="product"
                  name="product"
                  value={formik.values.product}
                  defaultValue={formik.values.product}
                  setValue={(p) => setProduct(p)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.product && Boolean(formik.errors.product)}
                  helperText={formik.touched.product && formik.errors.product}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="quantity"
                  name="quantity"
                  label={t("Manufactures.SaleOrderItem.Field.Quantity")}
                  type="number"
                  fullWidth
                  value={formik.values.quantity}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("quantity", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                  helperText={formik.touched.quantity && formik.errors.quantity}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="text" autoFocus onClick={close}>
            {t("Commons.Cancel")}
          </Button>
          <Button autoFocus type="submit" color="success" variant="contained" onClick={formik.handleSubmit}>
            {t("Commons.Save")}
          </Button>
        </DialogActions>
      </form>
    </ArazDialog>
  );
}
