import ArazBox from "components/ArazComponents/ArazBox";
import { useTranslation } from "react-i18next";
import { Chip, Rating, Step, StepLabel, Stepper, TextField, Tooltip, Typography } from "@mui/material";
import ArazContainer from "components/ArazComponents/ArazContainer";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import ArazSaveButton from "components/ArazComponents/ArazSaveButton";
import ArazDatePicker from "components/ArazComponents/ArazDatePicker";
import { useState } from "react";
import ArazWarehouseAutoComplete from "components/ArazComponents/ArazWarehouseAutoComplete";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useConfirm } from "material-ui-confirm";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import { insert, useFormik } from "formik";
import * as yup from "yup";
import { Confirm, Done, GetNextCode, InProgress, Pause } from "Services/ManufactureOrders/ManufactureOrderService";
import { useEffect } from "react";
import { Insert, GetDetail, Update, Delete } from "Services/Base/ApiBaseService";
import { AvailabilityStatuses, EntityTypes, ManufacturingOrderSourceTypes, ManufacturingOrderStatusTypes, SaleOrderStatuses } from "Services/Base/constants";
import ArazButton from "components/ArazComponents/ArazButton";
import { LocalDateFormat } from "i18n/LocalDate";
import ArazProductAutoComplete from "components/ArazComponents/ArazProductAutoComplete";
import ComponentItem from "./ComponentItem";
import WorksItem from "./WorksItem";
import WarehouseBomLedger from "components/Ledger/WarehouseBomLedger";
import CircleIcon from "@mui/icons-material/Circle";
import * as moment from "moment";
import { FormatTimeStamp } from "Helper/DateTimeHelper";
import PauseIcon from "@mui/icons-material/Pause";
import CheckIcon from "@mui/icons-material/Check";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ArazContainerHeader from "components/ArazComponents/ArazContainerHeader";

export default function ManufactureOrder() {
  const { t } = useTranslation();
  let { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({
    code: yup.string().required().label(t("Manufactures.ManufacturingOrder.Field.Code")),
    deadline: yup.date().required().label(t("Manufactures.ManufacturingOrder.Field.Deadline")),
    priority: yup.number().required().positive().label(t("Manufactures.ManufacturingOrder.Field.Priority")),
    bomId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.SaleOrderItem.Field.ProductName")),
    quantity: yup.number().required().positive().label(t("Manufactures.ManufacturingOrder.Field.Quantity")),
    componentsLocationId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.ManufacturingOrder.Field.ComponentsLocationId")),
    finishedProductsLocationId: yup
      .object()
      .shape({
        id: yup.number().required().positive(),
      })
      .nullable()
      .required()
      .label(t("Manufactures.ManufacturingOrder.Field.FinishedProductsLocationId")),
    manufacturingOrderComponents: yup.array().nullable().min(1).required().label(" "),
  });

  const formik = useFormik({
    initialValues: {
      id: 0,
      code: "",
      deadline: new Date(),
      priority: 3,
      quantity: 1,
      bomId: null,
      componentsLocationId: null,
      finishedProductsLocationId: null,
      manufacturingOrderComponents: [],
      manufacturingWorkOrders: [],
      canConfirm: false,
      canDone: false,
      canInProgress: false,
      canPause: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Save(values);
    },
  });

  async function SetFormValue(v) {
    const result = await initial(v);
    formik.setValues(result);
  }

  const orderComponentsColumns = [
    { field: "id", type: "number", headerName: "id", hide: true },
    { field: "bomId", type: "number", headerName: "bomId", hide: true },
    {
      field: "productName",
      headerName: t("Manufactures.ManufacturingOrderComponent.Field.ProductName"),
      flex: 1,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "quantity",
      headerName: t("Manufactures.ManufacturingOrderComponent.Field.Quantity"),
      flex: 1,
      minWidth: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },

    {
      field: "availabilityStatusesString",
      headerName: t("Manufactures.ManufacturingOrderComponent.Field.AvailabilityStatus"),
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      hide: id == "New",
      renderCell: (params) => {
        if (params.row.id < 0) return "";
        if ([ManufacturingOrderStatusTypes.Confirmed, ManufacturingOrderStatusTypes.InProgress, ManufacturingOrderStatusTypes.Paused].indexOf(formik.values.status) != -1) {
          const value = params.row.availabilityStatus == AvailabilityStatuses.Expected ? params.value + " " + LocalDateFormat(params.row.expectedDate) : params.value;
          return params.row.availabilityStatus == AvailabilityStatuses.NotAvailable ? (
            <Chip
              label={value}
              color="error"
              onClick={(event) => {
                event.stopPropagation();
                OpenLedger(params.row.bomId);
              }}
            />
          ) : params.row.availabilityStatus == AvailabilityStatuses.InStock ? (
            <Chip
              label={value}
              color="success"
              onClick={(event) => {
                event.stopPropagation();
                OpenLedger(params.row.bomId);
              }}
            />
          ) : (
            <Chip
              label={value}
              color="warning"
              onClick={(event) => {
                event.stopPropagation();
                OpenLedger(params.row.bomId);
              }}
            />
          );
        } else {
          return <Typography variant="caption">{t("DotNotShowAvailabilityStatus", { status: formik.values.statusString })}</Typography>;
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      minWidth: 100,
      getActions: (params) => {
        const actions = [];

        actions.push(
          <GridActionsCellItem
            icon={
              <Tooltip title={t("Commons.Delete")}>
                <DeleteIcon />
              </Tooltip>
            }
            label={t("Commons.Delete")}
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(() => {
                  const newSate = formik.values.manufacturingOrderComponents.filter((v) => v.id != params.id);
                  formik.setFieldValue("manufacturingOrderComponents", newSate);
                })
                .catch(() => {});
            }}
          />
        );

        return actions;
      },
      align: "center",
      headerAlign: "center",
    },
  ];

  const workOrdersColumns = [
    { field: "id", type: "number", headerName: "id", hide: true },
    {
      field: "processName",
      headerName: t("Manufactures.ManufacturingWorkOrder.Field.ProcessName"),
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workCenterName",
      headerName: t("Manufactures.ManufacturingWorkOrder.Field.WorkCenterName"),
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "expectedDuration",
      headerName: t("Manufactures.ManufacturingWorkOrder.Field.ExpectedDuration"),
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "sequence",
      headerName: t("Manufactures.BomProcess.Field.Sequence"),
      flex: 1,
      type: "number",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      minWidth: 100,
      getActions: (params) => {
        const actions = [];

        actions.push(
          <GridActionsCellItem
            icon={
              <Tooltip title={t("Commons.Delete")}>
                <DeleteIcon />
              </Tooltip>
            }
            label={t("Commons.Delete")}
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(() => {
                  const newSate = formik.values.manufacturingWorkOrders.filter((v) => v.id != params.id);
                  formik.setFieldValue("manufacturingWorkOrders", newSate);
                })
                .catch(() => {});
            }}
          />
        );

        return actions;
      },
      align: "center",
      headerAlign: "center",
    },
  ];

  async function initial(v) {
    if (id === "New" && !v) {
      const nextCode = await GetNextCode({ navigate, dispatch, t });
      return {
        id: 0,
        code: nextCode.data,
        deadline: new Date(),
        priority: 3,
        quantity: 1,
        bomId: null,
        componentsLocationId: null,
        finishedProductsLocationId: null,
        manufacturingOrderComponents: [],
        manufacturingWorkOrders: [],
        canConfirm: false,
        canDone: false,
        canInProgress: false,
        canPause: false,
      };
    } else {
      const detail = v ?? (await GetDetail({ navigate, dispatch, t }, EntityTypes.ManufacturingOrder, id));
      return {
        id: id,
        code: detail.data.code,
        deadline: new Date(detail.data.deadline),
        priority: detail.data.priority,
        quantity: detail.data.quantity,
        bomId: { id: detail.data.bom.productId, name: detail.data.bom.productName, boms: [{ id: detail.data.bomId }] },
        componentsLocationId: { id: detail.data.componentsLocation.id, name: detail.data.componentsLocation.name },
        finishedProductsLocationId: { id: detail.data.finishedProductsLocation.id, name: detail.data.finishedProductsLocation.name },
        statusString: detail.data.statusString,
        status: detail.data.status,
        manufacturingOrderComponents: detail.data.manufacturingOrderComponents,
        manufacturingWorkOrders: detail.data.manufacturingWorkOrders,
        canConfirm: detail.data.canConfirm,
        canDone: detail.data.canDone,
        canInProgress: detail.data.canInProgress,
        canPause: detail.data.canPause,
      };
    }
  }
  useEffect(() => {
    (async () => {
      const result = await initial();
      formik.setValues(result);
    })();
  }, [id]);

  const [openLedger, setOpenLedger] = useState(false);
  const [ledgerBomId, setLedgerBomId] = useState(0);
  function OpenLedger(bomId) {
    setLedgerBomId(bomId);
    setOpenLedger(true);
  }

  async function handleProductChange(v) {
    formik.setFieldValue("bomId", v);

    if (v && id == "New") {
      formik.setFieldValue("manufacturingOrderComponents", []);
      formik.setFieldValue("manufacturingWorkOrders", []);
      formik.setFieldValue("quantity", 1);
      const detail = await GetDetail({ navigate, dispatch, t }, EntityTypes.BOM, v.id);
      if (detail && detail.isSuccess) {
        const component = detail.data.components.map((m) => {
          return {
            id: Math.floor(Math.random() * -100000000 + 1),
            productName: m.productName,
            productId: m.productId,
            quantity: m.quantity,
            bomId: m.componentId,
          };
        });
        formik.setFieldValue("manufacturingOrderComponents", component);

        const work = detail.data.bomProcesses.map((m) => {
          return {
            id: Math.floor(Math.random() * -100000000 + 1),
            sequence: m.sequence,
            processId: m.processId,
            processName: m.processName,
            expectedDuration: m.expectedDuration,
            workCenterId: m.workCenterId,
            workCenterName: m.workCenterName,
          };
        });
        formik.setFieldValue("manufacturingWorkOrders", work);
      }
    }
  }

  async function handleQuantityChange(event) {
    const newValue = event.target.value;

    if (id == "New") {
      const oldValue = formik.values.quantity;

      if (newValue > 0) {
        const newComponentSate = [...formik.values.manufacturingOrderComponents]; // creates a copy of the current array
        for (let i = 0; i < newComponentSate.length; i++) {
          newComponentSate[i] = { ...newComponentSate[i], quantity: (newComponentSate[i].quantity / oldValue) * newValue }; // set the position of the array to payload (or whatever should be the new value)
        }
        formik.setFieldValue("manufacturingOrderComponents", newComponentSate);

        const newWorkSate = [...formik.values.manufacturingWorkOrders]; // creates a copy of the current array
        for (let i = 0; i < newWorkSate.length; i++) {
          const duration = FormatTimeStamp(newWorkSate[i].expectedDuration);

          const newExpectedDuration = moment.duration((moment.duration(duration) * newValue) / oldValue);

          let newExpectedDurationString = "";
          if (newExpectedDuration._data.days > 0) newExpectedDurationString += newExpectedDuration._data.days;

          newExpectedDuration._data.hours > 0
            ? newExpectedDuration._data.hours < 10
              ? (newExpectedDurationString += "0" + (newExpectedDuration._data.hours + ":"))
              : (newExpectedDurationString += newExpectedDuration._data.hours + ":")
            : (newExpectedDurationString += "00:");

          newExpectedDuration._data.minutes > 0
            ? newExpectedDuration._data.minutes < 10
              ? (newExpectedDurationString += "0" + (newExpectedDuration._data.minutes + ":"))
              : (newExpectedDurationString += newExpectedDuration._data.minutes + ":")
            : (newExpectedDurationString += "00:");

          newExpectedDuration._data.seconds > 0
            ? newExpectedDuration._data.seconds < 10
              ? (newExpectedDurationString += "0" + newExpectedDuration._data.seconds)
              : (newExpectedDurationString += newExpectedDuration._data.seconds)
            : (newExpectedDurationString += "00");

          newWorkSate[i] = { ...newWorkSate[i], expectedDuration: newExpectedDurationString }; // set the position of the array to payload (or whatever should be the new value)
        }
        formik.setFieldValue("manufacturingWorkOrders", newWorkSate);
      }
    }
    if (newValue > 0) formik.setFieldValue("quantity", event.target.value, true);
  }
  const [componentsItem, setComponentsItem] = useState({ id: 0, product: null, quantity: 1, bomId: 0 });
  const [openComponentsItem, setOpenComponentsItem] = useState(false);
  async function OpenComponentsItem(v) {
    await setComponentsItem(v);
    setOpenComponentsItem(true);
  }
  function handleOnSaveComponentsItem(item) {
    const existingProductIdx = formik.values.manufacturingOrderComponents.findIndex((v) => v.productId == item.product.id && v.id != item.id);
    if (existingProductIdx !== -1 || item.product.id == formik.values.bomId) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Product.Duplicate"), severity: "error", open: true } });
      return;
    }
    const existingId = formik.values.manufacturingOrderComponents.findIndex((v) => v.id == item.id);
    if (existingId === -1) {
      // product doesn't exist in the array
      formik.setFieldValue("manufacturingOrderComponents", [
        ...formik.values.manufacturingOrderComponents,
        { id: item.id, productId: item.product.id, productName: item.product.name, quantity: item.quantity, bomId: item.bomId },
      ]);
    } else {
      // product exists
      const newSate = [...formik.values.manufacturingOrderComponents]; // creates a copy of the current array
      newSate[existingId] = { ...newSate[existingId], id: item.id, productId: item.product.id, productName: item.product.name, quantity: item.quantity, bomId: item.bomId }; // set the position of the array to payload (or whatever should be the new value)
      formik.setFieldValue("manufacturingOrderComponents", newSate);
    }
  }

  const [workItem, setWorkItem] = useState({ id: 0, sequence: 1, process: null, workCenter: 1, duration: 0 });
  const [openWorkItem, setOpenWorkItem] = useState(false);
  async function OpenWorkItem(v) {
    await setWorkItem(v);
    setOpenWorkItem(true);
  }

  function handleOnSaveWorkItem(item) {
    const duration = FormatTimeStamp(item.expectedDuration);
    const existingId = formik.values.manufacturingWorkOrders.findIndex((v) => v.id == item.id);
    if (existingId === -1) {
      // product doesn't exist in the array
      formik.setFieldValue("manufacturingWorkOrders", [
        ...formik.values.manufacturingWorkOrders,
        {
          id: item.id,
          sequence: item.sequence,
          processId: item.process.id,
          processName: item.process.name,
          expectedDuration: duration,
          workCenterId: item.workCenter.id,
          workCenterName: item.workCenter.name,
        },
      ]);
    } else {
      // product exists
      const newSate = [...formik.values.manufacturingWorkOrders]; // creates a copy of the current array
      newSate[existingId] = {
        id: item.id,
        sequence: item.sequence,
        processId: item.process.id,
        processName: item.process.name,
        expectedDuration: duration,
        workCenterId: item.workCenter.id,
        workCenterName: item.workCenter.name,
      }; // set the position of the array to payload (or whatever should be the new value)
      formik.setFieldValue("manufacturingWorkOrders", newSate);
    }
  }

  async function Save(item) {
    formik.setStatus({ error: null });
    const data = {
      id: item.id,
      code: item.code,
      quantity: item.quantity,
      bomId: item.bomId.boms ? item.bomId.boms[0].id : item.bomId.id,
      deadline: item.deadline,
      componentsLocationId: item.componentsLocationId.id,
      finishedProductsLocationId: item.finishedProductsLocationId.id,
      automaticBackOrder: false,
      priority: item.priority,
      source: ManufacturingOrderSourceTypes.ManualRequest,
      sourceEntityCode: "",
      manufacturingOrderComponents: item.manufacturingOrderComponents,
      manufacturingWorkOrders: item.manufacturingWorkOrders,
    };
    const result = id == "New" ? await Insert({ navigate, dispatch, t }, EntityTypes.ManufacturingOrder, data) : await Update({ navigate, dispatch, t }, EntityTypes.ManufacturingOrder, data);
    if (result && result.isSuccess) {
      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });

      if (id == "New") {
        navigate("/Manufactures/ManufacturingOrder/" + result.data.id);
        return;
      }

      const data = await initial(result);
      formik.setValues(data);
    } else formik.setStatus({ error: result.error });
  }

  return (
    <form>
      <ArazBox>
        {id > 0 && (
          <ArazContainer sx={{ p: 1 }}>
            <Stepper nonLinear activeStep={-1} sx={{ flexWrap: "wrap" }}>
              {[
                ManufacturingOrderStatusTypes.Draft,
                ManufacturingOrderStatusTypes.Confirmed,
                ManufacturingOrderStatusTypes.Paused,
                ManufacturingOrderStatusTypes.InProgress,
                ManufacturingOrderStatusTypes.Done,
              ].map((label, index) => (
                <Step key={label} completed={false}>
                  <StepLabel icon={<CircleIcon color={formik.values.status == label ? "navbar" : "deactive"} />}>
                    <Typography variant="subtitle2" color={formik.values.status == label ? "navbar.main" : "deactive.main"}>
                      {t(`ManufacturingOrderStatusTypes.${Object.keys(ManufacturingOrderStatusTypes).find((key) => ManufacturingOrderStatusTypes[key] === label)}`)}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </ArazContainer>
        )}

        <ArazContainer>
          <ArazGrid>
            <ArazGrid xs={12} justifyContent="space-between">
              <ArazGrid>
                <ArazGridItem>
                  <ArazSaveButton onClick={formik.handleSubmit} />
                  <ArazButton
                    color="error"
                    variant="outlined"
                    sx={{ mx: 1 }}
                    onClick={() => {
                      confirm({
                        confirmationButtonProps: { color: "error", variant: "contained" },
                        titleProps: { color: "error" },
                        title: t("Commons.Delete"),
                        description: t("Commons.Delete.confirm"),
                        confirmationText: t("Commons.Delete"),
                        cancellationText: t("Commons.Cancel"),
                      })
                        .then(async () => {
                          const result = await Delete({ navigate, dispatch, t }, EntityTypes.ManufacturingOrder, id);
                          if (result.isSuccess) navigate("/Manufactures/ManufacturingOrder");
                          else formik.setStatus({ error: result.error });
                        })
                        .catch(() => {});
                    }}
                  >
                    {t("Commons.Delete")}
                  </ArazButton>
                </ArazGridItem>
              </ArazGrid>

              <ArazGrid>
                <ArazGridItem>
                  {formik.values.canConfirm && (
                    <ArazButton
                      startIcon={<CheckIcon />}
                      color="warning"
                      sx={{ mx: 1 }}
                      onClick={async () => {
                        const result = await Confirm({ navigate, dispatch, t }, id);
                        if (result && result.isSuccess) SetFormValue(result);
                        else formik.setStatus({ error: result.error });
                      }}
                    >
                      {t("Commons.Confirm")}
                    </ArazButton>
                  )}

                  {formik.values.canInProgress && (
                    <ArazButton
                      startIcon={<PlayArrowIcon />}
                      color="warning"
                      sx={{ mx: 1, px: 3 }}
                      onClick={async () => {
                        const result = await InProgress({ navigate, dispatch, t }, id);
                        if (result && result.isSuccess) SetFormValue(result);
                        else formik.setStatus({ error: result.error });
                      }}
                    >
                      {t("ManufacturingOrderStatusTypes.InProgress")}
                    </ArazButton>
                  )}

                  {formik.values.canPause && (
                    <ArazButton
                      startIcon={<PauseIcon />}
                      color="warning"
                      sx={{ mx: 1 }}
                      onClick={async () => {
                        const result = await Pause({ navigate, dispatch, t }, id);
                        if (result && result.isSuccess) SetFormValue(result);
                        else formik.setStatus({ error: result.error });
                      }}
                    >
                      {t("Commons.Pause")}
                    </ArazButton>
                  )}

                  {formik.values.canDone && (
                    <ArazButton
                      startIcon={<CheckIcon />}
                      color="warning"
                      sx={{ mx: 1 }}
                      onClick={async () => {
                        const result = await Done({ navigate, dispatch, t }, id);
                        if (result && result.isSuccess) SetFormValue(result);
                        else formik.setStatus({ error: result.error });
                      }}
                    >
                      {t("ManufacturingOrderStatusTypes.Done")}
                    </ArazButton>
                  )}
                </ArazGridItem>
              </ArazGrid>
            </ArazGrid>
            {formik.status && formik.status.error && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  {formik.status.error.map((e, i) => (
                    <Typography component="span" color="error" key={i}>
                      {e[Object.keys(formik.status.error[0])[0]]}
                    </Typography>
                  ))}
                </ArazGridItem>
              </ArazGrid>
            )}
            <ArazGrid xs={12} md={4}>
              <ArazGridItem>
                <TextField
                  id="code"
                  name="code"
                  label={t("Manufactures.SaleOrder.Field.Code")}
                  variant="outlined"
                  fullWidth
                  size="medium"
                  value={formik.values.code}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("code", event.target.value, true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={4}>
              <ArazGridItem sx={{ textAlign: "center" }}>
                <ArazDatePicker
                  id="deadline"
                  name="deadline"
                  value={formik.values.deadline}
                  setValue={(v) => formik.setFieldValue("deadline", v)}
                  label={t("Manufactures.SaleOrder.Field.Deadline")}
                  error={formik.touched.deadline && Boolean(formik.errors.deadline)}
                  helperText={formik.touched.deadline && formik.errors.deadline}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={4}>
              <ArazGridItem>
                <Rating
                  name="priority"
                  precision={0.5}
                  defaultValue={formik.values.priority}
                  value={formik.values.priority}
                  onChange={(event) => {
                    formik.setFieldValue("priority", parseFloat(event.target.value), true);
                  }}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <ArazGridItem>
                <ArazProductAutoComplete
                  id="bomId"
                  name="bomId"
                  disabled={id != "New"}
                  value={formik.values.bomId}
                  defaultValue={formik.values.bomId}
                  setValue={(v) => handleProductChange(v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.bomId && Boolean(formik.errors.bomId)}
                  helperText={formik.touched.bomId && formik.errors.bomId}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <TextField
                id="quantity"
                name="quantity"
                label={t("Manufactures.ManufacturingOrder.Field.Quantity")}
                type="number"
                variant="outlined"
                fullWidth
                size="medium"
                value={formik.values.quantity}
                onBlur={formik.handleBlur}
                onChange={async (event) => handleQuantityChange(event)}
                error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                helperText={formik.touched.quantity && formik.errors.quantity}
                sx={{
                  margin: "auto",
                  px: 2,
                  "& .MuiInputLabel-root": {
                    left: 16,
                  },
                }}
              />
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <ArazGridItem>
                <ArazWarehouseAutoComplete
                  id="componentsLocationId"
                  name="componentsLocationId"
                  label={t("Manufactures.ManufacturingOrder.Field.ComponentsLocationId")}
                  value={formik.values.componentsLocationId}
                  defaultValue={formik.values.componentsLocationId}
                  setValue={(v) => formik.setFieldValue("componentsLocationId", v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.componentsLocationId && Boolean(formik.errors.componentsLocationId)}
                  helperText={formik.touched.componentsLocationId && formik.errors.componentsLocationId}
                />
              </ArazGridItem>
            </ArazGrid>
            <ArazGrid xs={12} md={6}>
              <ArazGridItem>
                <ArazWarehouseAutoComplete
                  id="finishedProductsLocationId"
                  name="finishedProductsLocationId"
                  label={t("Manufactures.ManufacturingOrder.Field.FinishedProductsLocationId")}
                  value={formik.values.finishedProductsLocationId}
                  defaultValue={formik.values.finishedProductsLocationId}
                  setValue={(v) => formik.setFieldValue("finishedProductsLocationId", v)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.finishedProductsLocationId && Boolean(formik.errors.finishedProductsLocationId)}
                  helperText={formik.touched.finishedProductsLocationId && formik.errors.finishedProductsLocationId}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </ArazContainer>
        <ArazContainer>
          <ArazContainerHeader>{t("Manufactures.ManufacturingOrder.Field.ManufacturingOrderComponents")}</ArazContainerHeader>
          <ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazAddButton
                  label={t("Commons.Insert")}
                  left
                  onClick={async () => await OpenComponentsItem({ id: Math.floor(Math.random() * -100000000 + 1), product: null, quantity: 1, bomId: 0 })}
                  sx={{ mt: 1 }}
                />
              </ArazGridItem>
              {formik.touched.manufacturingOrderComponents && Boolean(formik.errors.manufacturingOrderComponents) && (
                <ArazGridItem>
                  <Typography component="span" color="error">
                    {formik.touched.manufacturingOrderComponents && formik.errors.manufacturingOrderComponents}
                  </Typography>
                </ArazGridItem>
              )}
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazDataGrid
                  manual
                  rows={formik.values.manufacturingOrderComponents}
                  columns={orderComponentsColumns}
                  sortingMode="client"
                  paginationMode="client"
                  rowCount={formik.values.manufacturingOrderComponents.length}
                  onRowClick={async (param) => {
                    await OpenComponentsItem({
                      id: param.id,
                      product: { id: param.row.productId, name: param.row.productName, boms: [{ id: param.row.bomId }] },
                      quantity: param.row.quantity,
                      bomId: param.row.bomId,
                    });
                  }}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </ArazContainer>
        <ArazContainer>
          <ArazContainerHeader>{t("Manufactures.ManufacturingOrder.Field.ManufacturingworkOrders")}</ArazContainerHeader>
          <ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazAddButton
                  label={t("Commons.Insert")}
                  left
                  onClick={async () => await OpenWorkItem({ id: Math.floor(Math.random() * -100000000 + 1), sequence: 1, process: null, workCenter: null, expectedDuration: "" })}
                  sx={{ mt: 1 }}
                />
              </ArazGridItem>
              {formik.touched.manufacturingWorkOrders && Boolean(formik.errors.manufacturingWorkOrders) && (
                <ArazGridItem>
                  <Typography component="span" color="error">
                    {formik.touched.manufacturingWorkOrders && formik.errors.manufacturingWorkOrders}
                  </Typography>
                </ArazGridItem>
              )}
            </ArazGrid>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <ArazDataGrid
                  manual
                  rows={formik.values.manufacturingWorkOrders}
                  columns={workOrdersColumns}
                  sortingMode="client"
                  paginationMode="client"
                  rowCount={formik.values.manufacturingWorkOrders.length}
                  onRowClick={async (param) => {
                    await OpenWorkItem({
                      id: param.id,
                      sequence: param.row.sequence,
                      process: { id: param.row.processId, name: param.row.processName },
                      workCenter: { id: param.row.workCenterId, name: param.row.workCenterName },
                      expectedDuration: param.row.expectedDuration,
                    });
                  }}
                />
              </ArazGridItem>
            </ArazGrid>
          </ArazGrid>
        </ArazContainer>
      </ArazBox>
      {openComponentsItem && (
        <ComponentItem
          open={openComponentsItem}
          setOpen={setOpenComponentsItem}
          value={componentsItem}
          onSave={handleOnSaveComponentsItem}
          productFilter={formik.values.bomId ? ["id", "<>", formik.values.bomId.id] : null}
        />
      )}
      {openWorkItem && <WorksItem open={openWorkItem} setOpen={setOpenWorkItem} value={workItem} onSave={handleOnSaveWorkItem} />}
      {openLedger && <WarehouseBomLedger open={openLedger} setOpen={setOpenLedger} warehouseId={formik.values.componentsLocationId.id} bomId={ledgerBomId} />}
    </form>
  );
}
