import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import { Insert, Search } from "Services/Base/ApiBaseService";
import { EntityTypes } from "Services/Base/constants";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { useValue } from "context/ContextProvider";

const filter = createFilterOptions({
  matchFrom: "contains",
  stringify: (option) => option.name,
});

export default function ArazProductCategoryAutoComplete({ value, setValue, defaultValue, label, error, helperText, ...props }) {
  const { t } = useTranslation();
  const {
    state: {},
    dispatch,
  } = useValue();
  const navigate = useNavigate();

  defaultValue = defaultValue ?? null;

  const [data, setData] = useState([]);

  const [loading, SetLoading] = useState(false);

  const GetData = async (keyword) => {
    SetLoading(true);
    const result = await Search({ navigate, dispatch, t }, EntityTypes.ProductCategory, keyword ? ["name", "contains", keyword] : []);
    setData(result.data.data);
    SetLoading(false);
  };

  // Axios Shit goes here
  useEffect(() => {
    setValue(defaultValue);
    (async () => {
      await GetData();
    })();
  }, []);

  return (
    <>
      <Autocomplete
        loading={loading}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue) => {
          if (!newValue) {
            setValue(null);
          } else if (newValue.inputValue) {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(async () => {
              const result = await Insert({ navigate, dispatch, t }, EntityTypes.ProductCategory, { name: newValue.inputValue });
              if (result && result.isSuccess) {
                setValue({ id: result.data.id, name: result.data.name });
              }
            });
          } else {
            setValue(newValue);
          }
        }}
        options={data}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (option.inputValue) {
            return option.name;
          }
          return `${option.name}`;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const isExisting = options.some((option) => params.inputValue === `${option.name}`);
          if (params.inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue: params.inputValue,
              name: `${t("Commons.Add")} ${params.inputValue}`,
            });
          }
          return filtered;
        }}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(`${option.name}`, inputValue);
          const parts = parse(`${option.name}`, matches);

          return (
            <li {...props} key={option.id}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? "highlight.main" : "inherit",
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            onChange={async (e) => await GetData(e.target.value)}
            label={label ?? t("Manufactures.Product.Field.ProductCategoryName")}
            error={error}
            helperText={helperText}
          />
        )}
        {...props}
      />
    </>
  );
}
