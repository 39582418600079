import { Button, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArazButton from "./ArazButton";

const ArazSaveButton = ({ children, sx, ...props }) => {
  const { t } = useTranslation();

  children = children ?? t("Commons.Save");
  return (
    <ArazButton color="success" {...props}>
      {children}
    </ArazButton>
  );
};

export default ArazSaveButton;
