import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { EntityTypes } from "Services/Base/constants";
import { LoadData } from "Services/Base/ApiBaseService";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function Roles({ value, setValue }) {
  const { t } = useTranslation();

  const [chipData, setChipData] = useState([]);
  const {
    state: {},
    dispatch,
  } = useValue();
  const navigate = useNavigate();

  const loadData = async () => {
    const result = await LoadData({ navigate, dispatch, t }, EntityTypes.Role, {
      pageSize: 100,
      page: 0,
      sortOption: null,
      filterOption: null,
      filters: null,
    });
    setChipData(result.data.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleClick = (data) => {
    if (value.map((m) => m.id).indexOf(data.id) == -1) {
      setValue([...value, { id: data.id, name: data.name }]);
    } else {
      setValue(value.filter((m) => m.id != data.id));
    }
  };
  return (
    <>
      <Typography variant="h6">{t("Commons.User.Field.UserRoles")}</Typography>

      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {chipData.map((data) => {
          let icon = <DoneIcon />;
          let color = "success";

          if (value.map((m) => m.id).indexOf(data.id) == -1) {
            color = "error";
            icon = null;
          }
          return (
            <ListItem key={data.id}>
              <Chip icon={icon} label={t(data.name)} onClick={() => handleClick(data)} variant="outlined" color={color} />
            </ListItem>
          );
        })}
      </Paper>
    </>
  );
}
