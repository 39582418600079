import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterJalali from "@date-io/date-fns-jalali";
import DateIODateFnsAdapter from "@date-io/date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import { useValue } from "context/ContextProvider";

export function ArazDatePicker({ value, setValue, error, helperText, ...props }) {
  const [open, setOpen] = useState(false);

  const {
    state: { language },
  } = useValue();

  return (
    <LocalizationProvider dateAdapter={language == "fa" ? AdapterJalali : DateIODateFnsAdapter}>
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        //mask="____/__/__"
        sx={{
          width: "100%",
        }}
        value={value}
        onChange={(newValue) => setValue(newValue)}
        renderInput={(params) => <TextField onClick={(e) => setOpen(true)} fullWidth error={error} helperText={helperText} {...params} />}
        {...props}
      />
    </LocalizationProvider>
  );
}
export default ArazDatePicker;
