import { styled } from "@mui/material/styles";
import { Box, IconButton, Link as MuiLink, useMediaQuery } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import logo from "./arazsoft-logo.png";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { useValue } from "context/ContextProvider";
import MenuIcon from "@mui/icons-material/Menu";
import MdNavMenu from "./MdNavMenu";
import XsNavMenu from "./XsNavMenu";
import UserMenu from "components/user/UserMenu";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { GetCurrentCustomer } from "Services/Security/UserService";
import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import LanguageSelector from "i18n/LanguageSelector";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function NavBar() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { dark, openMenu, currentUser, token, language },
    dispatch,
  } = useValue();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    (async () => {
      if (token != null) {
        if (currentUser == null || new Date().setHours(new Date().getHours() - 12) < new Date(currentUser.ReceivedDateTime)) {
          const costomer = await GetCurrentCustomer({ navigate, dispatch, t });
          if (costomer && costomer.isSuccess) {
            dispatch({ type: "UPDATE_USER", payload: costomer.data });
          }
        }
      }
    })();
  }, []);

  return (
    <>
      {token && (
        <AppBar
          position="fixed"
          color="background"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: 70,
            px: 2,
          }}
        >
          <UserMenu />

          {!isXs && <MdNavMenu />}
          {isXs && <XsNavMenu />}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
              flexGrow: 1,
            }}
          >
            {/* 
            todo help
            <MuiLink component={Link} to={`${language}/Knowledge`} color="navbar.light" height={26} className="knowledge-icon">
              <QuestionMarkIcon />
            </MuiLink> */}

            <IconButton onClick={() => dispatch({ type: "SET_DARK", payload: !dark })}>{dark ? <Brightness7 /> : <Brightness4 />}</IconButton>
            <LanguageSelector />
            <IconButton size="large" sx={{ display: { xs: "block", md: "none" } }} onClick={() => dispatch({ type: "TOGGLE_MENU", payload: !openMenu })}>
              {<MenuIcon />}
            </IconButton>

            <MuiLink component={Link} to="/" sx={{ display: { xs: "none", md: "block" } }}>
              <img src={logo} style={{ height: "30px" }} />
            </MuiLink>
          </Box>
        </AppBar>
      )}
    </>
  );
}
