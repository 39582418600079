import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { useState } from "react";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { useMemo } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import { useEffect } from "react";
import ManufacturingWorkOrderHistoryItem from "./ManufacturingWorkOrderHistoryItem";
import { DeleteManufacturingWorkOrderHistory } from "Services/ManufactureOrders/ManufactureWorkOrderService";

export default function ManufacturingWorkOrderHistoryList({ open, setOpen, manufacturingWorkOrderId, values, onSave }) {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const confirm = useConfirm();
  const navigate = useNavigate();

  const {
    state: {language},
    dispatch,
  } = useValue();

  useEffect(() => {
    setItems(values);
  }, [values]);

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "realDuration",
        headerName: t("Manufactures.ManufacturingWorkOrderHistory.Field.RealDuration"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        minWidth: 100,
        getActions: (params) => {
          const actions = [];
          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              color="error"
              onClick={() => {
                confirm({
                  confirmationButtonProps: { color: "error", variant: "contained" },
                  titleProps: { color: "error" },
                  title: t("Commons.Delete"),
                  description: t("Commons.Delete.confirm"),
                  confirmationText: t("Commons.Delete"),
                  cancellationText: t("Commons.Cancel"),
                })
                  .then(async () => {
                    const result = await DeleteManufacturingWorkOrderHistory({ navigate, dispatch, t }, manufacturingWorkOrderId, params.id);
                    if (result && result.isSuccess) {
                      dispatch({ type: "UPDATE_ALERT", payload: { message: t("Save.Success"), severity: "success", open: true } });
                      handleOnSaveHistoryItem();
                    } else dispatch({ type: "UPDATE_ALERT", payload: { message: t("WentWrong.Retry"), severity: "error", open: true } });
                  })
                  .catch(() => {});
              }}
            />
          );
          return actions;
        },
        align: "center",
        headerAlign: "center",
      },
    ],
    [language]
  );

  const [openManufacturingWorkOrderHistoryItem, setOpenManufacturingWorkOrderHistoryItem] = useState(false);
  const [item, setItem] = useState({ id: 0, realDuration: "", manufacturingWorkOrderId });
  async function openItem(v) {
    await setItem(v);
    setOpenManufacturingWorkOrderHistoryItem(true);
  }

  function handleOnSaveHistoryItem(v) {
    onSave(v);
    close();
  }

  function close() {
    setOpen(false);
  }
  return (
    <>
      <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="lg">
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {t("Manufactures.ManufacturingWorkOrder.Field.TotalRealDuration")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid xs={12}>
            <ArazGridItem>
              <ArazDataGrid
                manual
                rows={items}
                columns={columns}
                sortingMode="client"
                paginationMode="client"
                rowCount={items.length}
                onRowClick={async (param) => {
                  await openItem({ id: param.id, realDuration: param.row.realDuration, manufacturingWorkOrderId });
                }}
              />
            </ArazGridItem>
          </ArazGrid>
        </DialogContent>
      </ArazDialog>
      {openManufacturingWorkOrderHistoryItem && (
        <ManufacturingWorkOrderHistoryItem open={openManufacturingWorkOrderHistoryItem} setOpen={setOpenManufacturingWorkOrderHistoryItem} value={item} onSave={handleOnSaveHistoryItem} />
      )}
    </>
  );
}
