import { Container } from "@mui/material";

const ArazContainer = ({ children, sx, ...props }) => {
  return (
    <Container maxWidth="100%" sx={{ p: { xs: 1, md: 2 }, mt: 1, backgroundColor: "common.white", borderRadius: 2, ...sx }} {...props}>
      {children}
    </Container>
  );
};

export default ArazContainer;
