import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const ArazAddButton = ({ sx, label, left, ...props }) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      color="common"
      sx={{ float: left ? "left" : "right", borderRadius: 5, color: "success.light", top: "-10px", ...sx }}
      onClick={() => {
        navigate("New");
      }}
      {...props}
    >
      <AddIcon fontSize="large" sx={{ borderRadius: "50%", color: "white", backgroundColor: "success.light", mr: 3 }} /> {label}
    </Button>
  );
};

export default ArazAddButton;
