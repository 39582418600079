import { useMemo, useState } from "react";
import ArazDataGrid from "components/ArazComponents/ArazDataGrid";
import { useTranslation } from "react-i18next";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { EntityTypes } from "Services/Base/constants";
import { useConfirm } from "material-ui-confirm";
import { Delete } from "Services/Base/ApiBaseService";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import ArazAddButton from "components/ArazComponents/ArazAddButton";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import Warehouse from "./Warehouse";

export default function Warehouses() {
  const { t } = useTranslation();

  const confirm = useConfirm();
  const navigate = useNavigate();
  const {
    state: {language},
    dispatch,
  } = useValue();

  const [trigger, setTrigger] = useState(false);
  const [item, setItem] = useState({ id: 0, name: "" });
  const [openItem, setOpenItem] = useState(false);
  async function OpenItem(v) {
    await setItem(v);
    setOpenItem(true);
  }

  const columns = useMemo(
    () => [
      { field: "id", type: "number", headerName: "id", hide: true },
      {
        field: "name",
        headerName: t("Inventories.Warehouse.Field.Name"),
        flex: 1,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
      },

      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        minWidth: 100,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            onClick={() => {
              confirm({
                confirmationButtonProps: { color: "error", variant: "contained" },
                titleProps: { color: "error" },
                title: t("Commons.Delete"),
                description: t("Commons.Delete.confirm"),
                confirmationText: t("Commons.Delete"),
                cancellationText: t("Commons.Cancel"),
              })
                .then(async () => {
                  const result = await Delete({ navigate, dispatch,t }, EntityTypes.Warehouse, params.id);
                  if (result.isSuccess) setTrigger((trigger) => !trigger);
                })
                .catch(() => {});
            }}
          />,
        ],
        align: "center",
        headerAlign: "center",
      },
    ],
    [language]
  );

  function handleOnSaveItem(item) {
    setTrigger((trigger) => !trigger);
  }

  return (
    <>
      <ArazGrid xs={12}>
        <ArazGridItem>
          <ArazAddButton
            label={t("Commons.Insert")}
            onClick={() => {
              OpenItem({ id: 0, name: "" });
            }}
          />
        </ArazGridItem>
      </ArazGrid>
      <ArazGrid xs={12}>
        <ArazGridItem>
          <ArazDataGrid
            columns={columns}
            entityType={EntityTypes.Warehouse}
            trigger={trigger}
            onRowClick={async (param) => {
              await OpenItem({
                id: param.id,
                name: param.row.name,
              });
            }}
          />
        </ArazGridItem>
      </ArazGrid>

      {openItem && <Warehouse open={openItem} setOpen={setOpenItem} value={item} onSave={handleOnSaveItem} />}
    </>
  );
}
