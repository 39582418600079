import * as baseApi from "Services/Base/ApiBaseService";
import { EntityTypes } from "Services/Base/constants";

export const GetByWarehouseId = async (hooks, warehouseId, searchModel) => {
  hooks.dispatch({ type: "START_LOADING" });

  const params = {
    RequireTotalCount: true,
    Skip: searchModel.page * searchModel.pageSize,
    Take: searchModel.pageSize,
  };

  if (searchModel.sortOption && Object.keys(searchModel.sortOption).length !== 0) {
    const sort = searchModel.sortOption.map((s) => {
      return { Selector: s.field, Desc: s.sort == "asc" ? false : true };
    });
    params.Sort = JSON.stringify(sort);
  }

  let filters = [];

  let predifineFilters = [];
  if (searchModel.filters && Object.keys(searchModel.filters).length !== 0) {
    searchModel.filters.map((f) => {
      predifineFilters.push(f);
    });
    filters.push(predifineFilters);
  }

  let filter = [];
  if (searchModel.filterOption && searchModel.filterOption.items && Object.keys(searchModel.filterOption.items).length !== 0) {
    searchModel.filterOption.items.map((s, i) => {
      if (s.value) {
        filter.push([s.columnField, s.operatorValue, s.value]);
        if (i !== searchModel.filterOption.items.length - 1) filter.push("or");
      }
    });
    if (filter.length !== 0) {
      if (filters.length !== 0) filters.push("AND");
      filters.push(filter);
    }
  }

  if (filters.length > 0) params.Filter = JSON.stringify(filters);

  const query = baseApi.objectToQueryString(params);

  let response = await baseApi.fetchWithTimeout(hooks, `${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.Warehouse}/GetByWarehouseId/${warehouseId}/?${query}`, {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};

export const GetByWarehouseIdAndBomId = async (hooks, warehouseId, bomId) => {
  hooks.dispatch({ type: "START_LOADING" });

  let response = await baseApi.fetchWithTimeout(hooks, `${process.env.REACT_APP_BaseApiAddress}/${EntityTypes.Warehouse}/GetByWarehouseIdAndBomId/${warehouseId}/${bomId}`, {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${baseApi.GetBearerToken()}`,
    },
  });
  hooks.dispatch({ type: "END_LOADING" });

  return baseApi.ReadResult(hooks, response);
};
