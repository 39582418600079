import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import ArazGrid, { ArazGridItem } from "components/ArazComponents/ArazGrid";
import { TextField, Typography } from "@mui/material";
import { ArazDialog } from "components/ArazComponents/ArazDialog";
import ArazDialogTitle from "components/ArazComponents/ArazDialogTitle";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useValue } from "context/ContextProvider";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="mm:hm:hm:hm"
      definitions={{
        m: /[0-9]/,
        h: /[0-5]/,
      }}
      inputRef={ref}
      onAccept={(value) => {
        onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function ManufacturingWorkOrderHistoryItem({ open, setOpen, value, onSave }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {},
    dispatch,
  } = useValue();

  const validationSchema = yup.object().shape({
    realDuration: yup.string().required().label(t("Manufactures.ManufacturingWorkOrderHistory.Field.RealDuration")),
  });

  useEffect(() => {
    (async () => {})();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: value.id,
      manufacturingWorkOrderId: value.manufacturingWorkOrderId,
      realDuration: value.realDuration,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.setStatus({ error: null });
      onSave(values);
      close();
    },
  });

  function close() {
    setOpen(false);
  }

  function ConvertTimeStampToReadable(input) {
    const data = input?.split(":");

    let format = [];
    if (data[0]) format.push(data[data.length - 1] + ` ${t("Second")} `);
    if (data[1]) format.push(data[data.length - 2] + ` ${t("Minute")} `);
    if (data[2]) format.push(data[data.length - 3] + ` ${t("Hour")} `);
    if (data[3]) format.push(data[data.length - 4] + ` ${t("Day")} `);

    return format.reverse().toString();
  }

  return (
    <ArazDialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
      <form>
        <ArazDialogTitle id="customized-dialog-title" onClose={close}>
          {t("Commons.Edit")}
        </ArazDialogTitle>
        <DialogContent dividers>
          <ArazGrid xs={12}>
            <ArazGrid xs={12}>
              <ArazGridItem>
                <TextField
                  id="realDuration"
                  name="realDuration"
                  label={t("Manufactures.ManufacturingWorkOrderHistory.Field.RealDuration")}
                  fullWidth
                  value={formik.values.realDuration}
                  onBlur={formik.handleBlur}
                  onChange={async (event) => {
                    await formik.setFieldValue("realDuration", event.target.value, true);
                  }}
                  error={formik.touched.realDuration && Boolean(formik.errors.realDuration)}
                  helperText={formik.touched.realDuration && formik.errors.realDuration}
                  placeholder="dd:hh:mm:ss"
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />
                {!formik.errors.realDuration && <Typography variant="caption">{ConvertTimeStampToReadable(formik.values.realDuration)}</Typography>}
              </ArazGridItem>
            </ArazGrid>

            {formik.status && formik.status.error && (
              <ArazGrid xs={12}>
                <ArazGridItem>
                  {formik.status.error.map((e, i) => (
                    <Typography component="span" color="error" key={i}>
                      {e[Object.keys(formik.status.error[0])[0]]}
                    </Typography>
                  ))}
                </ArazGridItem>
              </ArazGrid>
            )}
          </ArazGrid>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="text" autoFocus onClick={close}>
            {t("Commons.Cancel")}
          </Button>
          <Button autoFocus type="submit" color="success" variant="contained" onClick={formik.handleSubmit}>
            {t("Commons.Save")}
          </Button>
        </DialogActions>
      </form>
    </ArazDialog>
  );
}
