import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function ArazImageList({ items, onDelete }) {
  const theme = useTheme();

  return (
    <ImageList variant="masonry" sx={{ width: "100%", height: "auto" }} cols={useMediaQuery(theme.breakpoints.down("md")) ? 1 : 6} rowHeight={200}>
      <ImageListItem key="Subheader" cols={useMediaQuery(theme.breakpoints.down("md")) ? 1 : 6}>
        {/* <ListSubheader component="div">December</ListSubheader> */}
      </ImageListItem>
      {items.map((item) => (
        <ImageListItem key={item.pictureUrl}>
          <img src={`${process.env.REACT_APP_BaseWWWAddress}${item.pictureUrl}`} srcSet={`${process.env.REACT_APP_BaseWWWAddress}${item.pictureUrl}`} loading="lazy" />
          <ImageListItemBar
            // title={item.title}
            // subtitle={item.author}
            actionIcon={
              <IconButton sx={{ color: "rgba(255, 255, 255, 0.54)" }} onClick={() => onDelete(item.id)}>
                <DeleteIcon color="error" />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
